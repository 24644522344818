import styled from 'styled-components/macro'

import React from 'react'
import { ItemCard, ItemCardData } from '../../molecules'
import { CollectionInfoHead, CollectionProgress } from '../../organisms'
import { HorizontalLine } from '../../atoms'
import { useDisplayType } from '../../hooks'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  max-width: 1200px;
  align-items: center;
  margin: 0 auto;

  ${({ theme }) => theme.device.desktop} {
    gap: 8px;
  }
`

export type CollectionInfoData = {
  id: string
  image: string
  title: string
  gameName: string
  backgroundImage: string
  description?: string
  chestsToGet?: number
  chestsTotal?: number
  lastBlockAvailable?: number
  firstBlockAvailable: number
  keyPrice?: bigint
  chainCreatedTimestamp: number
  chest: {
    id: string
    name?: string
    image?: string
    additional?: any
  }
}

type CollectionInfoProps = {
  collection: CollectionInfoData
}

export const CollectionInfo: React.FC<CollectionInfoProps> = ({ collection }) => {
  const display = useDisplayType()

  return (
    <StyledContainer>
      <CollectionInfoHead collection={collection} />
      <HorizontalLine
        width={display === 'desktop' ? '284px' : '156px'}
        margin="24px auto"
        thickness={display === 'desktop' ? '2px' : '3px'}
        color="medium_dark"
      />
      <CollectionProgress collection={collection} />
    </StyledContainer>
  )
}
