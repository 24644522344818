import styled from 'styled-components/macro'
import Image from 'next/image'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { ReactComponent as BurntIcon } from '../../assets/icons/burnt-icon.svg'
import { ReactComponent as ListedIcon } from '../../assets/icons/listed-icon.svg'
import { ReactComponent as MintedIcon } from '../../assets/icons/minted-icon.svg'
import { ReactComponent as TransferIcon } from '../../assets/icons/transfer-icon.svg'
import { ReactComponent as UnlistedIcon } from '../../assets/icons/unlisted-icon.svg'
import { useDisplayType } from '../../hooks'
import { QuestionMark } from '../../atoms/Icon/assets'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 156px;

  ${({ theme }) => theme.device.desktop} {
    min-width: 173px;
  }
`

const SmallIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
`

const Icon = styled(Image)`
  object-fit: cover;
  border-radius: 50%;

  width: auto !important;
  height: auto !important;
`

const InnerContainer = styled.div<{ gap: string }>`
  display: flex;
  flex-direction: row;
  gap: ${({ gap }) => gap};
  align-items: center;

  ${({ theme }) => theme.device.desktop} {
    div:first-child,
    div:first-child img {
      width: 58px;
      height: 58px;
    }
  }
`

const SizeToPixel = (size: 'small' | 'normal' | 'big', display: 'mobile' | 'tablet' | 'desktop') => {
  if (display === 'mobile') return size === 'small' ? 32 : size === 'normal' ? 42 : 48
  return size === 'small' ? 42 : size === 'normal' ? 48 : 64
}

const IconContainer = styled.div<{ size: 'small' | 'normal' | 'big' }>`
  width: ${({ size }) => SizeToPixel(size, 'mobile')}px !important;
  height: ${({ size }) => SizeToPixel(size, 'mobile')}px !important;

  border-radius: 50%;
  border: 1px solid #9696b2;

  ${({ theme }) => theme.device.desktop} {
    width: ${({ size }) => SizeToPixel(size, 'desktop')}px !important;
    height: ${({ size }) => SizeToPixel(size, 'desktop')}px !important;
  }
`

const Title = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 20px;
  line-height: 24px;
  margin: 0;

  color: ${({ theme }) => theme.colors.deep_koamaru_medium};
`

const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  width: calc(100% - 60px);
`

type SourceImageData = {
  src: string | null
  size: 'small' | 'normal' | 'big'
  backgroundColor?: string
}

type IconImageData = {
  icon: 'burnt' | 'listed' | 'minted' | 'unlisted' | 'transfered' | 'opened'
  size: 'small' | 'normal' | 'big'
}

type ColoredLabelData<T extends string> = {
  text: string
  color?: T
  href?: string
  fontSize?: number
  lineHeight?: number
}

type JazziconImageData = {
  address: string
  size: 'small' | 'normal' | 'big'
}

type IconedLabelProps = {
  image?: SourceImageData | IconImageData | JazziconImageData | null
  title?: string
  label: ColoredLabelData<'white' | 'orange'>
  sublabel?: ColoredLabelData<'gray' | 'orange'>
  overflowLayout?: boolean
}

type LabelColorType = IconedLabelProps['label']['color']
const Label = styled.a<{
  color: LabelColorType
  fontSize?: number
  lineHeight?: number
  overflowLayout: boolean
}>`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ fontSize }) => fontSize ?? 20}px;
  line-height: ${({ lineHeight }) => lineHeight ?? 27}px;
  margin: 0;
  white-space: nowrap;
  text-transform: capitalize;
  text-decoration: none;
  overflow: ${({ overflowLayout }) => (overflowLayout ? 'hidden' : 'visible')};
  text-overflow: ellipsis;

  color: ${({ color, theme }) => (color === 'orange' ? theme.colors.royal_orange_primary : 'white')};
`

type SubLabelColorType = NonNullable<IconedLabelProps['sublabel']>['color']
const Sublabel = styled.a<{
  color: SubLabelColorType
  fontSize?: number
  lineHeight?: number
  overflowLayout: boolean
}>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ fontSize }) => fontSize ?? 20}px;
  line-height: ${({ lineHeight }) => lineHeight ?? 27}px;
  margin: 0;
  white-space: nowrap;
  text-transform: capitalize;
  text-decoration: none;
  overflow: ${({ overflowLayout }) => (overflowLayout ? 'hidden' : 'visible')};
  text-overflow: ellipsis;

  color: ${({ color, theme }) =>
    color === 'gray' ? theme.colors.deep_koamaru_medium : theme.colors.royal_orange_primary};
`

const SmallIconMap = {
  burnt: BurntIcon,
  listed: ListedIcon,
  minted: MintedIcon,
  transfered: TransferIcon,
  unlisted: UnlistedIcon,
}

const IsSmallIcon = (image?: IconedLabelProps['image']): image is IconImageData => !!image && 'icon' in image

const GetIcon = ({ image }: { image?: IconedLabelProps['image'] }) => {
  const display = useDisplayType()

  if (IsSmallIcon(image)) {
    const SmallIcon = SmallIconMap[image.icon]
    return (
      <SmallIconContainer>
        <SmallIcon />
      </SmallIconContainer>
    )
  } else if (image && 'src' in image && image.src)
    return (
      <IconContainer size={image.size ?? 'normal'}>
        <Icon width={48} height={48} src={image.src} alt="Icon" />
      </IconContainer>
    )
  else if (image && 'address' in image && image.address)
    return (
      <IconContainer size={image.size ?? 'normal'}>
        <Jazzicon diameter={SizeToPixel(image.size, display)} seed={jsNumberForAddress(image.address)} />
      </IconContainer>
    )
  else if (image === null) return <QuestionMark />
  else return null
}

export const IconedInfo = ({ image, title, label, sublabel, overflowLayout = false }: IconedLabelProps) => {
  return (
    <Container>
      {title && <Title>{title}</Title>}
      <InnerContainer gap={IsSmallIcon(image) ? '14px' : '12px'}>
        <GetIcon image={image} />
        <LabelsContainer>
          <Label
            color={label.color}
            href={label.href}
            lineHeight={label.lineHeight}
            fontSize={label.fontSize}
            overflowLayout={overflowLayout}
          >
            {label.text}
          </Label>
          {sublabel && (
            <Sublabel
              color={sublabel.color}
              href={sublabel.href}
              lineHeight={sublabel.lineHeight}
              fontSize={sublabel.fontSize}
              overflowLayout={overflowLayout}
            >
              {sublabel.text}
            </Sublabel>
          )}
        </LabelsContainer>
      </InnerContainer>
    </Container>
  )
}
