import styled from 'styled-components/macro'

type Props = {
  bgSrc: string
}

const StyledGameBackground = styled.div`
  background: linear-gradient(
      180deg,
      rgba(45, 45, 107, 0) 0%,
      ${({ theme }) => theme.colors.deep_koamaru_primary} 100%
    ),
    url(${({ bgSrc }: Props) => bgSrc});
  width: 100%;
  height: 576px;
`

export const GameBackground = ({ bgSrc }: Props) => {
  return <StyledGameBackground bgSrc={bgSrc} />
}
