import Link from 'next/link'
import styled from 'styled-components/macro'
import { EmailSubscriptionForm } from '../../organisms/EmailSubscriptionForm'
import { FooterCommunity } from '../../organisms/FooterCommunity'
import { HorizontalLine } from '../../atoms/HorizontalLine'
import { useTranslation } from 'next-i18next'

const FooterSection = styled.section`
  width: auto;
  padding: 38px 16px 0;
  background-color: ${({ theme }) => theme.colors.deep_koamaru_darkest};

  ${({ theme }) => theme.device.desktop} {
    padding: 80px 0 0;
  }
`

const FooterCommunityContainer = styled.div`
  width: 100%;
  ${({ theme }) => theme.device.desktop} {
    max-width: 95vw;
  }
`

const FooterHorizontalLine = styled(HorizontalLine)`
  &:first-of-type {
    margin: 30px 0 40px;
  }

  &:nth-of-type(2) {
    margin: 27px 0 17px;
  }
`

const FooterNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral_white};
  font-family: 'Inter';
  font-size: 12px;
  margin-top: 22px;
  width: 100%;

  ul {
    display: flex;
    justify-content: space-around;
    gap: 12px;
    padding: 0;
    margin: 0;
    list-style: none;

    white-space: nowrap;

    a {
      color: ${({ theme }) => theme.colors.neutral_white};
      text-decoration: none;
    }
  }

  p {
    margin-top: 22px;
    margin-bottom: 17px;
  }

  ${({ theme }) => theme.device.desktop} {
    max-width: 95vw;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.deep_koamaru_medium};
    font-size: 18px;

    ul {
      gap: 18px;
      a {
        color: ${({ theme }) => theme.colors.deep_koamaru_medium};
      }
    }
  }
`

const FooterBottomPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0 auto;

  max-width: 1920px;
`

const MobileHorizontalLine = styled(HorizontalLine)`
  display: block;
  margin: 27px 0 17px;

  ${({ theme }) => theme.device.desktop} {
    display: none;
  }
`

export const Footer = () => {
  const { t } = useTranslation('footer')
  const currentYear = new Date().getFullYear()

  return (
    <FooterSection>
      <EmailSubscriptionForm onSubmit={(email: string) => console.log(email)} />
      <FooterHorizontalLine />
      <FooterBottomPart>
        <FooterCommunityContainer>
          <FooterCommunity />
        </FooterCommunityContainer>
        <MobileHorizontalLine />
        <FooterNav>
          <ul>
            <li>
              <Link href="http://bine.games">{t('about_us')}</Link>
            </li>
            <li>
              <Link href="http://bine.games/terms-of-service">{t('terms_of_service')}</Link>
            </li>
            <li>
              <Link href="http://bine.games/privacy-policy">{t('privacy_policy')}</Link>
            </li>
          </ul>
          <p>
            ©{currentYear} | BINE | {t('all_rights_reserved')}
          </p>
        </FooterNav>
      </FooterBottomPart>
    </FooterSection>
  )
}
