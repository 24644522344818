import styled from 'styled-components/macro'
import { Player, BigPlayButton } from 'video-react'

type Props = {
  videoSrc: string
  posterUrl?: string
}

const VideoContainer = styled.div`
  width: 156px;
  height: 96px;

  ${({ theme }) => theme.device.desktop} {
    width: 438px;
    height: 241px;
  }
`

const StyledVideoCard = styled(Player)`
  border-radius: 16px;
  overflow: hidden;
  box-sizing: border-box;

  .video-react-poster {
    box-sizing: border-box;
    border: 1px solid ${({ theme }) => theme.colors.deep_koamaru_medium};
    border-radius: 16px;
  }

  .video-react-video {
    box-sizing: border-box;
    border: 1px solid ${({ theme }) => theme.colors.deep_koamaru_medium};
    border-radius: 16px;

    &:hover:not([disabled]) {
      border: 1px solid ${({ theme }) => theme.colors.royal_orange_primary};
    }
  }

  .video-react-time-control {
    font-family: 'Manrope', sans-serif;
  }

  .video-react-play-progress:after {
    font-family: 'Manrope', sans-serif;
  }

  ${({ theme }) => theme.device.desktop} {
    border-radius: 30px;

    .video-react-poster {
      border-radius: 30px;
    }

    .video-react-video {
      border-radius: 30px;

      &:hover:not([disabled]) {
        border: 2px solid ${({ theme }) => theme.colors.deep_koamaru_medium};
      }
    }
  }
`

export const VideoCard = ({ videoSrc, posterUrl }: Props) => {
  return (
    <VideoContainer>
      <StyledVideoCard src={videoSrc} poster={posterUrl}>
        <BigPlayButton position="center" />
      </StyledVideoCard>
    </VideoContainer>
  )
}
