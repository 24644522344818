import Icon from '../../../atoms/Icon'
import { FlexBox } from '../../../atoms'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  position: fixed;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  width: calc(100% - 32px);
  height: 64px;
  border-bottom: ${({ theme }) => theme.colors.deep_koamaru_medium} 1px solid;

  background: ${({ theme }) => theme.colors.deep_koamaru_primary};
  z-index: 1;
`

const HeaderTitle = styled.p`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 24px;
  line-height: 33px;

  color: #ffffff;
`

type SidebarHeaderProps = {
  title: string
  onClose?: () => void
}

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({
  title,
  onClose = () => null,
}) => {
  return (
    <StyledContainer>
      <HeaderTitle>{title}</HeaderTitle>
      <FlexBox onClick={onClose}>
        <Icon icon={{ name: 'close', size: 30 }} />
      </FlexBox>
    </StyledContainer>
  )
}
