import styled from 'styled-components'
import Icon from '../Icon'
import React, { useState, ChangeEvent } from 'react'
import { useTranslation } from 'next-i18next'

const SelectContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 6px 10px 20px;
  height: 36px;
  border: 0.5px solid #414376;
  border-radius: 30px;
  cursor: pointer;

  ${({ theme }) => theme.device.desktop} {
    height: 48px;
    padding: auto 6px auto 20px;
  }
`

const SelectElement = styled.select`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  background: transparent;
  border: none;
  appearance: none;
  cursor: pointer;

  ${({ theme }) => theme.device.desktop} {
    font-size: 20px;
    line-height: 24px;
  }
`

const SelectOption = styled.option`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  font-size: 12px;
  line-height: 15px;
  color: white;
  background-color: ${({ theme }) => theme.colors.deep_koamaru_primary};
  border: none;
  appearance: none;
  cursor: pointer;

  ${({ theme }) => theme.device.desktop} {
    font-size: 20px;
    line-height: 24px;
  }
`

const SelectIconWrapper = styled.div`
  display: flex;
  align-items: center;
`

type SelectProps = {
  defaultValue?: string
  options: string[]
  onChanged?: (option: string) => void
}

export const Select: React.FC<SelectProps> = ({ defaultValue, options, onChanged }) => {
  const { t } = useTranslation('common')
  const [selectedOption, setSelectedOption] = useState<string | null>(defaultValue || null)

  const onOptionChanged = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value)
    if (onChanged) onChanged(event.target.value)
  }

  return (
    <SelectContainer>
      <SelectElement value={selectedOption || ''} onChange={onOptionChanged}>
        <SelectOption disabled value="">
          {(selectedOption && t(selectedOption)) ?? t('select_an_option')}
        </SelectOption>
        {options.map((option, index) => (
          <SelectOption key={index} value={option}>
            {t(option)}
          </SelectOption>
        ))}
      </SelectElement>
      <SelectIconWrapper>
        <Icon icon={{ name: 'arrowDownRound', size: 25 }} />
      </SelectIconWrapper>
    </SelectContainer>
  )
}
