import styled from 'styled-components/macro'

export const Button = styled.button`
  border: 0px;
  background-color: transparent;
  cursor: pointer;
`

export const Title = styled.p`
  font-size: ${({ theme }) => theme.typeScale.description1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: ${({ theme }) => theme.lineHeight.description1};
  color: ${({ theme }) => theme.colors.deep_koamaru_primary};
`
