import { SiweMessage } from 'siwe'
import { AuthService } from '../services/auth.service'
import { createAuthenticationAdapter } from '@rainbow-me/rainbowkit'
import {
  GetAuthNonceDocument,
  GetAuthNonceQuery,
  LoginDocument,
  LoginMutation,
  LogoutDocument,
  LogoutMutation,
  CurrentUserDocument,
  CurrentUserQuery,
} from '@data-access'
import { RainbowKitAuthenticationProvider } from '@rainbow-me/rainbowkit'
import { initializeApollo } from '../apollo-client'
import { useEffect, useState } from 'react'

type BineAuthenticationProviderProps = {
  children: React.ReactNode
}

export const BineAuthenticationProvider = ({ children }: BineAuthenticationProviderProps) => {
  const [authStatus, setAuthStatus] = useState<'loading' | 'authenticated' | 'unauthenticated'>('unauthenticated')
  const client = initializeApollo()

  useEffect(() => {
    if (!client) return

    const checkAuth = async () => {
      const { data }: { data: CurrentUserQuery } = await client.query({
        query: CurrentUserDocument,
        fetchPolicy: 'no-cache',
      })

      setAuthStatus(data.currentUser.__typename === 'User' ? 'authenticated' : 'unauthenticated')
    }

    if (authStatus === 'unauthenticated') {
      checkAuth()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client])

  const authenticationAdapter = createAuthenticationAdapter({
    getNonce: async () => {
      const { data }: { data: GetAuthNonceQuery } = await client.query({
        query: GetAuthNonceDocument,
        fetchPolicy: 'no-cache',
      })

      if (data.authNonce.__typename !== 'AuthNonceOk') {
        if (data.authNonce.message === 'Already authenticated') {
          setAuthStatus('authenticated')
        }
        return ''
      }

      return data.authNonce.result
    },
    createMessage: ({ nonce, address, chainId }) => {
      return new SiweMessage({
        domain: window.location.host,
        address,
        statement: 'Bine Games wants you to sign in with your wallet.',
        uri: window.location.origin,
        version: '1',
        chainId,
        nonce,
      })
    },
    getMessageBody: ({ message }: { message: SiweMessage }) => {
      return message.prepareMessage()
    },
    verify: async ({ message, signature }: { message: SiweMessage; signature: string }) => {
      setAuthStatus('loading')
      const { data } = await client.mutate<LoginMutation>({
        mutation: LoginDocument,
        variables: {
          message,
          signature,
        },
      })

      if (data.login.__typename !== 'LoginOk') {
        if (data.login.__typename !== 'AuthError' || data.login.message !== 'Already authenticated') {
          console.error(data.login.message)
          setAuthStatus('unauthenticated')
          return false
        }
      }

      setAuthStatus('authenticated')
      return true
    },
    signOut: async () => {
      AuthService.removeTokenFromStorage()
      AuthService.removeUserFromStorage()

      const { data } = await client.mutate<LogoutMutation>({
        mutation: LogoutDocument,
      })

      if (data.logout.__typename !== 'LogoutOk') {
        console.log(data.logout.message)
      }

      setAuthStatus('unauthenticated')
    },
  })

  return RainbowKitAuthenticationProvider({
    adapter: authenticationAdapter,
    status: authStatus,
    children,
  })
}
