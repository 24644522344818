export class AuthService {
  public static storeTokenToStorage(accessToken: string): void {
    window.localStorage.setItem('access-token', accessToken)
  }

  public static tokenExistsInStorage(): boolean {
    return window.localStorage.getItem('access-token') !== null
  }

  public static getTokenFromStorage(): string {
    return 'Bearer ' + window.localStorage.getItem('access-token')
  }

  public static removeTokenFromStorage(): void {
    window.localStorage.removeItem('access-token')
  }

  public static storeUserToStorage(user: any): void {
    window.localStorage.setItem('user', JSON.stringify(user))
  }

  public static userExistsInStorage(): boolean {
    return window.localStorage.getItem('user') !== null
  }

  public static getUserFromStorage(): any {
    return JSON.parse(window.localStorage.getItem('user'))
  }

  public static removeUserFromStorage(): void {
    window.localStorage.removeItem('user')
  }
}
