import styled from 'styled-components'
import { Icon } from '../../atoms'

import { IconProp } from '../../../lib/theme/types'
import Link from 'next/link'

const LinkContainer = styled(({ isActive, ...props }) => <Link {...props} />)<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50px;
  gap: 10px;
  text-decoration: none;

  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'not-allowed')};
`

const ActionContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50px;
  gap: 10px;
  text-decoration: none;

  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'not-allowed')};
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
`

const StyledText = styled.span<{ isActive?: boolean }>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: ${({ theme, isActive }) => (isActive === true ? 'white' : theme.colors.deep_koamaru_medium)};
`

export type DropdownItemProps = {
  leftIcon?: IconProp
  rightIcon?: IconProp
  text: string
  subtext?: string
  onClick?: (() => void) | string
  isActive?: boolean
}

export const DropdownItem = ({ leftIcon, rightIcon, text, subtext, onClick, isActive }: DropdownItemProps) => {
  const ContainerContent = (
    <>
      {leftIcon && (
        <IconContainer>
          <Icon icon={leftIcon} />
        </IconContainer>
      )}
      <div>
        <StyledText isActive={isActive}>{text}</StyledText>
        {subtext && <StyledText isActive={false}>{subtext}</StyledText>}
      </div>
      {rightIcon && (
        <div style={{ marginLeft: 'auto' }}>
          <IconContainer>
            <Icon icon={rightIcon} />
          </IconContainer>
        </div>
      )}
    </>
  )
  return typeof onClick !== 'string' ? (
    <ActionContainer onClick={onClick} isActive={isActive ?? false}>
      {ContainerContent}
    </ActionContainer>
  ) : (
    <LinkContainer href={onClick} isActive={isActive ?? false}>
      {ContainerContent}
    </LinkContainer>
  )
}
