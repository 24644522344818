import styled from 'styled-components'
import { Icon } from '../../atoms'
import type { IconProp, IconProps } from '../../../lib/theme/types'

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-down.svg'

const StyledIcon = styled(ArrowIcon)<{ open: boolean }>`
  margin-left: 5px;
  height: 8px;
  transform: ${({ open }) => (open ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: transform 0.2s ease-in-out;
`

type HeaderButtonProps = {
  label: string
  icon: IconProp
  iconProps?: Partial<IconProps>
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  dropdownOpen?: boolean
  minWidth?: number
  children?: React.ReactNode
}

const StyledContainer = styled.a<{ minWidth: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  white-space: nowrap;
  min-width: 60px;

  cursor: pointer;

  @media screen and (min-width: 1400px) {
    min-width: ${({ minWidth }) => minWidth}px;
  }
`

const StyledDropdownContent = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  position: absolute;
  z-index: 1;
  flex-direction: column;
`

const StyledLabel = styled.span`
  font-family: 'Inter';
  font-style: normal;
  display: none;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: 20px;
  line-height: 24px;

  color: white;

  @media screen and (min-width: 1400px) {
    display: block;
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const HeaderButton = ({
  label,
  icon,
  iconProps,
  onClick,
  onMouseEnter,
  onMouseLeave,
  dropdownOpen,
  minWidth,
  children,
}: HeaderButtonProps) => {
  return (
    <Wrapper>
      <StyledContainer
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        minWidth={minWidth ?? 80}
      >
        <Icon icon={icon} defaultProps={iconProps} />
        <StyledLabel>{label}</StyledLabel>
        {dropdownOpen !== undefined && <StyledIcon open={dropdownOpen} width={20} height={20} />}
      </StyledContainer>
      <StyledDropdownContent open={!!dropdownOpen}>{children}</StyledDropdownContent>
    </Wrapper>
  )
}
