import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'next-i18next'

import { HorizontalLine } from '../../atoms'
import { Accordion } from '../../organisms'

const ItemProperty = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;

  justify-content: space-between;
  align-items: center;

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 20px;
    line-height: 24px;

    color: ${({ theme }) => theme.colors.deep_koamaru_medium};
  }

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: 20px;
    line-height: 24px;
    text-align: right;
  }
`

const StyledAccordion = styled(Accordion)`
  h3 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
  }

  ${({ theme }) => theme.device.desktop} {
    h3 {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      font-size: 24px;
      line-height: 33px;
    }
  }
`

type ItemPropertiesProps = {
  properties: Record<string, unknown>
}

export const ItemProperties = ({ properties }: ItemPropertiesProps) => {
  const { t } = useTranslation('itempage')

  return (
    <>
      <HorizontalLine margin="18px 0" />
      <StyledAccordion title={t('properties')} open>
        <HorizontalLine margin="32px 0 0 0" />
        {Object.entries(properties).map(
          ([key, value]) =>
            ['string', 'number', 'boolean'].includes(typeof value) && (
              <React.Fragment key={key}>
                <ItemProperty>
                  <p>{key.substring(0, 1).toUpperCase() + key.substring(1)}</p>
                  <span>{String(value)}</span>
                </ItemProperty>
                <HorizontalLine margin="0 0 0 0" />
              </React.Fragment>
            ),
        )}
      </StyledAccordion>
    </>
  )
}
