import type { ImageProps as NextImageProps } from 'next/image'
import { useState } from 'react'
import { ImageContent, Container, SkeletonLoader } from './styles'

export interface ImageProps extends NextImageProps {
  withLoader?: boolean
}

const Image = ({ withLoader = true, ...props }: ImageProps) => {
  const { width, height } = props
  const [loading, setLoading] = useState<boolean>(withLoader)

  const onLoad = () => setLoading(prevState => !prevState)

  return (
    <Container {...{ width, height }}>
      {withLoader && loading && <SkeletonLoader {...{ width, height }} />}
      <ImageContent {...props} onLoad={onLoad} />
    </Container>
  )
}

export default Image
