import styled, { css } from 'styled-components/macro'
import { Animation } from '../..'

export const Button = styled.button<{ outline: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 1rem auto;
  ${({ outline }) =>
    outline
      ? css`
          border: 3px solid ${({ theme }) => theme.colors.special_away_dark};
          background: transparent;

          &:hover {
            border-color: ${({ theme }) => theme.colors.special_away_bright};
          }

          &:disabled {
            border-color: ${({ theme }) => theme.colors.special_away_bright};
          }
        `
      : css`
          background-image: ${({ theme }) => theme.gradients.theme_gradient};
          border: 0px;

          &:hover {
            background-image: ${({ theme }) =>
              theme.gradients.dark_yellow_gradient};
          }

          &:disabled {
            background-image: ${({ theme }) =>
              theme.gradients.dark_yellow_gradient};
          }
        `}
  width: 100%;
  min-height: 52px;
  border-radius: 100rem;
`

export const Title = styled.p<{ outline?: boolean }>`
  ${({ outline }) =>
    outline
      ? css`
          background: -webkit-linear-gradient(180deg, #f5c959 0%, #ff9d43 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        `
      : css`
          color: ${({ theme }) => theme.colors.deep_koamaru_darkest};
        `}
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.typeScale.description2};
  line-height: ${({ theme }) => theme.lineHeight.description2};
  padding: 0px 8px;
`

export const Loader = styled(Animation)`
  width: 54px;
  height: 54px;
`
