import styled from 'styled-components/macro'
import Link from 'next/link'

import { ReactComponent as LeftArrowIcon } from '../../assets/icons/left-arrow.svg'
import { ReactComponent as RefreshIcon } from '../../assets/icons/refresh.svg'
import { ReactComponent as ShareIcon } from '../../assets/icons/share.svg'
import { useTranslation } from 'next-i18next'

type Props = {
  prevHref: string
  onRefresh: (() => void) | null
  onShare: (() => void) | null
  transparent?: boolean
}

const SubheaderContainer = styled.div<{ transparent: boolean }>`
  overflow-y: visible;
  z-index: 1;

  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: ${({ transparent }) => (transparent ? '0px' : '64px')};

  margin-top: 0;

  ${({ theme }) => theme.device.desktop} {
    height: ${({ transparent }) => (transparent ? '0px' : '69px')};
  }
`

const StyledSubheaderNavigation = styled.div`
  width: 100%;
  height: 66px;
  background: rgba(45, 45, 107, 0.5);
  backdrop-filter: blur(15px);

  ${({ theme }) => theme.device.desktop} {
    height: 71px;
  }
`

const StyledSubheaderNavigationContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 24px);
  height: inherit;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 12px;

  ${({ theme }) => theme.device.desktop} {
    width: 100%;
    padding: 0;
  }
`

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  border: none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: 24px;
  line-height: 29px;
  color: ${({ theme }) => theme.colors.deep_koamaru_medium};
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  border: none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: 24px;
  line-height: 29px;
  color: ${({ theme }) => theme.colors.deep_koamaru_medium};
  text-decoration: none;
`

const IconContainer = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
`

const StyledSpan = styled.span`
  margin-left: 15px;

  ${({ theme }) => theme.device.phone} {
    display: none;
  }
`

export const SubheaderNavigation = ({ prevHref, onRefresh = null, onShare = null, transparent = false }: Props) => {
  const { t } = useTranslation('common')
  // const [device, setDevice] = useState('desktop')

  // useEffect(() => {
  //   setDevice(detectDevice(window?.innerWidth))
  // }, [])

  return (
    <SubheaderContainer transparent={transparent}>
      <StyledSubheaderNavigation>
        <StyledSubheaderNavigationContent>
          <IconContainer>
            <StyledLink href={prevHref}>
              <LeftArrowIcon /> <StyledSpan>{t('back')}</StyledSpan>
            </StyledLink>
          </IconContainer>
          <IconContainer>
            {onRefresh === null ? null : (
              <StyledButton onClick={onRefresh}>
                {/* <Image
            width={device === 'mobile' ? 22 : 39}
            height={device === 'mobile' ? 22 : 39}
            src={RefreshIcon}
            alt="refresh"
          /> */}
                <RefreshIcon />
              </StyledButton>
            )}
            {onShare === null ? null : (
              <StyledButton onClick={onShare}>
                {/* <Image
            width={device === 'mobile' ? 26 : 39}
            height={device === 'mobile' ? 26 : 39}
            src={ShareIcon}
            alt="share"
          /> */}
                <ShareIcon />
              </StyledButton>
            )}
          </IconContainer>
        </StyledSubheaderNavigationContent>
      </StyledSubheaderNavigation>
    </SubheaderContainer>
  )
}
