import { useTheme } from 'styled-components/macro'
import type { IconProp, Color, IconProps as BasicIconProps, IconName } from '../../../lib/theme/types'
import * as Icons from './assets'
import { capitalizeFirstLetter } from '../../theme/tools'

export interface NativeIconProps extends Omit<BasicIconProps, 'fill' | 'stroke'> {
  fill?: string
  stroke?: string
}

export interface IconProps {
  icon?: IconProp
  defaultProps?: Partial<BasicIconProps>
  className?: string
}

const Icon = ({ icon, defaultProps, className }: IconProps) => {
  const theme = useTheme()

  const getColor = (color?: Color) => {
    if (color) {
      return theme.colors[color]
    }
    return ''
  }

  if (typeof icon === 'undefined' && typeof defaultProps === 'undefined') {
    return null
  }

  if (typeof icon === 'string') {
    const nativeIconProps: NativeIconProps = {
      name: icon,
      strokeWidth: defaultProps?.strokeWidth,
      fill: getColor(defaultProps?.fill),
      stroke: getColor(defaultProps?.stroke),
      ...theme.tools.getSize(defaultProps?.size),
    }
    // Remove undefined values
    const iconProps = JSON.parse(JSON.stringify(nativeIconProps))

    const NativeIcon = Icons[capitalizeFirstLetter<Capitalize<IconName>>(icon)]

    return <NativeIcon {...iconProps} />
  }

  const nativeIconProps: NativeIconProps = {
    name: icon?.name || defaultProps?.name,
    strokeWidth: icon?.strokeWidth || defaultProps?.strokeWidth,
    fill: getColor(icon?.fill || defaultProps?.fill),
    stroke: getColor(icon?.stroke || defaultProps?.stroke),
    ...theme.tools.getSize(icon?.size || defaultProps?.size),
  }
  // Remove undefined values
  const iconProps = JSON.parse(JSON.stringify(nativeIconProps))

  if (!nativeIconProps.name) {
    return null
  }

  const NativeIcon = Icons[capitalizeFirstLetter<Capitalize<IconName>>(nativeIconProps.name)]
  return <NativeIcon {...defaultProps} {...iconProps} className={className} />
}

export default Icon
