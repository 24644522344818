import { useEffect } from 'react'
import styled, { css } from 'styled-components/macro'

export { SidebarHeader } from './Header'

interface SidebarProps {
  isOpen: boolean
  children: React.ReactNode
  top?: number
}

const StyledSidebar = styled.div<SidebarProps>`
  position: fixed;
  top: ${({ top }) => top}px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.deep_koamaru_primary};
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  /* fuck css */
  z-index: 999;

  ${props =>
    props.isOpen &&
    css`
      transform: translateX(0);
    `}
`

const SidebarContent = styled.div`
  height: auto;

  display: flex;
  flex-direction: column;
`

const SidebarOverlay = styled.div<{ isOpen: boolean; top: number }>`
  position: fixed;
  top: ${({ top }) => top}px;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  ${props =>
    props.isOpen &&
    css`
      opacity: 1;
      pointer-events: auto;
    `};
`

export const Sidebar = ({ isOpen, children, top }: SidebarProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <>
      <StyledSidebar top={top || 0} isOpen={isOpen}>
        <SidebarContent>{children}</SidebarContent>
      </StyledSidebar>
      <SidebarOverlay top={top || 0} isOpen={isOpen} />
    </>
  )
}
