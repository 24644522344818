import styled, { css } from 'styled-components/macro'

export const Button = styled.button<{ outline: boolean; filled: boolean }>`
  display: flex;
  cursor: pointer;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  ${({ outline, filled }) =>
    filled
      ? outline
        ? css`
            border: 3px solid ${({ theme }) => theme.colors.special_away_dark};
            background: transparent;

            &:hover {
              border-color: ${({ theme }) => theme.colors.special_away_bright};
            }

            &:disabled {
              border-color: ${({ theme }) => theme.colors.special_away_bright};
            }
          `
        : css`
            background-image: ${({ theme }) => theme.gradients.theme_gradient};
            border: 0px;

            &:hover {
              background-image: ${({ theme }) =>
                theme.gradients.dark_yellow_gradient};
            }

            &:disabled {
              background-image: ${({ theme }) =>
                theme.gradients.dark_yellow_gradient};
            }
          `
      : outline
      ? css`
          border: 1px solid ${({ theme }) => theme.colors.deep_koamaru_dark};
        `
      : css`
          border: 0px;
        `}
  transition: background-color 0.25s ease-in;

  &:hover:not([disabled]) {
    background-color: ${({ theme }) => theme.colors.deep_koamaru_light};
    transition: background-color 0.25s ease-in;
  }

  ${({ theme }) => theme.device.desktop} {
    width: 46px;
    height: 46px;
  }
`
