import { useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FlexBox, HorizontalLine, Icon } from '../../atoms'
import { BurgerMenu } from '../../atoms/Icon/assets'
import { Sidebar } from '../../molecules/Sidebar'
import { LanguageSelector } from '../../molecules/LanguageSelector'
import { useDisplayType, useWindowSize } from '../../hooks'
import { HeaderButton, MobileSidebarSearch, ThemeSwitcher } from '../../molecules'
import { GameDropdown } from '../GameDropdown'
import { HeaderDropdown } from '../HeaderDropdown'

import Link from 'next/link'
import { NoLightModeScreen } from '../NoLightModeScreen'

import { HeaderProfile, type HeaderProfileProps } from './dropdowns/profile'
import { Accordion } from '../Accordion'

import { CommunityDropdown } from './dropdowns'

type HeaderProps = {
  profile: HeaderProfileProps
}

const SidebarAccordions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  height: calc(100vh - 300px);
  height: calc(100dvh - 300px);
`

const SidebarDownMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin: 0 auto;

  gap: 20px;
  width: 90%;
`

const StyledContainer = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0 12px;
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.device.desktop} {
    padding: 0 46px;
  }

  .header_mobile_content {
    display: flex;

    ${({ theme }) => theme.device.desktop} {
      display: none;
    }
  }

  .header_desktop_content {
    display: none;

    ${({ theme }) => theme.device.desktop} {
      display: flex;
    }
  }

  .header_desktop_content,
  .header_mobile_content {
    width: 100%;
    justify-content: flex-end;
  }
`

const StyledMobileContent = styled(FlexBox)`
  width: 100%;
  display: flex;

  ${({ theme }) => theme.device.desktop} {
    display: none;
  }
`

const StyledDesktopContent = styled(FlexBox)`
  width: 100%;
  display: none;

  ${({ theme }) => theme.device.desktop} {
    display: flex;
  }
`

const StyledWrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 50;
  background-color: ${({ theme }) => theme.colors.deep_koamaru_primary};

  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.deep_koamaru_medium_dark};
  border-top: 1px solid ${({ theme }) => theme.colors.deep_koamaru_primary};

  height: 64px;

  ${({ theme }) => theme.device.desktop} {
    height: 97px;
  }
`

type OpenDropdown = 'games' | 'community' | 'search' | 'language' | null

// TODO: Refactor this fat component
export const Header = ({ profile }: HeaderProps) => {
  const { t } = useTranslation(['common', 'header'])
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [openDropdown, setOpenDropdown] = useState<OpenDropdown>(null)
  const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 })
  const [lightmodeOpen, setLightmodeOpen] = useState(false)
  const router = useRouter()

  const onToggleLanguageClick = (newLocale: string) => {
    const { pathname, asPath, query } = router
    router.push({ pathname, query }, asPath, { locale: newLocale })
  }

  const onDropdownClick = (value: OpenDropdown) => {
    if (openDropdown === value) {
      setOpenDropdown(null)
    } else {
      setOpenDropdown(value)
    }
  }

  const display = useDisplayType()
  const width = useWindowSize()[0]

  const languageDropdown = (
    <HeaderDropdown
      open={openDropdown === 'language'}
      onClose={() => setOpenDropdown(null)}
      style={{ marginTop: '8px', marginLeft: `${Math.max(0, (420 - width) / 2)}px` }}
      items={{
        English: {
          isActive: true,
          leftIcon: 'earth',
          onClick: () => onToggleLanguageClick('en'),
        },
        Español: {
          isActive: true,
          leftIcon: 'spain',
          onClick: () => onToggleLanguageClick('es'),
        },
        Українська: {
          isActive: true,
          leftIcon: 'ukraine',
          onClick: () => onToggleLanguageClick('ua'),
        },
        // 中文: {
        //   isActive: true,
        //   leftIcon: 'china',
        //   onClick: () => onToggleLanguageClick('zh'),
        // },
        // 'Tiếng Việt': {
        //   isActive: true,
        //   leftIcon: 'vietnam',
        //   onClick: () => onToggleLanguageClick('vi'),
        // },
      }}
    />
  )

  return (
    <StyledWrapper>
      <StyledContainer>
        <Link href="/">
          <Icon defaultProps={{ size: display !== 'desktop' ? 70 : 130 }} icon="logo" />
        </Link>
        <StyledMobileContent justify="flex-end">
          <BurgerMenu onClick={() => setIsSidebarOpen(prevState => !prevState)} />
          <Sidebar top={66} isOpen={isSidebarOpen}>
            <MobileSidebarSearch />
            <HorizontalLine />
            <SidebarAccordions>
              <Accordion
                title={t('games')}
                icon={{
                  icon: { name: 'gamepad' },
                  defaultProps: { fill: 'deep_koamaru_primary' },
                }}
              >
                123
              </Accordion>
              <HorizontalLine />
              <Accordion
                title={t('header:community')}
                icon={{
                  icon: { name: 'community' },
                  defaultProps: { fill: 'deep_koamaru_primary' },
                }}
              >
                123
              </Accordion>
              <HorizontalLine />
            </SidebarAccordions>
            <SidebarDownMenu>
              <HorizontalLine />
              <FlexBox direction="row" justify="space-between" align="center" gap={16}>
                <LanguageSelector
                  open={openDropdown === 'language'}
                  onClick={() => onDropdownClick('language')}
                  small={false}
                >
                  {languageDropdown}
                </LanguageSelector>
                <ThemeSwitcher isLightTheme={lightmodeOpen} onClick={setLightmodeOpen} />
              </FlexBox>
              <HeaderProfile {...profile} bell={false} />
            </SidebarDownMenu>
          </Sidebar>
        </StyledMobileContent>
        <StyledDesktopContent gap={20} justify="flex-end" align="center">
          <HeaderButton label={t('header:search')} icon="search" minWidth={140} />
          <HeaderButton
            label={t('games')}
            icon="gamepad"
            iconProps={{ fill: 'deep_koamaru_primary' }}
            dropdownOpen={openDropdown === 'games'}
            onClick={() => onDropdownClick('games')}
            minWidth={156}
          />
          <HeaderButton
            label={t('header:community')}
            icon="community"
            dropdownOpen={openDropdown === 'community'}
            onClick={() => onDropdownClick('community')}
            minWidth={200}
          >
            <CommunityDropdown onClose={() => setOpenDropdown(null)} />
          </HeaderButton>
          <ThemeSwitcher isLightTheme={lightmodeOpen} onClick={setLightmodeOpen} />
          <LanguageSelector open={openDropdown === 'language'} small={true} onClick={() => onDropdownClick('language')}>
            {languageDropdown}
          </LanguageSelector>
          <HeaderProfile {...profile} />
        </StyledDesktopContent>
        <GameDropdown
          open={openDropdown === 'games'}
          onClose={() => setOpenDropdown(null)}
          items={[
            {
              title: 'Arcane Legends',
              href: '/',
              bgSrc:
                'https://i0.wp.com/www.unitedgamers.gg/wp-content/uploads/2022/07/Gods-Unchained.jpg?fit=1600%2C894&ssl=1',
            },
            {
              title: 'Axie Infinity',
              href: '/',
              bgSrc: 'https://playtoearndiary.com/wp-content/uploads/2022/08/Axie-Banner.jpg',
            },
            {
              title: 'Dota 2',
              href: '/',
              bgSrc: 'https://cdn.akamai.steamstatic.com/steam/apps/570/header.jpg?t=1682639497',
            },
            {
              title: 'CS:GO 2',
              href: '/',
              bgSrc: 'https://cdn.akamai.steamstatic.com/steam/apps/730/header.jpg?t=1683566799',
            },
          ]}
        />

        <NoLightModeScreen open={lightmodeOpen} onClose={() => setLightmodeOpen(false)} />
      </StyledContainer>
    </StyledWrapper>
  )
}
