import { useTranslation } from 'next-i18next'
import { CheckboxInput } from '../../../molecules'
import { Accordion } from '../../../organisms'
import styled from 'styled-components/macro'

const AccordionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 16px;
`

type CheckboxFilterSelectorProps<T extends string> = {
  title: string
  items: T[]
  selectedItems: T[]
  onChange?: (items: T[]) => void
}

export const CheckboxFilterSelector = <T extends string>({
  title,
  items,
  selectedItems,
  onChange = () => null,
}: CheckboxFilterSelectorProps<T>) => {
  const { t } = useTranslation('common')

  return (
    <Accordion title={title} open>
      <AccordionContent>
        {items.map(item => {
          return (
            <CheckboxInput
              key={item}
              label={t(item.toLowerCase().replace(/ /g, '_'))}
              value={item}
              checked={selectedItems.includes(item)}
              onChanged={checked =>
                checked ? onChange([...selectedItems, item]) : onChange(selectedItems.filter(i => i !== item))
              }
            />
          )
        })}
      </AccordionContent>
    </Accordion>
  )
}
