import styled from 'styled-components'
import { Icon } from '../../atoms'
import React from 'react'

const StyledContainer = styled.a<{ open: boolean }>`
  box-sizing: border-box;

  min-width: 76px;
  height: 48px;

  background: ${({ theme, open }) =>
    open
      ? theme.colors.neutral_heavenly_white
      : theme.colors.deep_koamaru_darkest};

  transition: background 0.15s ease-in-out;
  border-radius: 30px;

  cursor: pointer;
`

const StyledKnob = styled.div<{ open: boolean }>`
  position: relative;
  width: 42px;
  height: 41px;
  left: ${({ open }) => (open ? '31px' : '3px')};
  top: 3px;

  background: ${({ theme, open }) =>
    open ? 'white' : theme.colors.deep_koamaru_primary};
  border-radius: 50%;

  transition: left 0.15s ease-in-out, background 0.15s ease-in-out;

  overflow: hidden;

  svg:first-child {
    position: relative;
    top: ${({ open }) => (open ? '-32px' : '7px')};
    left: 9px;

    transition: top 0.15s ease-in-out;
  }

  svg:last-child {
    position: relative;
    top: ${({ open }) => (open ? '-20px' : '21px')};
    left: 9px;

    transition: top 0.15s ease-in-out;
  }
`

type ThemeSwitcherProps = {
  isLightTheme: boolean
  onClick?: (isLightTheme: boolean) => void
}

export const ThemeSwitcher = ({
  isLightTheme,
  onClick = _ => null,
}: ThemeSwitcherProps) => {
  return (
    <StyledContainer onClick={() => onClick(!isLightTheme)} open={isLightTheme}>
      <StyledKnob open={isLightTheme}>
        <Icon icon={{ name: 'moon', size: 25 }} />
        <Icon icon={{ name: 'sun', size: 25 }} />
      </StyledKnob>
    </StyledContainer>
  )
}
