import { createGlobalStyle, ThemeProvider } from 'styled-components/macro'
import { defaultTheme } from '@bine-frontend-monorepo/uikit'
import { appWithTranslation } from 'next-i18next'
import { ApolloProvider } from '@apollo/client'
import { initializeApollo } from '../app/apollo-client'
import { ProvidersWrapper } from '../app/helpers/ProvidersWrapper'
import { environment } from '../environments/environment'
import { publicProvider } from 'wagmi/providers/public'
import NextNProgress from 'nextjs-progressbar'
import { ToastContainer } from 'react-toastify'
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { mainnet, polygon, bsc, arbitrum } from 'wagmi/chains'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { BineAuthenticationProvider } from '../app/helpers/createAuthenticationAdapter'

import type { AppProps } from 'next/app'

import 'react-toastify/dist/ReactToastify.min.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'video-react/dist/video-react.css'
import '@rainbow-me/rainbowkit/styles.css'
import { bineTestnet } from '../app/helpers/bineTestnetChain'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Inter', sans-serif;
    src: url('@bine-frontend-monorepo/uikit/src/lib/assets/fonts/Inter/Inter-Bold.ttf') format('ttf');
    font-style: normal;
    font-weight: bold;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Inter', sans-serif;
    src: url('@bine-frontend-monorepo/uikit/src/lib/assets/fonts/Inter/Inter-Medium.ttf') format('ttf');
    font-style: normal;
    font-weight: 500;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Inter', sans-serif;
    src: url('@bine-frontend-monorepo/uikit/src/lib/assets/fonts/Inter/Inter-Regular.ttf') format('ttf');
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Inter', sans-serif;
    src: url('@bine-frontend-monorepo/uikit/src/lib/assets/fonts/Inter/Inter-Thin.ttf') format('ttf');
    font-style: normal;
    font-weight: 300;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Manrope', sans-serif;
    src: url('@bine-frontend-monorepo/uikit/src/lib/assets/fonts/Manrope/Manrope-Bold.ttf') format('ttf');
    font-style: normal;
    font-weight: bold;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Manrope', sans-serif;
    src: url('@bine-frontend-monorepo/uikit/src/lib/assets/fonts/Manrope/Manrope-Regular.ttf')
      format('ttf');
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Manrope', sans-serif;
    src: url('@bine-frontend-monorepo/uikit/src/lib/assets/fonts/Manrope/Manrope-ExtraLight.ttf')
      format('ttf');
    font-style: normal;
    font-weight: 300;
    font-display: fallback;
  }

  .Toastify__toast-container--top-right {
    top: 75px;

    @media screen and (min-width: 768px) {
      top: 100px;
    }
  }

  :root {
    --toastify-font-family: 'Manrope', sans-serif;

    --toastify-color-info: linear-gradient(90deg, rgba(205, 141, 255, 0.4) 0%, rgba(205, 141, 255, 0) 100%), rgba(65, 67, 118, 0.6);
    --toastify-color-success: linear-gradient(90deg, rgba(164, 220, 94, 0.30) 0%, rgba(164, 220, 94, 0.00) 100%), rgba(65, 67, 118, 0.80);
    --toastify-color-warning: linear-gradient(90deg, rgba(245, 201, 89, 0.30) 0%, rgba(245, 201, 89, 0.00) 100%), rgba(65, 67, 118, 0.60);
    --toastify-color-error: linear-gradient(90deg, rgba(245, 112, 111, 0.40) 0%, rgba(245, 112, 111, 0.00) 100%), rgba(65, 67, 118, 0.60);

    --toastify-icon-color-info: #A565D7;
    --toastify-icon-color-success: #86BE40;
    --toastify-icon-color-warning: #E1B545;
    --toastify-icon-color-error: #CD4847;

    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #A4DC5E;
    --toastify-text-color-warning: #F5C959;
    --toastify-text-color-error: #fff;
  }

  html {
    width: 100%;
    box-sizing: border-box;
  }

  body {
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter';
    color: ${defaultTheme.colors.neutral_white};
    background-color: ${defaultTheme.colors.deep_koamaru_primary};
  }

  #__next {
    height: 100%;
  }

`

const { chains, publicClient } = configureChains([mainnet, polygon, arbitrum, bsc, bineTestnet], [publicProvider()])

const { connectors } = getDefaultWallets({
  appName: 'BINE GAMES',
  projectId: environment.WALLET_CONNECT_ID,
  chains,
})

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
})

declare global {
  interface Window {
    ethereum: any
  }
}

function BineApp({ Component, pageProps }: AppProps) {
  const client = initializeApollo(pageProps.initialApolloState)

  return (
    <>
      <NextNProgress color="#D5D5E0" />
      <GlobalStyle />
      <ProvidersWrapper
        providers={[
          [ApolloProvider, { client }],
          [ThemeProvider, { theme: defaultTheme }],
          [WagmiConfig, { config: wagmiConfig }],
          [BineAuthenticationProvider],
          [RainbowKitProvider, { chains }],
        ]}
      >
        <Component {...pageProps} />
        <ToastContainer theme="colored" limit={5} />
      </ProvidersWrapper>
    </>
  )
}

export default appWithTranslation(BineApp)
