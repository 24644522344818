import styled from 'styled-components/macro'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import { ItemCardData, ItemCard } from '../../molecules/cards/ItemCard'
import { useDisplayType } from '../../hooks'
import { useTranslation } from 'next-i18next'

const StyledSection = styled.section`
  margin-top: 54px;
  margin-bottom: 54px;

  ${({ theme }) => theme.device.desktop} {
    margin-top: 80px;
    margin-bottom: 80px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .swiper-button-prev,
  .swiper-button-next {
    color: ${({ theme }) => theme.colors.neutral_white};
    left: initial;
    top: initial;
    right: initial;
    position: unset;
    margin-top: 5px;

    &::after {
      font-size: 19.5px;
    }
  }

  .swiper-button-prev {
    margin-right: 10px;
  }
`

const StyledHeader = styled.h2`
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.neutral_white};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-family: 'Manrope', sans-serif;

  ${({ theme }) => theme.device.desktop} {
    font-size: 24px;
    line-height: 29px;
  }
`

const SwiperButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`

export type RelatedItemsProps = {
  items: ItemCardData[]
}

export const RelatedItems: React.FC<RelatedItemsProps> = ({ items }) => {
  const { t } = useTranslation('itempage')

  return !items || items.length === 0 ? null : (
    <StyledSection>
      <Row>
        <StyledHeader>{t('related_items')}</StyledHeader>
        <SwiperButtonsContainer>
          <div className="swiper-button-prev related-items-nav"></div>
          <div className="swiper-button-next related-items-nav"></div>
        </SwiperButtonsContainer>
      </Row>

      <Swiper
        navigation={{
          nextEl: '.swiper-button-next.related-items-nav',
          prevEl: '.swiper-button-prev.related-items-nav',
        }}
        modules={[Navigation]}
        allowTouchMove={true}
        pagination={{
          clickable: true,
        }}
        spaceBetween={12}
        slidesPerView={2}
        breakpoints={{
          500: {
            slidesPerView: 3,
          },
          660: {
            slidesPerView: 4,
          },
          820: {
            slidesPerView: 5,
          },
          1024: {
            slidesPerView: 2,
          },
          1180: {
            slidesPerView: 3,
          },
          1600: {
            slidesPerView: 4,
          },
          1920: {
            slidesPerView: 5,
          },
        }}
      >
        {items.map((item, idx) => (
          <SwiperSlide key={idx}>
            <ItemCard item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledSection>
  )
}
