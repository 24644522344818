import { useTranslation } from 'next-i18next'
import styled from 'styled-components/macro'

export type ItemHistoryTransferProps = {
  id: string
  from: {
    id: string
    name: string
  }
  to: {
    id: string
    name: string
  }
  timestamp: string
  amount: number
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
  margin-top: 18px;
  margin-bottom: 18px;

  width: 100%;
`

const Header = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: 16px;
  line-height: 19px;
  margin: 0;

  ${({ theme }) => theme.device.desktop} {
    font-size: 20px;
    line-height: 24px;
  }

  span {
    color: ${({ theme }) => theme.colors.royal_orange_primary};
  }
`

const Details = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: 14px;
  line-height: 17px;
  margin: 0;

  color: ${({ theme }) => theme.colors.deep_koamaru_medium};

  ${({ theme }) => theme.device.desktop} {
    font-size: 16px;
    line-height: 19px;
  }

  span {
    color: white;
  }
`

export const ItemHistoryTransfer = ({ from, to, timestamp, amount }: ItemHistoryTransferProps) => {
  const { t } = useTranslation(['common', 'itempage'])
  const date = new Date(parseInt(timestamp) * 1000)

  return (
    <Container>
      {from.id === '0x0000000000000000000000000000000000000000' ? (
        <>
          <Header>
            {t('itempage:minted_for')} <span>{to.name ?? to.id}</span> {`(x${amount})`}
          </Header>
          <Details>
            {date.toLocaleDateString()} {date.toLocaleTimeString()}
          </Details>
        </>
      ) : (
        <>
          <Header>
            {t('itempage:transfered_to')} <span>{to.name ?? to.id}</span> {`(x${amount})`}
          </Header>
          <Details>
            {t('by').toLowerCase()} <span>{from.name ?? from.id}</span> {date.toLocaleDateString()}{' '}
            {date.toLocaleTimeString()}
          </Details>
        </>
      )}
    </Container>
  )
}
