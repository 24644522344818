import styled, { css, keyframes } from 'styled-components/macro'
import { SnackbarError, SnackbarInfo, SnackbarSuccess, SnackbarWarning, SnackbarCross } from '../../atoms/Icon/assets'
import { FlexBox } from '../../atoms'
import { useEffect } from 'react'

type SnackbarProps = {
  children: React.ReactNode
  type: 'success' | 'error' | 'warning' | 'info'
  closing?: boolean
  startClosing: () => void
  removeSnackbar: () => void
}

const CloseButton = styled.button`
  background-color: transparent;
  color: #fff;
  border: none;
  outline: none;
  margin-left: 8px;
  cursor: pointer;
`

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
`

const slideOut = keyframes`
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
`

const renderIcon = (type: SnackbarProps['type']) => {
  switch (type) {
    case 'success':
      return <SnackbarSuccess style={{ marginTop: 12 }} />
    case 'error':
      return <SnackbarError style={{ marginTop: 12 }} />
    case 'warning':
      return <SnackbarWarning style={{ marginTop: 12 }} />
    case 'info':
      return <SnackbarInfo style={{ marginTop: 12 }} />
  }
}

const StyledSnackbar = styled.div<Pick<SnackbarProps, 'type'> & { closing: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 14px 16px 20px;
  height: fit-content;
  gap: 10px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: 16px;
  font-family: 'Manrope';
  transition: transform 0.3s, margin-bottom 0.3s;
  transform-origin: top;
  transform: ${({ closing }) => (closing ? 'scaleY(0)' : 'scaleY(1)')};
  margin-bottom: ${({ closing }) => (closing ? '0px' : '10px')};
  overflow: hidden;

  animation: ${({ closing }) =>
    closing
      ? css`
          ${slideOut} 0.3s ease-in forwards
        `
      : css`
          ${slideIn} 0.3s ease-in forwards
        `};

  width: 258px;

  ${({ type }) => {
    switch (type) {
      case 'success':
        return css`
          background: linear-gradient(90deg, rgba(164, 220, 94, 0.3) 0%, rgba(164, 220, 94, 0) 100%),
            rgba(65, 67, 118, 0.8);
          border: 1px solid #68a022;
          color: ${({ theme }) => theme.colors.special_online_dark};
        `
      case 'error':
        return css`
          background: linear-gradient(
              90deg,
              rgba(245, 112, 111, 0.4) 0%,
              rgba(245, 112, 111, 0) 100%
            ),
            rgba(65, 67, 118, 0.6);
          border: 1px solid #e16665;
          color: ${({ theme }) => theme.colors.neutral_white}};
        `
      case 'warning':
        return css`
          background: linear-gradient(90deg, rgba(245, 201, 89, 0.3) 0%, rgba(245, 201, 89, 0) 100%),
            rgba(65, 67, 118, 0.6);
          border: 1px solid #b9945c;
          color: ${({ theme }) => theme.colors.special_away_dark};
        `
      case 'info':
        return css`
          background: linear-gradient(90deg, rgba(205, 141, 255, 0.4) 0%, rgba(205, 141, 255, 0) 100%),
            rgba(65, 67, 118, 0.6);
          border: 1px solid #9b5bcd;
          color: ${({ theme }) => theme.colors.neutral_white};
        `
    }
  }}
  backdrop-filter: blur(12px);
  border-radius: 8px;

  ${({ theme }) => theme.device.desktop} {
    width: 558px;
    height: 44px;

    font-size: 20px;

    margin-bottom: ${({ closing }) => (closing ? '0px' : '20px')};
  }
`
export const Snackbar = ({ children, type, closing = false, startClosing, removeSnackbar }: SnackbarProps) => {
  useEffect(() => {
    if (closing) {
      const timeoutId = setTimeout(() => {
        removeSnackbar()
      }, 350)

      return () => {
        clearTimeout(timeoutId)
      }
    }
    return
  }, [closing, removeSnackbar])

  return (
    <StyledSnackbar closing={closing} type={type}>
      <FlexBox gap={20} align="center">
        {renderIcon(type)}
        {children}
      </FlexBox>
      <CloseButton onClick={startClosing}>
        <SnackbarCross />
      </CloseButton>
    </StyledSnackbar>
  )
}
