import React from 'react'
import { FlexBox, HorizontalLine, PrimaryButton } from '../../atoms'
import styled, { css } from 'styled-components/macro'

import { CheckboxFilterSelector } from './CheckboxFilterSelector'
import { RadioFilterSelector } from './RadioFilterSelector'
import { useId } from 'react'
import { useTranslation } from 'next-i18next'

const Filters = styled.div<{ mobile?: boolean }>`
  display: flex;

  ${({ mobile }) =>
    mobile &&
    css`
      height: calc(100vh - 72px);
      height: calc(100dvh - 72px);
    `}

  flex-direction: column;
  justify-content: flex-start;
  align-items: top;
  gap: 24px;
  margin-top: 24px;
`

type BaseFilterSetting = {
  title: string
}

type RadioFilterSetting = BaseFilterSetting & {
  options: string[]
  value: string
}

type CheckboxFilterSetting = BaseFilterSetting & {
  options: string[]
  value: string[]
}

type AnyFilterSetting = RadioFilterSetting | CheckboxFilterSetting

export type FilterChangeData = {
  filters: Record<string, AnyFilterSetting>
  submit: boolean
}

type ExploreItemsFiltersProps = {
  settings: FilterChangeData
  onFilterChange?: (newSettings: FilterChangeData) => void
  buttonsRequired?: boolean
}

export const ExploreItemsFilters = ({
  settings,
  onFilterChange = () => null,
  buttonsRequired = false,
}: ExploreItemsFiltersProps) => {
  const { t } = useTranslation('common')
  const id = useId()

  return (
    <Filters mobile={buttonsRequired}>
      {Object.entries(settings.filters).map(([key, setting], i) => {
        const onChange = (state: string | string[]) =>
          onFilterChange({
            filters: {
              ...settings.filters,
              [key]: {
                title: setting.title,
                options: setting.options,
                value: state,
              } as AnyFilterSetting,
            },
            submit: !buttonsRequired,
          })

        if (typeof setting.value === 'string')
          return (
            <React.Fragment key={id + key}>
              {i !== 0 && <HorizontalLine />}
              <RadioFilterSelector
                title={setting.title}
                items={setting.options}
                selectedItem={setting.value}
                onChange={onChange}
              />
            </React.Fragment>
          )
        else
          return (
            <React.Fragment key={id + key}>
              {i !== 0 && <HorizontalLine />}
              <CheckboxFilterSelector
                title={setting.title}
                items={setting.options}
                selectedItems={setting.value}
                onChange={onChange}
              />
            </React.Fragment>
          )
      })}
      {buttonsRequired && (
        <FlexBox gap={16}>
          <PrimaryButton title={t('reset')} outline />
          <PrimaryButton
            title={t('apply')}
            onClick={() =>
              onFilterChange({
                ...settings,
                submit: true,
              })
            }
          />
        </FlexBox>
      )}
      {/* 
      
      <div style={{ minHeight: '60px' }}> </div>
      { <HorizontalLine margin="16px 0" />
            <Accordion title="Price range" open>
              <AccordionContent>
                <RangeSlider
                  min={10}
                  max={50}
                  value={{ from: 0, to: 100 }}
                  step={1}
                  onChange={({ from, to }) => {
                    console.log(from, to)
                  }}
                />
              </AccordionContent>
            </Accordion> } */}
    </Filters>
  )
}
