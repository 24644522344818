import type { IconProps } from '../../Icon'
import { Button } from './styles'
import { Icon } from '../..'

export interface IconButtonProps
  extends Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    'title' | 'size'
  > {
  icon?: IconProps
  outline?: boolean
  filled?: boolean
}

export const IconButton = ({
  icon,
  outline = false,
  filled = false,
  ...props
}: IconButtonProps) => {
  return (
    <Button {...props} outline={outline} filled={filled}>
      <Icon {...icon} />
    </Button>
  )
}
