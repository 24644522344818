import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import styled from 'styled-components/macro'

const IdsBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  font-family: 'Inter';
  width: 100%;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  p {
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.colors.deep_koamaru_medium};
  }

  span,
  a {
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.royal_orange_primary};
    margin: 0;
    padding: 0;
    width: 41vw;
    max-width: 196px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-decoration: none;
  }

  ${({ theme }) => theme.device.desktop} {
    gap: 48px;

    div {
      gap: 14px;
    }

    p,
    a,
    span {
      font-size: 20px;
      line-height: 24px;
    }
  }
`

type ItemInfoAddressesProps = {
  ownedBy: string
  ownedByHref?: string
  tokenId: string
}

export const ItemInfoAddresses = ({ ownedBy, ownedByHref, tokenId }: ItemInfoAddressesProps) => {
  const { t } = useTranslation('itempage')

  return (
    <IdsBlock>
      <div>
        <p>{t('owned_by')}:</p>
        {ownedByHref ? (
          <Link target="_blank" href={ownedByHref}>
            {ownedBy}
          </Link>
        ) : (
          <span>{ownedBy}</span>
        )}
      </div>
      <div>
        <p>{t('token_id')}:</p>
        <span>{tokenId}</span>
      </div>
    </IdsBlock>
  )
}
