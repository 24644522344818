import { createGlobalStyle } from 'styled-components/macro'
import { defaultTheme } from './theme'

const PATH_TO_FONTS = '../assets/fonts/'

export enum FontFamilies {
  INTER = `'Inter', sans-serif`,
  MANROPE = `'Manrope', sans-serif`,
}

export const FontFaces = createGlobalStyle`
    @font-face{
        font-family: ${FontFamilies.INTER};
        src: url(${PATH_TO_FONTS + 'Inter/Inter-Bold.ttf'}) format('ttf');
        font-style: normal;
        font-weight: ${defaultTheme.fontWeight.bold};
        font-display: fallback;
    }

    @font-face{
        font-family: ${FontFamilies.INTER};
        src: url(${PATH_TO_FONTS + 'Inter/Inter-Medium.ttf'}) format('ttf');
        font-style: normal;
        font-weight: ${defaultTheme.fontWeight.semibold};
        font-display: fallback;
    }

    @font-face{
        font-family: ${FontFamilies.INTER};
        src: url(${PATH_TO_FONTS + 'Inter/Inter-Regular.ttf'}) format('ttf');
        font-style: normal;
        font-weight: ${defaultTheme.fontWeight.regular};
        font-display: fallback;
    }

    @font-face{
        font-family: ${FontFamilies.INTER};
        src: url(${PATH_TO_FONTS + 'Inter/Inter-Thin.ttf'}) format('ttf');
        font-style: normal;
        font-weight: ${defaultTheme.fontWeight.light};
        font-display: fallback;
    }

    @font-face{
        font-family: ${FontFamilies.MANROPE};
        src: url(${PATH_TO_FONTS + 'Manrope-Bold.ttf'}) format('ttf');
        font-style: normal;
        font-weight: ${defaultTheme.fontWeight.bold};
        font-display: fallback;
    }

    @font-face{
        font-family: ${FontFamilies.MANROPE};
        src: url(${PATH_TO_FONTS + 'Manrope-Regular.ttf'}) format('ttf');
        font-style: normal;
        font-weight: ${defaultTheme.fontWeight.regular};
        font-display: fallback;
    }

    @font-face{
        font-family: ${FontFamilies.MANROPE};
        src: url(${PATH_TO_FONTS + 'Manrope-ExtraLight.ttf'}) format('ttf');
        font-style: normal;
        font-weight: ${defaultTheme.fontWeight.light};
        font-display: fallback;
    }
`
