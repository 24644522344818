import {
  borderRadius,
  colors,
  fontWeight,
  lineHeight,
  shadow,
  typeScale,
  deviceSizes,
  device,
  tools,
  gradients,
  fonts,
} from '.'
import { DefaultTheme } from 'styled-components/macro'

declare module 'styled-components' {
  export interface DefaultTheme {
    typeScale: typeof typeScale
    lineHeight: typeof lineHeight
    fontWeight: typeof fontWeight
    colors: typeof colors
    borderRadius: typeof borderRadius
    shadow: typeof shadow
    device: typeof device
    deviceSizes: typeof deviceSizes
    tools: typeof tools
    fonts: typeof fonts
    gradients: typeof gradients
  }
}

export const defaultTheme: DefaultTheme = {
  borderRadius,
  colors,
  fonts,
  fontWeight,
  lineHeight,
  shadow,
  typeScale,
  device,
  deviceSizes,
  tools,
  gradients,
}
