import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  BigDecimal: any
  BigInt: any
  Bytes: any
  DateTime: any
  ObjectScalar: any
  UUID: any
}

export type AcceptFriendRequestOk = {
  __typename?: 'AcceptFriendRequestOk'
  result: Scalars['String']
}

export type AcceptFriendRequestResult =
  | AcceptFriendRequestOk
  | AuthError
  | ForbiddenError
  | NotFoundError
  | ValidationError

export type Affiliation = {
  __typename?: 'Affiliation'
  amount: Scalars['BigInt']
  id: Scalars['ID']
  item: Item
  owner: User
}

export type Affiliation_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  amount?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  amount_not?: InputMaybe<Scalars['BigInt']>
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  and?: InputMaybe<Array<InputMaybe<Affiliation_Filter>>>
  id?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_lt?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  item?: InputMaybe<Scalars['String']>
  item_?: InputMaybe<Item_Filter>
  item_contains?: InputMaybe<Scalars['String']>
  item_contains_nocase?: InputMaybe<Scalars['String']>
  item_ends_with?: InputMaybe<Scalars['String']>
  item_ends_with_nocase?: InputMaybe<Scalars['String']>
  item_gt?: InputMaybe<Scalars['String']>
  item_gte?: InputMaybe<Scalars['String']>
  item_in?: InputMaybe<Array<Scalars['String']>>
  item_lt?: InputMaybe<Scalars['String']>
  item_lte?: InputMaybe<Scalars['String']>
  item_not?: InputMaybe<Scalars['String']>
  item_not_contains?: InputMaybe<Scalars['String']>
  item_not_contains_nocase?: InputMaybe<Scalars['String']>
  item_not_ends_with?: InputMaybe<Scalars['String']>
  item_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  item_not_in?: InputMaybe<Array<Scalars['String']>>
  item_not_starts_with?: InputMaybe<Scalars['String']>
  item_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  item_starts_with?: InputMaybe<Scalars['String']>
  item_starts_with_nocase?: InputMaybe<Scalars['String']>
  or?: InputMaybe<Array<InputMaybe<Affiliation_Filter>>>
  owner?: InputMaybe<Scalars['String']>
  owner_?: InputMaybe<User_Filter>
  owner_contains?: InputMaybe<Scalars['String']>
  owner_contains_nocase?: InputMaybe<Scalars['String']>
  owner_ends_with?: InputMaybe<Scalars['String']>
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>
  owner_gt?: InputMaybe<Scalars['String']>
  owner_gte?: InputMaybe<Scalars['String']>
  owner_in?: InputMaybe<Array<Scalars['String']>>
  owner_lt?: InputMaybe<Scalars['String']>
  owner_lte?: InputMaybe<Scalars['String']>
  owner_not?: InputMaybe<Scalars['String']>
  owner_not_contains?: InputMaybe<Scalars['String']>
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>
  owner_not_ends_with?: InputMaybe<Scalars['String']>
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  owner_not_in?: InputMaybe<Array<Scalars['String']>>
  owner_not_starts_with?: InputMaybe<Scalars['String']>
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  owner_starts_with?: InputMaybe<Scalars['String']>
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>
}

export enum Affiliation_OrderBy {
  Amount = 'amount',
  Id = 'id',
  Item = 'item',
  ItemChainCreatedTimestamp = 'item__chainCreatedTimestamp',
  ItemGameId = 'item__gameID',
  ItemId = 'item__id',
  ItemLimited = 'item__limited',
  ItemOwnersCount = 'item__ownersCount',
  ItemPrice = 'item__price',
  ItemRarity = 'item__rarity',
  ItemTag = 'item__tag',
  Owner = 'owner',
  OwnerBalance = 'owner__balance',
  OwnerChainCreatedTimestamp = 'owner__chainCreatedTimestamp',
  OwnerId = 'owner__id',
  OwnerItemsCount = 'owner__itemsCount',
}

export type AuthError = Error & {
  __typename?: 'AuthError'
  message: Scalars['String']
}

export type AuthMessage = {
  __typename?: 'AuthMessage'
  address: Scalars['String']
  noonce: Scalars['Int']
}

export type AuthNonceOk = {
  __typename?: 'AuthNonceOk'
  result: Scalars['String']
}

export type AuthNonceResult = AuthError | AuthNonceOk

export type BlockChangedFilter = {
  number_gte: Scalars['Int']
}

export type Block_Height = {
  hash?: InputMaybe<Scalars['Bytes']>
  number?: InputMaybe<Scalars['Int']>
  number_gte?: InputMaybe<Scalars['Int']>
}

export type CancelFriendRequestOk = {
  __typename?: 'CancelFriendRequestOk'
  result: Scalars['ID']
}

export type CancelFriendRequestResult =
  | AuthError
  | CancelFriendRequestOk
  | ForbiddenError
  | NotFoundError
  | ValidationError

export type Chat = {
  __typename?: 'Chat'
  id: Scalars['ID']
  lastMessage?: Maybe<Message>
  users: Array<User>
}

export type ChatsOk = {
  __typename?: 'ChatsOk'
  result: Array<Chat>
}

export type ChatsResult = AuthError | ChatsOk

export type ChestOpenRequest = {
  __typename?: 'ChestOpenRequest'
  collection: Collection
  drop: Array<DropInfo>
  fulfilled: Scalars['Boolean']
  id: Scalars['ID']
  requestID: Scalars['BigInt']
  user: User
}

export type ChestOpenRequestDropArgs = {
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<DropInfo_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<DropInfo_Filter>
}

export type ChestOpenRequest_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<ChestOpenRequest_Filter>>>
  collection?: InputMaybe<Scalars['String']>
  collection_?: InputMaybe<Collection_Filter>
  collection_contains?: InputMaybe<Scalars['String']>
  collection_contains_nocase?: InputMaybe<Scalars['String']>
  collection_ends_with?: InputMaybe<Scalars['String']>
  collection_ends_with_nocase?: InputMaybe<Scalars['String']>
  collection_gt?: InputMaybe<Scalars['String']>
  collection_gte?: InputMaybe<Scalars['String']>
  collection_in?: InputMaybe<Array<Scalars['String']>>
  collection_lt?: InputMaybe<Scalars['String']>
  collection_lte?: InputMaybe<Scalars['String']>
  collection_not?: InputMaybe<Scalars['String']>
  collection_not_contains?: InputMaybe<Scalars['String']>
  collection_not_contains_nocase?: InputMaybe<Scalars['String']>
  collection_not_ends_with?: InputMaybe<Scalars['String']>
  collection_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  collection_not_in?: InputMaybe<Array<Scalars['String']>>
  collection_not_starts_with?: InputMaybe<Scalars['String']>
  collection_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  collection_starts_with?: InputMaybe<Scalars['String']>
  collection_starts_with_nocase?: InputMaybe<Scalars['String']>
  drop_?: InputMaybe<DropInfo_Filter>
  fulfilled?: InputMaybe<Scalars['Boolean']>
  fulfilled_in?: InputMaybe<Array<Scalars['Boolean']>>
  fulfilled_not?: InputMaybe<Scalars['Boolean']>
  fulfilled_not_in?: InputMaybe<Array<Scalars['Boolean']>>
  id?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_lt?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  or?: InputMaybe<Array<InputMaybe<ChestOpenRequest_Filter>>>
  requestID?: InputMaybe<Scalars['BigInt']>
  requestID_gt?: InputMaybe<Scalars['BigInt']>
  requestID_gte?: InputMaybe<Scalars['BigInt']>
  requestID_in?: InputMaybe<Array<Scalars['BigInt']>>
  requestID_lt?: InputMaybe<Scalars['BigInt']>
  requestID_lte?: InputMaybe<Scalars['BigInt']>
  requestID_not?: InputMaybe<Scalars['BigInt']>
  requestID_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  user?: InputMaybe<Scalars['String']>
  user_?: InputMaybe<User_Filter>
  user_contains?: InputMaybe<Scalars['String']>
  user_contains_nocase?: InputMaybe<Scalars['String']>
  user_ends_with?: InputMaybe<Scalars['String']>
  user_ends_with_nocase?: InputMaybe<Scalars['String']>
  user_gt?: InputMaybe<Scalars['String']>
  user_gte?: InputMaybe<Scalars['String']>
  user_in?: InputMaybe<Array<Scalars['String']>>
  user_lt?: InputMaybe<Scalars['String']>
  user_lte?: InputMaybe<Scalars['String']>
  user_not?: InputMaybe<Scalars['String']>
  user_not_contains?: InputMaybe<Scalars['String']>
  user_not_contains_nocase?: InputMaybe<Scalars['String']>
  user_not_ends_with?: InputMaybe<Scalars['String']>
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  user_not_in?: InputMaybe<Array<Scalars['String']>>
  user_not_starts_with?: InputMaybe<Scalars['String']>
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  user_starts_with?: InputMaybe<Scalars['String']>
  user_starts_with_nocase?: InputMaybe<Scalars['String']>
}

export enum ChestOpenRequest_OrderBy {
  Collection = 'collection',
  CollectionChainCreatedTimestamp = 'collection__chainCreatedTimestamp',
  CollectionChestsToGet = 'collection__chestsToGet',
  CollectionChestsTotal = 'collection__chestsTotal',
  CollectionFirstBlockAvailable = 'collection__firstBlockAvailable',
  CollectionId = 'collection__id',
  CollectionKeyPrice = 'collection__keyPrice',
  CollectionLastBlockAvailable = 'collection__lastBlockAvailable',
  Drop = 'drop',
  Fulfilled = 'fulfilled',
  Id = 'id',
  RequestId = 'requestID',
  User = 'user',
  UserBalance = 'user__balance',
  UserChainCreatedTimestamp = 'user__chainCreatedTimestamp',
  UserId = 'user__id',
  UserItemsCount = 'user__itemsCount',
}

export type Collection = {
  __typename?: 'Collection'
  backgroundImage?: Maybe<Scalars['String']>
  chainCreatedTimestamp: Scalars['BigInt']
  chest: Item
  chestsToGet?: Maybe<Scalars['BigInt']>
  chestsTotal?: Maybe<Scalars['BigInt']>
  description?: Maybe<Scalars['String']>
  firstBlockAvailable: Scalars['BigInt']
  id: Scalars['ID']
  image?: Maybe<Scalars['String']>
  items: Array<Item>
  keyPrice?: Maybe<Scalars['BigInt']>
  lastBlockAvailable?: Maybe<Scalars['BigInt']>
  rarities: Array<CollectionRarity>
}

export type CollectionItemsArgs = {
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Item_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<Item_Filter>
}

export type CollectionRaritiesArgs = {
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<CollectionRarity_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CollectionRarity_Filter>
}

export type CollectionCreate = {
  description: Scalars['String']
}

export type CollectionItemDropData = {
  __typename?: 'CollectionItemDropData'
  amountRemaining?: Maybe<Scalars['BigInt']>
  collectionRarity: CollectionRarity
  id: Scalars['ID']
  item: Item
  maximumDropSize: Scalars['BigInt']
  minimalDropSize: Scalars['BigInt']
}

export type CollectionItemDropData_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  amountRemaining?: InputMaybe<Scalars['BigInt']>
  amountRemaining_gt?: InputMaybe<Scalars['BigInt']>
  amountRemaining_gte?: InputMaybe<Scalars['BigInt']>
  amountRemaining_in?: InputMaybe<Array<Scalars['BigInt']>>
  amountRemaining_lt?: InputMaybe<Scalars['BigInt']>
  amountRemaining_lte?: InputMaybe<Scalars['BigInt']>
  amountRemaining_not?: InputMaybe<Scalars['BigInt']>
  amountRemaining_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  and?: InputMaybe<Array<InputMaybe<CollectionItemDropData_Filter>>>
  collectionRarity?: InputMaybe<Scalars['String']>
  collectionRarity_?: InputMaybe<CollectionRarity_Filter>
  collectionRarity_contains?: InputMaybe<Scalars['String']>
  collectionRarity_contains_nocase?: InputMaybe<Scalars['String']>
  collectionRarity_ends_with?: InputMaybe<Scalars['String']>
  collectionRarity_ends_with_nocase?: InputMaybe<Scalars['String']>
  collectionRarity_gt?: InputMaybe<Scalars['String']>
  collectionRarity_gte?: InputMaybe<Scalars['String']>
  collectionRarity_in?: InputMaybe<Array<Scalars['String']>>
  collectionRarity_lt?: InputMaybe<Scalars['String']>
  collectionRarity_lte?: InputMaybe<Scalars['String']>
  collectionRarity_not?: InputMaybe<Scalars['String']>
  collectionRarity_not_contains?: InputMaybe<Scalars['String']>
  collectionRarity_not_contains_nocase?: InputMaybe<Scalars['String']>
  collectionRarity_not_ends_with?: InputMaybe<Scalars['String']>
  collectionRarity_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  collectionRarity_not_in?: InputMaybe<Array<Scalars['String']>>
  collectionRarity_not_starts_with?: InputMaybe<Scalars['String']>
  collectionRarity_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  collectionRarity_starts_with?: InputMaybe<Scalars['String']>
  collectionRarity_starts_with_nocase?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_lt?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  item?: InputMaybe<Scalars['String']>
  item_?: InputMaybe<Item_Filter>
  item_contains?: InputMaybe<Scalars['String']>
  item_contains_nocase?: InputMaybe<Scalars['String']>
  item_ends_with?: InputMaybe<Scalars['String']>
  item_ends_with_nocase?: InputMaybe<Scalars['String']>
  item_gt?: InputMaybe<Scalars['String']>
  item_gte?: InputMaybe<Scalars['String']>
  item_in?: InputMaybe<Array<Scalars['String']>>
  item_lt?: InputMaybe<Scalars['String']>
  item_lte?: InputMaybe<Scalars['String']>
  item_not?: InputMaybe<Scalars['String']>
  item_not_contains?: InputMaybe<Scalars['String']>
  item_not_contains_nocase?: InputMaybe<Scalars['String']>
  item_not_ends_with?: InputMaybe<Scalars['String']>
  item_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  item_not_in?: InputMaybe<Array<Scalars['String']>>
  item_not_starts_with?: InputMaybe<Scalars['String']>
  item_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  item_starts_with?: InputMaybe<Scalars['String']>
  item_starts_with_nocase?: InputMaybe<Scalars['String']>
  maximumDropSize?: InputMaybe<Scalars['BigInt']>
  maximumDropSize_gt?: InputMaybe<Scalars['BigInt']>
  maximumDropSize_gte?: InputMaybe<Scalars['BigInt']>
  maximumDropSize_in?: InputMaybe<Array<Scalars['BigInt']>>
  maximumDropSize_lt?: InputMaybe<Scalars['BigInt']>
  maximumDropSize_lte?: InputMaybe<Scalars['BigInt']>
  maximumDropSize_not?: InputMaybe<Scalars['BigInt']>
  maximumDropSize_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  minimalDropSize?: InputMaybe<Scalars['BigInt']>
  minimalDropSize_gt?: InputMaybe<Scalars['BigInt']>
  minimalDropSize_gte?: InputMaybe<Scalars['BigInt']>
  minimalDropSize_in?: InputMaybe<Array<Scalars['BigInt']>>
  minimalDropSize_lt?: InputMaybe<Scalars['BigInt']>
  minimalDropSize_lte?: InputMaybe<Scalars['BigInt']>
  minimalDropSize_not?: InputMaybe<Scalars['BigInt']>
  minimalDropSize_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  or?: InputMaybe<Array<InputMaybe<CollectionItemDropData_Filter>>>
}

export enum CollectionItemDropData_OrderBy {
  AmountRemaining = 'amountRemaining',
  CollectionRarity = 'collectionRarity',
  CollectionRarityId = 'collectionRarity__id',
  CollectionRarityMatAtATime = 'collectionRarity__matAtATime',
  CollectionRarityProbability = 'collectionRarity__probability',
  CollectionRarityProbabilityDelta = 'collectionRarity__probabilityDelta',
  Id = 'id',
  Item = 'item',
  ItemChainCreatedTimestamp = 'item__chainCreatedTimestamp',
  ItemGameId = 'item__gameID',
  ItemId = 'item__id',
  ItemLimited = 'item__limited',
  ItemOwnersCount = 'item__ownersCount',
  ItemPrice = 'item__price',
  ItemRarity = 'item__rarity',
  ItemTag = 'item__tag',
  MaximumDropSize = 'maximumDropSize',
  MinimalDropSize = 'minimalDropSize',
}

export type CollectionPreset = {
  __typename?: 'CollectionPreset'
  chestItem: Item
  chestsToGet?: Maybe<Scalars['Int']>
  collection: Collection
  firstBlockAvailable: Scalars['Int']
  keyPrice: Scalars['Int']
  lastBlockAvailable?: Maybe<Scalars['Int']>
  rarities: Array<Rarity>
}

export type CollectionPresetCreate = {
  chestItem: ItemCreate
  chestsToGet?: InputMaybe<Scalars['Int']>
  collection: CollectionCreate
  firstBlockAvailable: Scalars['Int']
  keyPrice: Scalars['Int']
  lastBlockAvailable?: InputMaybe<Scalars['Int']>
  rarities: Array<RarityCreate>
}

export type CollectionPresetCreateResult = AuthError | CollectionPreset | PermissionError

export type CollectionPresetDeleteOk = {
  __typename?: 'CollectionPresetDeleteOk'
  result: Scalars['ID']
}

export type CollectionPresetDeleteResult = AuthError | CollectionPresetDeleteOk | NotFoundError | PermissionError

export type CollectionPresetOk = {
  __typename?: 'CollectionPresetOk'
  result?: Maybe<CollectionPreset>
}

export type CollectionPresetResult = AuthError | CollectionPresetOk | PermissionError

export type CollectionPresetSetChestsToGetResult = AuthError | CollectionPreset | NotFoundError | PermissionError

export type CollectionPresetSetFirstBlockAvailableResult =
  | AuthError
  | CollectionPreset
  | NotFoundError
  | PermissionError

export type CollectionPresetSetKeyPriceResult = AuthError | CollectionPreset | NotFoundError | PermissionError

export type CollectionPresetSetLastBlockAvailableResult = AuthError | CollectionPreset | NotFoundError | PermissionError

export type CollectionPresetsOk = {
  __typename?: 'CollectionPresetsOk'
  result: Array<CollectionPreset>
}

export type CollectionPresetsResult = AuthError | CollectionPresetsOk | PermissionError

export type CollectionRarity = {
  __typename?: 'CollectionRarity'
  collection: Collection
  id: Scalars['ID']
  itemsData: Array<CollectionItemDropData>
  matAtATime: Scalars['Int']
  probability: Scalars['BigInt']
  probabilityDelta: Scalars['BigInt']
}

export type CollectionRarityItemsDataArgs = {
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<CollectionItemDropData_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CollectionItemDropData_Filter>
}

export type CollectionRarity_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<CollectionRarity_Filter>>>
  collection?: InputMaybe<Scalars['String']>
  collection_?: InputMaybe<Collection_Filter>
  collection_contains?: InputMaybe<Scalars['String']>
  collection_contains_nocase?: InputMaybe<Scalars['String']>
  collection_ends_with?: InputMaybe<Scalars['String']>
  collection_ends_with_nocase?: InputMaybe<Scalars['String']>
  collection_gt?: InputMaybe<Scalars['String']>
  collection_gte?: InputMaybe<Scalars['String']>
  collection_in?: InputMaybe<Array<Scalars['String']>>
  collection_lt?: InputMaybe<Scalars['String']>
  collection_lte?: InputMaybe<Scalars['String']>
  collection_not?: InputMaybe<Scalars['String']>
  collection_not_contains?: InputMaybe<Scalars['String']>
  collection_not_contains_nocase?: InputMaybe<Scalars['String']>
  collection_not_ends_with?: InputMaybe<Scalars['String']>
  collection_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  collection_not_in?: InputMaybe<Array<Scalars['String']>>
  collection_not_starts_with?: InputMaybe<Scalars['String']>
  collection_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  collection_starts_with?: InputMaybe<Scalars['String']>
  collection_starts_with_nocase?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_lt?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  itemsData_?: InputMaybe<CollectionItemDropData_Filter>
  matAtATime?: InputMaybe<Scalars['Int']>
  matAtATime_gt?: InputMaybe<Scalars['Int']>
  matAtATime_gte?: InputMaybe<Scalars['Int']>
  matAtATime_in?: InputMaybe<Array<Scalars['Int']>>
  matAtATime_lt?: InputMaybe<Scalars['Int']>
  matAtATime_lte?: InputMaybe<Scalars['Int']>
  matAtATime_not?: InputMaybe<Scalars['Int']>
  matAtATime_not_in?: InputMaybe<Array<Scalars['Int']>>
  or?: InputMaybe<Array<InputMaybe<CollectionRarity_Filter>>>
  probability?: InputMaybe<Scalars['BigInt']>
  probabilityDelta?: InputMaybe<Scalars['BigInt']>
  probabilityDelta_gt?: InputMaybe<Scalars['BigInt']>
  probabilityDelta_gte?: InputMaybe<Scalars['BigInt']>
  probabilityDelta_in?: InputMaybe<Array<Scalars['BigInt']>>
  probabilityDelta_lt?: InputMaybe<Scalars['BigInt']>
  probabilityDelta_lte?: InputMaybe<Scalars['BigInt']>
  probabilityDelta_not?: InputMaybe<Scalars['BigInt']>
  probabilityDelta_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  probability_gt?: InputMaybe<Scalars['BigInt']>
  probability_gte?: InputMaybe<Scalars['BigInt']>
  probability_in?: InputMaybe<Array<Scalars['BigInt']>>
  probability_lt?: InputMaybe<Scalars['BigInt']>
  probability_lte?: InputMaybe<Scalars['BigInt']>
  probability_not?: InputMaybe<Scalars['BigInt']>
  probability_not_in?: InputMaybe<Array<Scalars['BigInt']>>
}

export enum CollectionRarity_OrderBy {
  Collection = 'collection',
  CollectionChainCreatedTimestamp = 'collection__chainCreatedTimestamp',
  CollectionChestsToGet = 'collection__chestsToGet',
  CollectionChestsTotal = 'collection__chestsTotal',
  CollectionFirstBlockAvailable = 'collection__firstBlockAvailable',
  CollectionId = 'collection__id',
  CollectionKeyPrice = 'collection__keyPrice',
  CollectionLastBlockAvailable = 'collection__lastBlockAvailable',
  Id = 'id',
  ItemsData = 'itemsData',
  MatAtATime = 'matAtATime',
  Probability = 'probability',
  ProbabilityDelta = 'probabilityDelta',
}

export type CollectionSetDescriptionResult = AuthError | Collection | NotFoundError | PermissionError

export type Collection_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<Collection_Filter>>>
  chainCreatedTimestamp?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_gt?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_gte?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>
  chainCreatedTimestamp_lt?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_lte?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_not?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  chest?: InputMaybe<Scalars['String']>
  chest_?: InputMaybe<Item_Filter>
  chest_contains?: InputMaybe<Scalars['String']>
  chest_contains_nocase?: InputMaybe<Scalars['String']>
  chest_ends_with?: InputMaybe<Scalars['String']>
  chest_ends_with_nocase?: InputMaybe<Scalars['String']>
  chest_gt?: InputMaybe<Scalars['String']>
  chest_gte?: InputMaybe<Scalars['String']>
  chest_in?: InputMaybe<Array<Scalars['String']>>
  chest_lt?: InputMaybe<Scalars['String']>
  chest_lte?: InputMaybe<Scalars['String']>
  chest_not?: InputMaybe<Scalars['String']>
  chest_not_contains?: InputMaybe<Scalars['String']>
  chest_not_contains_nocase?: InputMaybe<Scalars['String']>
  chest_not_ends_with?: InputMaybe<Scalars['String']>
  chest_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  chest_not_in?: InputMaybe<Array<Scalars['String']>>
  chest_not_starts_with?: InputMaybe<Scalars['String']>
  chest_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  chest_starts_with?: InputMaybe<Scalars['String']>
  chest_starts_with_nocase?: InputMaybe<Scalars['String']>
  chestsToGet?: InputMaybe<Scalars['BigInt']>
  chestsToGet_gt?: InputMaybe<Scalars['BigInt']>
  chestsToGet_gte?: InputMaybe<Scalars['BigInt']>
  chestsToGet_in?: InputMaybe<Array<Scalars['BigInt']>>
  chestsToGet_lt?: InputMaybe<Scalars['BigInt']>
  chestsToGet_lte?: InputMaybe<Scalars['BigInt']>
  chestsToGet_not?: InputMaybe<Scalars['BigInt']>
  chestsToGet_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  chestsTotal?: InputMaybe<Scalars['BigInt']>
  chestsTotal_gt?: InputMaybe<Scalars['BigInt']>
  chestsTotal_gte?: InputMaybe<Scalars['BigInt']>
  chestsTotal_in?: InputMaybe<Array<Scalars['BigInt']>>
  chestsTotal_lt?: InputMaybe<Scalars['BigInt']>
  chestsTotal_lte?: InputMaybe<Scalars['BigInt']>
  chestsTotal_not?: InputMaybe<Scalars['BigInt']>
  chestsTotal_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  firstBlockAvailable?: InputMaybe<Scalars['BigInt']>
  firstBlockAvailable_gt?: InputMaybe<Scalars['BigInt']>
  firstBlockAvailable_gte?: InputMaybe<Scalars['BigInt']>
  firstBlockAvailable_in?: InputMaybe<Array<Scalars['BigInt']>>
  firstBlockAvailable_lt?: InputMaybe<Scalars['BigInt']>
  firstBlockAvailable_lte?: InputMaybe<Scalars['BigInt']>
  firstBlockAvailable_not?: InputMaybe<Scalars['BigInt']>
  firstBlockAvailable_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  id?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_lt?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  items_?: InputMaybe<Item_Filter>
  keyPrice?: InputMaybe<Scalars['BigInt']>
  keyPrice_gt?: InputMaybe<Scalars['BigInt']>
  keyPrice_gte?: InputMaybe<Scalars['BigInt']>
  keyPrice_in?: InputMaybe<Array<Scalars['BigInt']>>
  keyPrice_lt?: InputMaybe<Scalars['BigInt']>
  keyPrice_lte?: InputMaybe<Scalars['BigInt']>
  keyPrice_not?: InputMaybe<Scalars['BigInt']>
  keyPrice_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  lastBlockAvailable?: InputMaybe<Scalars['BigInt']>
  lastBlockAvailable_gt?: InputMaybe<Scalars['BigInt']>
  lastBlockAvailable_gte?: InputMaybe<Scalars['BigInt']>
  lastBlockAvailable_in?: InputMaybe<Array<Scalars['BigInt']>>
  lastBlockAvailable_lt?: InputMaybe<Scalars['BigInt']>
  lastBlockAvailable_lte?: InputMaybe<Scalars['BigInt']>
  lastBlockAvailable_not?: InputMaybe<Scalars['BigInt']>
  lastBlockAvailable_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  or?: InputMaybe<Array<InputMaybe<Collection_Filter>>>
  rarities_?: InputMaybe<CollectionRarity_Filter>
}

export enum Collection_OrderBy {
  ChainCreatedTimestamp = 'chainCreatedTimestamp',
  Chest = 'chest',
  ChestChainCreatedTimestamp = 'chest__chainCreatedTimestamp',
  ChestGameId = 'chest__gameID',
  ChestId = 'chest__id',
  ChestLimited = 'chest__limited',
  ChestOwnersCount = 'chest__ownersCount',
  ChestPrice = 'chest__price',
  ChestRarity = 'chest__rarity',
  ChestTag = 'chest__tag',
  ChestsToGet = 'chestsToGet',
  ChestsTotal = 'chestsTotal',
  FirstBlockAvailable = 'firstBlockAvailable',
  Id = 'id',
  Items = 'items',
  KeyPrice = 'keyPrice',
  LastBlockAvailable = 'lastBlockAvailable',
  Rarities = 'rarities',
}

export type ConflictError = Error & {
  __typename?: 'ConflictError'
  message: Scalars['String']
}

export type CreateApiKeyResult = AuthError | PermissionError | Token | ValidationError

export type CreateChatOk = {
  __typename?: 'CreateChatOk'
  result: Scalars['ID']
}

export type CreateChatResult = AuthError | CreateChatOk

export type CurrentUserResult = AuthError | User

export type DeclineFriendRequestOk = {
  __typename?: 'DeclineFriendRequestOk'
  result: Scalars['ID']
}

export type DeclineFriendRequestResult =
  | AuthError
  | DeclineFriendRequestOk
  | ForbiddenError
  | NotFoundError
  | ValidationError

export type DeleteFriendOk = {
  __typename?: 'DeleteFriendOk'
  result: Scalars['ID']
}

export type DeleteFriendResult = AuthError | DeleteFriendOk | ForbiddenError | NotFoundError | ValidationError

export type DeleteMessageOk = {
  __typename?: 'DeleteMessageOk'
  result: Scalars['ID']
}

export type DeleteMessageResult = AuthError | DeleteMessageOk | NotFoundError | PermissionError | ValidationError

export type Domain = {
  __typename?: 'Domain'
  chainId: Scalars['String']
  name: Scalars['String']
  salt?: Maybe<Scalars['String']>
  verifyingContract: Scalars['String']
  version: Scalars['String']
}

export type DropInfo = {
  __typename?: 'DropInfo'
  amount: Scalars['BigInt']
  id: Scalars['ID']
  item: Item
  rarityID: Scalars['BigInt']
  request: ChestOpenRequest
}

export type DropInfo_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  amount?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  amount_not?: InputMaybe<Scalars['BigInt']>
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  and?: InputMaybe<Array<InputMaybe<DropInfo_Filter>>>
  id?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_lt?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  item?: InputMaybe<Scalars['String']>
  item_?: InputMaybe<Item_Filter>
  item_contains?: InputMaybe<Scalars['String']>
  item_contains_nocase?: InputMaybe<Scalars['String']>
  item_ends_with?: InputMaybe<Scalars['String']>
  item_ends_with_nocase?: InputMaybe<Scalars['String']>
  item_gt?: InputMaybe<Scalars['String']>
  item_gte?: InputMaybe<Scalars['String']>
  item_in?: InputMaybe<Array<Scalars['String']>>
  item_lt?: InputMaybe<Scalars['String']>
  item_lte?: InputMaybe<Scalars['String']>
  item_not?: InputMaybe<Scalars['String']>
  item_not_contains?: InputMaybe<Scalars['String']>
  item_not_contains_nocase?: InputMaybe<Scalars['String']>
  item_not_ends_with?: InputMaybe<Scalars['String']>
  item_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  item_not_in?: InputMaybe<Array<Scalars['String']>>
  item_not_starts_with?: InputMaybe<Scalars['String']>
  item_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  item_starts_with?: InputMaybe<Scalars['String']>
  item_starts_with_nocase?: InputMaybe<Scalars['String']>
  or?: InputMaybe<Array<InputMaybe<DropInfo_Filter>>>
  rarityID?: InputMaybe<Scalars['BigInt']>
  rarityID_gt?: InputMaybe<Scalars['BigInt']>
  rarityID_gte?: InputMaybe<Scalars['BigInt']>
  rarityID_in?: InputMaybe<Array<Scalars['BigInt']>>
  rarityID_lt?: InputMaybe<Scalars['BigInt']>
  rarityID_lte?: InputMaybe<Scalars['BigInt']>
  rarityID_not?: InputMaybe<Scalars['BigInt']>
  rarityID_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  request?: InputMaybe<Scalars['String']>
  request_?: InputMaybe<ChestOpenRequest_Filter>
  request_contains?: InputMaybe<Scalars['String']>
  request_contains_nocase?: InputMaybe<Scalars['String']>
  request_ends_with?: InputMaybe<Scalars['String']>
  request_ends_with_nocase?: InputMaybe<Scalars['String']>
  request_gt?: InputMaybe<Scalars['String']>
  request_gte?: InputMaybe<Scalars['String']>
  request_in?: InputMaybe<Array<Scalars['String']>>
  request_lt?: InputMaybe<Scalars['String']>
  request_lte?: InputMaybe<Scalars['String']>
  request_not?: InputMaybe<Scalars['String']>
  request_not_contains?: InputMaybe<Scalars['String']>
  request_not_contains_nocase?: InputMaybe<Scalars['String']>
  request_not_ends_with?: InputMaybe<Scalars['String']>
  request_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  request_not_in?: InputMaybe<Array<Scalars['String']>>
  request_not_starts_with?: InputMaybe<Scalars['String']>
  request_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  request_starts_with?: InputMaybe<Scalars['String']>
  request_starts_with_nocase?: InputMaybe<Scalars['String']>
}

export enum DropInfo_OrderBy {
  Amount = 'amount',
  Id = 'id',
  Item = 'item',
  ItemChainCreatedTimestamp = 'item__chainCreatedTimestamp',
  ItemGameId = 'item__gameID',
  ItemId = 'item__id',
  ItemLimited = 'item__limited',
  ItemOwnersCount = 'item__ownersCount',
  ItemPrice = 'item__price',
  ItemRarity = 'item__rarity',
  ItemTag = 'item__tag',
  RarityId = 'rarityID',
  Request = 'request',
  RequestFulfilled = 'request__fulfilled',
  RequestId = 'request__id',
  RequestRequestId = 'request__requestID',
}

export type Error = {
  message: Scalars['String']
}

export type ForbiddenError = Error & {
  __typename?: 'ForbiddenError'
  message: Scalars['String']
}

export type GetAccessTokenResult = AuthError | Token

export type IncomingFriendRequestsOk = {
  __typename?: 'IncomingFriendRequestsOk'
  result: Array<UserFriendship>
}

export type IncomingFriendRequestsResult = AuthError | IncomingFriendRequestsOk

export type Item = {
  __typename?: 'Item'
  _lotData: Array<Scalars['String']>
  additional?: Maybe<Scalars['ObjectScalar']>
  boundCollection?: Maybe<Collection>
  chainCreatedTimestamp: Scalars['BigInt']
  created?: Maybe<Scalars['DateTime']>
  description?: Maybe<Scalars['String']>
  gameID?: Maybe<Scalars['BigInt']>
  gameId?: Maybe<Scalars['UUID']>
  id: Scalars['ID']
  image?: Maybe<Scalars['String']>
  limited: Scalars['Boolean']
  lots: Array<MarketLot>
  name?: Maybe<Scalars['String']>
  owners: Array<Affiliation>
  ownersCount: Scalars['Int']
  price?: Maybe<Scalars['BigInt']>
  rarity: Scalars['Int']
  tag?: Maybe<Scalars['String']>
  updated?: Maybe<Scalars['DateTime']>
}

export type ItemLotsArgs = {
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<MarketLot_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MarketLot_Filter>
}

export type ItemOwnersArgs = {
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Affiliation_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<Affiliation_Filter>
}

export type ItemCreate = {
  additional: Scalars['ObjectScalar']
  description: Scalars['String']
  gameId: Scalars['UUID']
  name: Scalars['String']
}

export type ItemCreateResult = AuthError | Item | PermissionError

export type ItemDeleteOk = {
  __typename?: 'ItemDeleteOk'
  result: Scalars['ID']
}

export type ItemDeleteResult = AuthError | ItemDeleteOk | NotFoundError | PermissionError

export type ItemDropData = {
  __typename?: 'ItemDropData'
  amountRemaining?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  item: Item
  maximumDropSize: Scalars['Int']
  minimalDropSize: Scalars['Int']
}

export type ItemDropDataCreate = {
  amountRemaining?: InputMaybe<Scalars['Int']>
  item: ItemCreate
  maximumDropSize: Scalars['Int']
  minimalDropSize: Scalars['Int']
}

export type ItemDropDataCreateResult = AuthError | ItemDropData | PermissionError

export type ItemDropDataDeleteOk = {
  __typename?: 'ItemDropDataDeleteOk'
  result: Scalars['ID']
}

export type ItemDropDataDeleteResult = AuthError | ItemDropDataDeleteOk | NotFoundError | PermissionError

export type ItemDropDataSetAmountRemainingResult = AuthError | ItemDropData | NotFoundError | PermissionError

export type ItemDropDataSetMaximumDropSizeResult = AuthError | ItemDropData | NotFoundError | PermissionError

export type ItemDropDataSetMinimalDropSizeResult = AuthError | ItemDropData | NotFoundError | PermissionError

export type ItemSetAdditionalResult = AuthError | Item | NotFoundError | PermissionError

export type ItemSetDescriptionResult = AuthError | Item | NotFoundError | PermissionError

export type ItemSetGameResult = AuthError | Item | NotFoundError | PermissionError

export type ItemSetNameResult = AuthError | Item | NotFoundError | PermissionError

export type ItemTransfer = {
  __typename?: 'ItemTransfer'
  amount: Scalars['BigInt']
  chainCreatedTimestamp: Scalars['BigInt']
  from: User
  id: Scalars['ID']
  item: Item
  to: User
}

export type ItemTransfer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  amount?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  amount_not?: InputMaybe<Scalars['BigInt']>
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  and?: InputMaybe<Array<InputMaybe<ItemTransfer_Filter>>>
  chainCreatedTimestamp?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_gt?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_gte?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>
  chainCreatedTimestamp_lt?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_lte?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_not?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  from?: InputMaybe<Scalars['String']>
  from_?: InputMaybe<User_Filter>
  from_contains?: InputMaybe<Scalars['String']>
  from_contains_nocase?: InputMaybe<Scalars['String']>
  from_ends_with?: InputMaybe<Scalars['String']>
  from_ends_with_nocase?: InputMaybe<Scalars['String']>
  from_gt?: InputMaybe<Scalars['String']>
  from_gte?: InputMaybe<Scalars['String']>
  from_in?: InputMaybe<Array<Scalars['String']>>
  from_lt?: InputMaybe<Scalars['String']>
  from_lte?: InputMaybe<Scalars['String']>
  from_not?: InputMaybe<Scalars['String']>
  from_not_contains?: InputMaybe<Scalars['String']>
  from_not_contains_nocase?: InputMaybe<Scalars['String']>
  from_not_ends_with?: InputMaybe<Scalars['String']>
  from_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  from_not_in?: InputMaybe<Array<Scalars['String']>>
  from_not_starts_with?: InputMaybe<Scalars['String']>
  from_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  from_starts_with?: InputMaybe<Scalars['String']>
  from_starts_with_nocase?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_lt?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  item?: InputMaybe<Scalars['String']>
  item_?: InputMaybe<Item_Filter>
  item_contains?: InputMaybe<Scalars['String']>
  item_contains_nocase?: InputMaybe<Scalars['String']>
  item_ends_with?: InputMaybe<Scalars['String']>
  item_ends_with_nocase?: InputMaybe<Scalars['String']>
  item_gt?: InputMaybe<Scalars['String']>
  item_gte?: InputMaybe<Scalars['String']>
  item_in?: InputMaybe<Array<Scalars['String']>>
  item_lt?: InputMaybe<Scalars['String']>
  item_lte?: InputMaybe<Scalars['String']>
  item_not?: InputMaybe<Scalars['String']>
  item_not_contains?: InputMaybe<Scalars['String']>
  item_not_contains_nocase?: InputMaybe<Scalars['String']>
  item_not_ends_with?: InputMaybe<Scalars['String']>
  item_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  item_not_in?: InputMaybe<Array<Scalars['String']>>
  item_not_starts_with?: InputMaybe<Scalars['String']>
  item_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  item_starts_with?: InputMaybe<Scalars['String']>
  item_starts_with_nocase?: InputMaybe<Scalars['String']>
  or?: InputMaybe<Array<InputMaybe<ItemTransfer_Filter>>>
  to?: InputMaybe<Scalars['String']>
  to_?: InputMaybe<User_Filter>
  to_contains?: InputMaybe<Scalars['String']>
  to_contains_nocase?: InputMaybe<Scalars['String']>
  to_ends_with?: InputMaybe<Scalars['String']>
  to_ends_with_nocase?: InputMaybe<Scalars['String']>
  to_gt?: InputMaybe<Scalars['String']>
  to_gte?: InputMaybe<Scalars['String']>
  to_in?: InputMaybe<Array<Scalars['String']>>
  to_lt?: InputMaybe<Scalars['String']>
  to_lte?: InputMaybe<Scalars['String']>
  to_not?: InputMaybe<Scalars['String']>
  to_not_contains?: InputMaybe<Scalars['String']>
  to_not_contains_nocase?: InputMaybe<Scalars['String']>
  to_not_ends_with?: InputMaybe<Scalars['String']>
  to_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  to_not_in?: InputMaybe<Array<Scalars['String']>>
  to_not_starts_with?: InputMaybe<Scalars['String']>
  to_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  to_starts_with?: InputMaybe<Scalars['String']>
  to_starts_with_nocase?: InputMaybe<Scalars['String']>
}

export enum ItemTransfer_OrderBy {
  Amount = 'amount',
  ChainCreatedTimestamp = 'chainCreatedTimestamp',
  From = 'from',
  FromBalance = 'from__balance',
  FromChainCreatedTimestamp = 'from__chainCreatedTimestamp',
  FromId = 'from__id',
  FromItemsCount = 'from__itemsCount',
  Id = 'id',
  Item = 'item',
  ItemChainCreatedTimestamp = 'item__chainCreatedTimestamp',
  ItemGameId = 'item__gameID',
  ItemId = 'item__id',
  ItemLimited = 'item__limited',
  ItemOwnersCount = 'item__ownersCount',
  ItemPrice = 'item__price',
  ItemRarity = 'item__rarity',
  ItemTag = 'item__tag',
  To = 'to',
  ToBalance = 'to__balance',
  ToChainCreatedTimestamp = 'to__chainCreatedTimestamp',
  ToId = 'to__id',
  ToItemsCount = 'to__itemsCount',
}

export type Item_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  _lotData?: InputMaybe<Array<Scalars['String']>>
  _lotData_contains?: InputMaybe<Array<Scalars['String']>>
  _lotData_contains_nocase?: InputMaybe<Array<Scalars['String']>>
  _lotData_not?: InputMaybe<Array<Scalars['String']>>
  _lotData_not_contains?: InputMaybe<Array<Scalars['String']>>
  _lotData_not_contains_nocase?: InputMaybe<Array<Scalars['String']>>
  and?: InputMaybe<Array<InputMaybe<Item_Filter>>>
  boundCollection?: InputMaybe<Scalars['String']>
  boundCollection_?: InputMaybe<Collection_Filter>
  boundCollection_contains?: InputMaybe<Scalars['String']>
  boundCollection_contains_nocase?: InputMaybe<Scalars['String']>
  boundCollection_ends_with?: InputMaybe<Scalars['String']>
  boundCollection_ends_with_nocase?: InputMaybe<Scalars['String']>
  boundCollection_gt?: InputMaybe<Scalars['String']>
  boundCollection_gte?: InputMaybe<Scalars['String']>
  boundCollection_in?: InputMaybe<Array<Scalars['String']>>
  boundCollection_lt?: InputMaybe<Scalars['String']>
  boundCollection_lte?: InputMaybe<Scalars['String']>
  boundCollection_not?: InputMaybe<Scalars['String']>
  boundCollection_not_contains?: InputMaybe<Scalars['String']>
  boundCollection_not_contains_nocase?: InputMaybe<Scalars['String']>
  boundCollection_not_ends_with?: InputMaybe<Scalars['String']>
  boundCollection_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  boundCollection_not_in?: InputMaybe<Array<Scalars['String']>>
  boundCollection_not_starts_with?: InputMaybe<Scalars['String']>
  boundCollection_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  boundCollection_starts_with?: InputMaybe<Scalars['String']>
  boundCollection_starts_with_nocase?: InputMaybe<Scalars['String']>
  chainCreatedTimestamp?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_gt?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_gte?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>
  chainCreatedTimestamp_lt?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_lte?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_not?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  gameID?: InputMaybe<Scalars['BigInt']>
  gameID_gt?: InputMaybe<Scalars['BigInt']>
  gameID_gte?: InputMaybe<Scalars['BigInt']>
  gameID_in?: InputMaybe<Array<Scalars['BigInt']>>
  gameID_lt?: InputMaybe<Scalars['BigInt']>
  gameID_lte?: InputMaybe<Scalars['BigInt']>
  gameID_not?: InputMaybe<Scalars['BigInt']>
  gameID_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  id?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_lt?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  limited?: InputMaybe<Scalars['Boolean']>
  limited_in?: InputMaybe<Array<Scalars['Boolean']>>
  limited_not?: InputMaybe<Scalars['Boolean']>
  limited_not_in?: InputMaybe<Array<Scalars['Boolean']>>
  lots_?: InputMaybe<MarketLot_Filter>
  or?: InputMaybe<Array<InputMaybe<Item_Filter>>>
  ownersCount?: InputMaybe<Scalars['Int']>
  ownersCount_gt?: InputMaybe<Scalars['Int']>
  ownersCount_gte?: InputMaybe<Scalars['Int']>
  ownersCount_in?: InputMaybe<Array<Scalars['Int']>>
  ownersCount_lt?: InputMaybe<Scalars['Int']>
  ownersCount_lte?: InputMaybe<Scalars['Int']>
  ownersCount_not?: InputMaybe<Scalars['Int']>
  ownersCount_not_in?: InputMaybe<Array<Scalars['Int']>>
  owners_?: InputMaybe<Affiliation_Filter>
  price?: InputMaybe<Scalars['BigInt']>
  price_gt?: InputMaybe<Scalars['BigInt']>
  price_gte?: InputMaybe<Scalars['BigInt']>
  price_in?: InputMaybe<Array<Scalars['BigInt']>>
  price_lt?: InputMaybe<Scalars['BigInt']>
  price_lte?: InputMaybe<Scalars['BigInt']>
  price_not?: InputMaybe<Scalars['BigInt']>
  price_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  rarity?: InputMaybe<Scalars['Int']>
  rarity_gt?: InputMaybe<Scalars['Int']>
  rarity_gte?: InputMaybe<Scalars['Int']>
  rarity_in?: InputMaybe<Array<Scalars['Int']>>
  rarity_lt?: InputMaybe<Scalars['Int']>
  rarity_lte?: InputMaybe<Scalars['Int']>
  rarity_not?: InputMaybe<Scalars['Int']>
  rarity_not_in?: InputMaybe<Array<Scalars['Int']>>
  tag?: InputMaybe<Scalars['String']>
  tag_contains?: InputMaybe<Scalars['String']>
  tag_contains_nocase?: InputMaybe<Scalars['String']>
  tag_ends_with?: InputMaybe<Scalars['String']>
  tag_ends_with_nocase?: InputMaybe<Scalars['String']>
  tag_gt?: InputMaybe<Scalars['String']>
  tag_gte?: InputMaybe<Scalars['String']>
  tag_in?: InputMaybe<Array<Scalars['String']>>
  tag_lt?: InputMaybe<Scalars['String']>
  tag_lte?: InputMaybe<Scalars['String']>
  tag_not?: InputMaybe<Scalars['String']>
  tag_not_contains?: InputMaybe<Scalars['String']>
  tag_not_contains_nocase?: InputMaybe<Scalars['String']>
  tag_not_ends_with?: InputMaybe<Scalars['String']>
  tag_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  tag_not_in?: InputMaybe<Array<Scalars['String']>>
  tag_not_starts_with?: InputMaybe<Scalars['String']>
  tag_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  tag_starts_with?: InputMaybe<Scalars['String']>
  tag_starts_with_nocase?: InputMaybe<Scalars['String']>
}

export enum Item_OrderBy {
  LotData = '_lotData',
  BoundCollection = 'boundCollection',
  BoundCollectionChainCreatedTimestamp = 'boundCollection__chainCreatedTimestamp',
  BoundCollectionChestsToGet = 'boundCollection__chestsToGet',
  BoundCollectionChestsTotal = 'boundCollection__chestsTotal',
  BoundCollectionFirstBlockAvailable = 'boundCollection__firstBlockAvailable',
  BoundCollectionId = 'boundCollection__id',
  BoundCollectionKeyPrice = 'boundCollection__keyPrice',
  BoundCollectionLastBlockAvailable = 'boundCollection__lastBlockAvailable',
  ChainCreatedTimestamp = 'chainCreatedTimestamp',
  GameId = 'gameID',
  Id = 'id',
  Limited = 'limited',
  Lots = 'lots',
  Owners = 'owners',
  OwnersCount = 'ownersCount',
  Price = 'price',
  Rarity = 'rarity',
  Tag = 'tag',
}

export type LoginOk = {
  __typename?: 'LoginOk'
  result: Scalars['String']
}

export type LoginResult = AuthError | LoginOk | ValidationError

export type LogoutOk = {
  __typename?: 'LogoutOk'
  result: Scalars['String']
}

export type LogoutResult = AuthError | LogoutOk | ValidationError

export type MarketHistoryEntry = {
  __typename?: 'MarketHistoryEntry'
  amountRemaining: Scalars['BigInt']
  chainCreatedTimestamp: Scalars['BigInt']
  id: Scalars['ID']
  marketLot: MarketLot
  operator: User
  price: Scalars['BigInt']
  type: MarketHistoryType
}

export type MarketHistoryEntry_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  amountRemaining?: InputMaybe<Scalars['BigInt']>
  amountRemaining_gt?: InputMaybe<Scalars['BigInt']>
  amountRemaining_gte?: InputMaybe<Scalars['BigInt']>
  amountRemaining_in?: InputMaybe<Array<Scalars['BigInt']>>
  amountRemaining_lt?: InputMaybe<Scalars['BigInt']>
  amountRemaining_lte?: InputMaybe<Scalars['BigInt']>
  amountRemaining_not?: InputMaybe<Scalars['BigInt']>
  amountRemaining_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  and?: InputMaybe<Array<InputMaybe<MarketHistoryEntry_Filter>>>
  chainCreatedTimestamp?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_gt?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_gte?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>
  chainCreatedTimestamp_lt?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_lte?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_not?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  id?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_lt?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  marketLot?: InputMaybe<Scalars['String']>
  marketLot_?: InputMaybe<MarketLot_Filter>
  marketLot_contains?: InputMaybe<Scalars['String']>
  marketLot_contains_nocase?: InputMaybe<Scalars['String']>
  marketLot_ends_with?: InputMaybe<Scalars['String']>
  marketLot_ends_with_nocase?: InputMaybe<Scalars['String']>
  marketLot_gt?: InputMaybe<Scalars['String']>
  marketLot_gte?: InputMaybe<Scalars['String']>
  marketLot_in?: InputMaybe<Array<Scalars['String']>>
  marketLot_lt?: InputMaybe<Scalars['String']>
  marketLot_lte?: InputMaybe<Scalars['String']>
  marketLot_not?: InputMaybe<Scalars['String']>
  marketLot_not_contains?: InputMaybe<Scalars['String']>
  marketLot_not_contains_nocase?: InputMaybe<Scalars['String']>
  marketLot_not_ends_with?: InputMaybe<Scalars['String']>
  marketLot_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  marketLot_not_in?: InputMaybe<Array<Scalars['String']>>
  marketLot_not_starts_with?: InputMaybe<Scalars['String']>
  marketLot_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  marketLot_starts_with?: InputMaybe<Scalars['String']>
  marketLot_starts_with_nocase?: InputMaybe<Scalars['String']>
  operator?: InputMaybe<Scalars['String']>
  operator_?: InputMaybe<User_Filter>
  operator_contains?: InputMaybe<Scalars['String']>
  operator_contains_nocase?: InputMaybe<Scalars['String']>
  operator_ends_with?: InputMaybe<Scalars['String']>
  operator_ends_with_nocase?: InputMaybe<Scalars['String']>
  operator_gt?: InputMaybe<Scalars['String']>
  operator_gte?: InputMaybe<Scalars['String']>
  operator_in?: InputMaybe<Array<Scalars['String']>>
  operator_lt?: InputMaybe<Scalars['String']>
  operator_lte?: InputMaybe<Scalars['String']>
  operator_not?: InputMaybe<Scalars['String']>
  operator_not_contains?: InputMaybe<Scalars['String']>
  operator_not_contains_nocase?: InputMaybe<Scalars['String']>
  operator_not_ends_with?: InputMaybe<Scalars['String']>
  operator_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  operator_not_in?: InputMaybe<Array<Scalars['String']>>
  operator_not_starts_with?: InputMaybe<Scalars['String']>
  operator_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  operator_starts_with?: InputMaybe<Scalars['String']>
  operator_starts_with_nocase?: InputMaybe<Scalars['String']>
  or?: InputMaybe<Array<InputMaybe<MarketHistoryEntry_Filter>>>
  price?: InputMaybe<Scalars['BigInt']>
  price_gt?: InputMaybe<Scalars['BigInt']>
  price_gte?: InputMaybe<Scalars['BigInt']>
  price_in?: InputMaybe<Array<Scalars['BigInt']>>
  price_lt?: InputMaybe<Scalars['BigInt']>
  price_lte?: InputMaybe<Scalars['BigInt']>
  price_not?: InputMaybe<Scalars['BigInt']>
  price_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  type?: InputMaybe<MarketHistoryType>
  type_in?: InputMaybe<Array<MarketHistoryType>>
  type_not?: InputMaybe<MarketHistoryType>
  type_not_in?: InputMaybe<Array<MarketHistoryType>>
}

export enum MarketHistoryEntry_OrderBy {
  AmountRemaining = 'amountRemaining',
  ChainCreatedTimestamp = 'chainCreatedTimestamp',
  Id = 'id',
  MarketLot = 'marketLot',
  MarketLotActive = 'marketLot__active',
  MarketLotAmount = 'marketLot__amount',
  MarketLotChainCreatedTimestamp = 'marketLot__chainCreatedTimestamp',
  MarketLotId = 'marketLot__id',
  MarketLotInitialAmount = 'marketLot__initialAmount',
  MarketLotLotId = 'marketLot__lotID',
  MarketLotPrice = 'marketLot__price',
  Operator = 'operator',
  OperatorBalance = 'operator__balance',
  OperatorChainCreatedTimestamp = 'operator__chainCreatedTimestamp',
  OperatorId = 'operator__id',
  OperatorItemsCount = 'operator__itemsCount',
  Price = 'price',
  Type = 'type',
}

export enum MarketHistoryType {
  Bought = 'BOUGHT',
  Canceled = 'CANCELED',
  Created = 'CREATED',
  Repriced = 'REPRICED',
}

export type MarketLot = {
  __typename?: 'MarketLot'
  active: Scalars['Boolean']
  amount: Scalars['BigInt']
  chainCreatedTimestamp: Scalars['BigInt']
  historyData: Array<MarketHistoryEntry>
  id: Scalars['ID']
  initialAmount: Scalars['BigInt']
  item: Item
  lotID: Scalars['BigInt']
  price: Scalars['BigInt']
  seller: User
}

export type MarketLotHistoryDataArgs = {
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<MarketHistoryEntry_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MarketHistoryEntry_Filter>
}

export type MarketLot_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  active?: InputMaybe<Scalars['Boolean']>
  active_in?: InputMaybe<Array<Scalars['Boolean']>>
  active_not?: InputMaybe<Scalars['Boolean']>
  active_not_in?: InputMaybe<Array<Scalars['Boolean']>>
  amount?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  amount_not?: InputMaybe<Scalars['BigInt']>
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  and?: InputMaybe<Array<InputMaybe<MarketLot_Filter>>>
  chainCreatedTimestamp?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_gt?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_gte?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>
  chainCreatedTimestamp_lt?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_lte?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_not?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  historyData_?: InputMaybe<MarketHistoryEntry_Filter>
  id?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_lt?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  initialAmount?: InputMaybe<Scalars['BigInt']>
  initialAmount_gt?: InputMaybe<Scalars['BigInt']>
  initialAmount_gte?: InputMaybe<Scalars['BigInt']>
  initialAmount_in?: InputMaybe<Array<Scalars['BigInt']>>
  initialAmount_lt?: InputMaybe<Scalars['BigInt']>
  initialAmount_lte?: InputMaybe<Scalars['BigInt']>
  initialAmount_not?: InputMaybe<Scalars['BigInt']>
  initialAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  item?: InputMaybe<Scalars['String']>
  item_?: InputMaybe<Item_Filter>
  item_contains?: InputMaybe<Scalars['String']>
  item_contains_nocase?: InputMaybe<Scalars['String']>
  item_ends_with?: InputMaybe<Scalars['String']>
  item_ends_with_nocase?: InputMaybe<Scalars['String']>
  item_gt?: InputMaybe<Scalars['String']>
  item_gte?: InputMaybe<Scalars['String']>
  item_in?: InputMaybe<Array<Scalars['String']>>
  item_lt?: InputMaybe<Scalars['String']>
  item_lte?: InputMaybe<Scalars['String']>
  item_not?: InputMaybe<Scalars['String']>
  item_not_contains?: InputMaybe<Scalars['String']>
  item_not_contains_nocase?: InputMaybe<Scalars['String']>
  item_not_ends_with?: InputMaybe<Scalars['String']>
  item_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  item_not_in?: InputMaybe<Array<Scalars['String']>>
  item_not_starts_with?: InputMaybe<Scalars['String']>
  item_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  item_starts_with?: InputMaybe<Scalars['String']>
  item_starts_with_nocase?: InputMaybe<Scalars['String']>
  lotID?: InputMaybe<Scalars['BigInt']>
  lotID_gt?: InputMaybe<Scalars['BigInt']>
  lotID_gte?: InputMaybe<Scalars['BigInt']>
  lotID_in?: InputMaybe<Array<Scalars['BigInt']>>
  lotID_lt?: InputMaybe<Scalars['BigInt']>
  lotID_lte?: InputMaybe<Scalars['BigInt']>
  lotID_not?: InputMaybe<Scalars['BigInt']>
  lotID_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  or?: InputMaybe<Array<InputMaybe<MarketLot_Filter>>>
  price?: InputMaybe<Scalars['BigInt']>
  price_gt?: InputMaybe<Scalars['BigInt']>
  price_gte?: InputMaybe<Scalars['BigInt']>
  price_in?: InputMaybe<Array<Scalars['BigInt']>>
  price_lt?: InputMaybe<Scalars['BigInt']>
  price_lte?: InputMaybe<Scalars['BigInt']>
  price_not?: InputMaybe<Scalars['BigInt']>
  price_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  seller?: InputMaybe<Scalars['String']>
  seller_?: InputMaybe<User_Filter>
  seller_contains?: InputMaybe<Scalars['String']>
  seller_contains_nocase?: InputMaybe<Scalars['String']>
  seller_ends_with?: InputMaybe<Scalars['String']>
  seller_ends_with_nocase?: InputMaybe<Scalars['String']>
  seller_gt?: InputMaybe<Scalars['String']>
  seller_gte?: InputMaybe<Scalars['String']>
  seller_in?: InputMaybe<Array<Scalars['String']>>
  seller_lt?: InputMaybe<Scalars['String']>
  seller_lte?: InputMaybe<Scalars['String']>
  seller_not?: InputMaybe<Scalars['String']>
  seller_not_contains?: InputMaybe<Scalars['String']>
  seller_not_contains_nocase?: InputMaybe<Scalars['String']>
  seller_not_ends_with?: InputMaybe<Scalars['String']>
  seller_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  seller_not_in?: InputMaybe<Array<Scalars['String']>>
  seller_not_starts_with?: InputMaybe<Scalars['String']>
  seller_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  seller_starts_with?: InputMaybe<Scalars['String']>
  seller_starts_with_nocase?: InputMaybe<Scalars['String']>
}

export enum MarketLot_OrderBy {
  Active = 'active',
  Amount = 'amount',
  ChainCreatedTimestamp = 'chainCreatedTimestamp',
  HistoryData = 'historyData',
  Id = 'id',
  InitialAmount = 'initialAmount',
  Item = 'item',
  ItemChainCreatedTimestamp = 'item__chainCreatedTimestamp',
  ItemGameId = 'item__gameID',
  ItemId = 'item__id',
  ItemLimited = 'item__limited',
  ItemOwnersCount = 'item__ownersCount',
  ItemPrice = 'item__price',
  ItemRarity = 'item__rarity',
  ItemTag = 'item__tag',
  LotId = 'lotID',
  Price = 'price',
  Seller = 'seller',
  SellerBalance = 'seller__balance',
  SellerChainCreatedTimestamp = 'seller__chainCreatedTimestamp',
  SellerId = 'seller__id',
  SellerItemsCount = 'seller__itemsCount',
}

export type Message = {
  __typename?: 'Message'
  author: User
  created: Scalars['DateTime']
  id: Scalars['String']
  message: Scalars['String']
}

export type MessageChanged = {
  __typename?: 'MessageChanged'
  chatId: Scalars['ID']
  id: Scalars['ID']
  message?: Maybe<Message>
  mutationType: MutationType
}

export type MessagesOk = {
  __typename?: 'MessagesOk'
  result: Array<Message>
}

export type MessagesResult = AuthError | MessagesOk | NotFoundError | PermissionError

export type Mutation = {
  __typename?: 'Mutation'
  acceptFriendRequest: AcceptFriendRequestResult
  cancelFriendRequest: CancelFriendRequestResult
  collectionPresetCreate: CollectionPresetCreateResult
  collectionPresetDelete: CollectionPresetDeleteResult
  collectionPresetSetChestsToGet: CollectionPresetSetChestsToGetResult
  collectionPresetSetFirstBlockAvailable: CollectionPresetSetFirstBlockAvailableResult
  collectionPresetSetKeyPrice: CollectionPresetSetKeyPriceResult
  collectionPresetSetLastBlockAvailable: CollectionPresetSetLastBlockAvailableResult
  collectionSetDescription: CollectionSetDescriptionResult
  /** @deprecated This is a part of v1 Auth API, use v2 instead */
  createApiKey: CreateApiKeyResult
  /** Create a chat with a list of users. You will be automatically added to the chat, so you don't have to specify yourself. */
  createChat: CreateChatResult
  declineFriendRequest: DeclineFriendRequestResult
  deleteFriend: DeleteFriendResult
  /** Delete a message you sent. */
  deleteMessage: DeleteMessageResult
  /** @deprecated This is a part of v1 Auth API, use v2 instead */
  getAccessToken: GetAccessTokenResult
  itemCreate: ItemCreateResult
  itemDelete: ItemDeleteResult
  itemDropDataCreate: ItemDropDataCreateResult
  itemDropDataDelete: ItemDropDataDeleteResult
  itemDropDataSetAmountRemaining: ItemDropDataSetAmountRemainingResult
  itemDropDataSetMaximumDropSize: ItemDropDataSetMaximumDropSizeResult
  itemDropDataSetMinimalDropSize: ItemDropDataSetMinimalDropSizeResult
  itemSetAdditional: ItemSetAdditionalResult
  itemSetDescription: ItemSetDescriptionResult
  itemSetGame: ItemSetGameResult
  itemSetName: ItemSetNameResult
  /**
   * Login current session using SIWE signature.
   * This is a part of v2 Auth API.
   * Read more about it in the docs: https://docs.bine.games/graphql/auth-api/v2#login
   *
   */
  login: LoginResult
  /**
   * Logout current session.
   * This is a part of v2 Auth API.
   * Read more about it in the docs: https://docs.bine.games/graphql/auth-api/v2#logout
   *
   */
  logout: LogoutResult
  rarityCreate: RarityCreateResult
  rarityDelete: RarityDeleteResult
  raritySetMaxAtATime: RaritySetMaxAtATimeResult
  raritySetProbability: RaritySetProbabilityResult
  raritySetProbabilityDelta: RaritySetProbabilityDeltaResult
  sendFriendRequest: SendFriendRequestResult
  /** Send a message to a chat you participate in. */
  sendMessage: SendMessageResult
  setNickname: SetNicknameResult
  setSettings: SetSettingsResult
  setUserGameData: SetUserGameDataResult
  transactionCreate: TransactionCreateResult
}

export type MutationAcceptFriendRequestArgs = {
  userId: Scalars['ID']
}

export type MutationCancelFriendRequestArgs = {
  userId: Scalars['ID']
}

export type MutationCollectionPresetCreateArgs = {
  collectionData: CollectionPresetCreate
}

export type MutationCollectionPresetDeleteArgs = {
  collectionId: Scalars['ID']
}

export type MutationCollectionPresetSetChestsToGetArgs = {
  chestsToGet?: InputMaybe<Scalars['Int']>
  collectionId: Scalars['ID']
}

export type MutationCollectionPresetSetFirstBlockAvailableArgs = {
  collectionId: Scalars['ID']
  firstBlockAvailable: Scalars['Int']
}

export type MutationCollectionPresetSetKeyPriceArgs = {
  collectionId: Scalars['ID']
  keyPrice: Scalars['Int']
}

export type MutationCollectionPresetSetLastBlockAvailableArgs = {
  collectionId: Scalars['ID']
  lastBlockAvailable?: InputMaybe<Scalars['Int']>
}

export type MutationCollectionSetDescriptionArgs = {
  collectionId: Scalars['ID']
  description: Scalars['String']
}

export type MutationCreateApiKeyArgs = {
  gameId: Scalars['String']
}

export type MutationCreateChatArgs = {
  users: Array<Scalars['String']>
}

export type MutationDeclineFriendRequestArgs = {
  userId: Scalars['ID']
}

export type MutationDeleteFriendArgs = {
  userId: Scalars['ID']
}

export type MutationDeleteMessageArgs = {
  chatId: Scalars['ID']
  id: Scalars['ID']
}

export type MutationGetAccessTokenArgs = {
  address: Scalars['String']
  noonce: Scalars['Int']
  salt?: InputMaybe<Scalars['String']>
  signature: Scalars['String']
}

export type MutationItemCreateArgs = {
  itemData: ItemCreate
}

export type MutationItemDeleteArgs = {
  id: Scalars['ID']
}

export type MutationItemDropDataCreateArgs = {
  itemDropData: ItemDropDataCreate
  rarityId: Scalars['ID']
}

export type MutationItemDropDataDeleteArgs = {
  itemDropDataId: Scalars['ID']
}

export type MutationItemDropDataSetAmountRemainingArgs = {
  amountRemaining?: InputMaybe<Scalars['Int']>
  itemDropDataId: Scalars['ID']
}

export type MutationItemDropDataSetMaximumDropSizeArgs = {
  itemDropDataId: Scalars['ID']
  maximumDropSize: Scalars['Int']
}

export type MutationItemDropDataSetMinimalDropSizeArgs = {
  itemDropDataId: Scalars['ID']
  minimalDropSize: Scalars['Int']
}

export type MutationItemSetAdditionalArgs = {
  additional: Scalars['ObjectScalar']
  id: Scalars['ID']
}

export type MutationItemSetDescriptionArgs = {
  description: Scalars['String']
  id: Scalars['ID']
}

export type MutationItemSetGameArgs = {
  gameId: Scalars['UUID']
  id: Scalars['ID']
}

export type MutationItemSetNameArgs = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type MutationLoginArgs = {
  message: SiweMessageInput
  signature: Scalars['String']
}

export type MutationRarityCreateArgs = {
  collectionId: Scalars['ID']
  rarityData: RarityCreate
}

export type MutationRarityDeleteArgs = {
  rarityId: Scalars['ID']
}

export type MutationRaritySetMaxAtATimeArgs = {
  maxAtATime: Scalars['Int']
  rarityId: Scalars['ID']
}

export type MutationRaritySetProbabilityArgs = {
  probability: Scalars['Float']
  rarityId: Scalars['ID']
}

export type MutationRaritySetProbabilityDeltaArgs = {
  probabilityDelta: Scalars['Float']
  rarityId: Scalars['ID']
}

export type MutationSendFriendRequestArgs = {
  userId: Scalars['ID']
}

export type MutationSendMessageArgs = {
  chatId: Scalars['ID']
  message: Scalars['String']
}

export type MutationSetNicknameArgs = {
  nickname: Scalars['String']
}

export type MutationSetSettingsArgs = {
  id: Scalars['String']
  settings?: InputMaybe<Scalars['ObjectScalar']>
}

export type MutationSetUserGameDataArgs = {
  data: Scalars['ObjectScalar']
  gameId: Scalars['UUID']
  userId: Scalars['ID']
}

export enum MutationType {
  Created = 'CREATED',
  Deleted = 'DELETED',
}

export type NotFoundError = Error & {
  __typename?: 'NotFoundError'
  message: Scalars['String']
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type PendingFriendRequestsOk = {
  __typename?: 'PendingFriendRequestsOk'
  result: Array<UserFriendship>
}

export type PendingFriendRequestsResult = AuthError | PendingFriendRequestsOk

export type PermissionError = Error & {
  __typename?: 'PermissionError'
  message: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>
  affiliation?: Maybe<Affiliation>
  affiliations: Array<Affiliation>
  /**
   * Get auth nonce for authentication and generate session cookie.
   * This is a part of v2 Auth API.
   * Read more about it in the docs: https://docs.bine.games/graphql/auth-api/v2#login
   *
   */
  authNonce: AuthNonceResult
  /** Get chats you participate in. Chats are not guaranteed to persist forever. */
  chats: ChatsResult
  chestOpenRequest?: Maybe<ChestOpenRequest>
  chestOpenRequests: Array<ChestOpenRequest>
  collection?: Maybe<Collection>
  collectionItemDropData?: Maybe<CollectionItemDropData>
  collectionItemDropDatas: Array<CollectionItemDropData>
  collectionPreset: CollectionPresetResult
  collectionPresets: CollectionPresetsResult
  collectionRarities: Array<CollectionRarity>
  collectionRarity?: Maybe<CollectionRarity>
  collections: Array<Collection>
  currentUser: CurrentUserResult
  dropInfo?: Maybe<DropInfo>
  dropInfos: Array<DropInfo>
  heartbeat: Scalars['String']
  incomingFriendRequests: IncomingFriendRequestsResult
  item?: Maybe<Item>
  itemTransfer?: Maybe<ItemTransfer>
  itemTransfers: Array<ItemTransfer>
  items: Array<Item>
  marketHistoryEntries: Array<MarketHistoryEntry>
  marketHistoryEntry?: Maybe<MarketHistoryEntry>
  marketLot?: Maybe<MarketLot>
  marketLots: Array<MarketLot>
  /** Get messages from a chat you participate in. This include previous messages. */
  messages: MessagesResult
  pendingFriendRequests: PendingFriendRequestsResult
  role?: Maybe<Role>
  roles: Array<Role>
  settings: SettingsResult
  /** @deprecated This is a part of v1 Auth API, use v2 instead */
  signData: SignData
  tokenTransfer?: Maybe<TokenTransfer>
  tokenTransfers: Array<TokenTransfer>
  trade?: Maybe<Trade>
  tradeItemEntries: Array<TradeItemEntry>
  tradeItemEntry?: Maybe<TradeItemEntry>
  trades: Array<Trade>
  transactionCheck: TransactionCheckResult
  user?: Maybe<User>
  userItemTransfer?: Maybe<UserItemTransfer>
  userItemTransfers: Array<UserItemTransfer>
  userTokenTransfer?: Maybe<UserTokenTransfer>
  userTokenTransfers: Array<UserTokenTransfer>
  userTrade?: Maybe<UserTrade>
  userTrades: Array<UserTrade>
  users: Array<User>
}

export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>
}

export type QueryAffiliationArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryAffiliationsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Affiliation_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Affiliation_Filter>
}

export type QueryChestOpenRequestArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryChestOpenRequestsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<ChestOpenRequest_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<ChestOpenRequest_Filter>
}

export type QueryCollectionArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryCollectionItemDropDataArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryCollectionItemDropDatasArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<CollectionItemDropData_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<CollectionItemDropData_Filter>
}

export type QueryCollectionPresetArgs = {
  id: Scalars['ID']
}

export type QueryCollectionPresetsArgs = {
  limit?: Scalars['Int']
  offset?: Scalars['Int']
}

export type QueryCollectionRaritiesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<CollectionRarity_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<CollectionRarity_Filter>
}

export type QueryCollectionRarityArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryCollectionsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Collection_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Collection_Filter>
}

export type QueryDropInfoArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryDropInfosArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<DropInfo_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<DropInfo_Filter>
}

export type QueryItemArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryItemTransferArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryItemTransfersArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<ItemTransfer_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<ItemTransfer_Filter>
}

export type QueryItemsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Item_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Item_Filter>
}

export type QueryMarketHistoryEntriesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<MarketHistoryEntry_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarketHistoryEntry_Filter>
}

export type QueryMarketHistoryEntryArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryMarketLotArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryMarketLotsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<MarketLot_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarketLot_Filter>
}

export type QueryMessagesArgs = {
  chatId: Scalars['ID']
}

export type QueryRoleArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryRolesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Role_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Role_Filter>
}

export type QuerySettingsArgs = {
  id: Scalars['String']
}

export type QuerySignDataArgs = {
  address: Scalars['String']
}

export type QueryTokenTransferArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryTokenTransfersArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<TokenTransfer_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<TokenTransfer_Filter>
}

export type QueryTradeArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryTradeItemEntriesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<TradeItemEntry_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<TradeItemEntry_Filter>
}

export type QueryTradeItemEntryArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryTradesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Trade_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Trade_Filter>
}

export type QueryUserArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryUserItemTransferArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryUserItemTransfersArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<UserItemTransfer_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<UserItemTransfer_Filter>
}

export type QueryUserTokenTransferArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryUserTokenTransfersArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<UserTokenTransfer_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<UserTokenTransfer_Filter>
}

export type QueryUserTradeArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryUserTradesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<UserTrade_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<UserTrade_Filter>
}

export type QueryUsersArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<User_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<User_Filter>
}

export type Rarity = {
  __typename?: 'Rarity'
  dropData: Array<ItemDropData>
  id: Scalars['ID']
  maxAtATime: Scalars['Int']
  probability: Scalars['Float']
  probabilityDelta: Scalars['Float']
}

export type RarityCreate = {
  dropData: Array<ItemDropDataCreate>
  maxAtATime: Scalars['Int']
  probability: Scalars['Float']
  probabilityDelta: Scalars['Float']
}

export type RarityCreateResult = AuthError | PermissionError | Rarity

export type RarityDeleteOk = {
  __typename?: 'RarityDeleteOk'
  result: Scalars['ID']
}

export type RarityDeleteResult = AuthError | NotFoundError | PermissionError | RarityDeleteOk

export type RaritySetMaxAtATimeResult = AuthError | NotFoundError | PermissionError | Rarity

export type RaritySetProbabilityDeltaResult = AuthError | NotFoundError | PermissionError | Rarity

export type RaritySetProbabilityResult = AuthError | NotFoundError | PermissionError | Rarity

export type Role = {
  __typename?: 'Role'
  contract: Scalars['String']
  id: Scalars['ID']
  role: Scalars['String']
  user: User
}

export type Role_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<Role_Filter>>>
  contract?: InputMaybe<Scalars['String']>
  contract_contains?: InputMaybe<Scalars['String']>
  contract_contains_nocase?: InputMaybe<Scalars['String']>
  contract_ends_with?: InputMaybe<Scalars['String']>
  contract_ends_with_nocase?: InputMaybe<Scalars['String']>
  contract_gt?: InputMaybe<Scalars['String']>
  contract_gte?: InputMaybe<Scalars['String']>
  contract_in?: InputMaybe<Array<Scalars['String']>>
  contract_lt?: InputMaybe<Scalars['String']>
  contract_lte?: InputMaybe<Scalars['String']>
  contract_not?: InputMaybe<Scalars['String']>
  contract_not_contains?: InputMaybe<Scalars['String']>
  contract_not_contains_nocase?: InputMaybe<Scalars['String']>
  contract_not_ends_with?: InputMaybe<Scalars['String']>
  contract_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  contract_not_in?: InputMaybe<Array<Scalars['String']>>
  contract_not_starts_with?: InputMaybe<Scalars['String']>
  contract_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  contract_starts_with?: InputMaybe<Scalars['String']>
  contract_starts_with_nocase?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_lt?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  or?: InputMaybe<Array<InputMaybe<Role_Filter>>>
  role?: InputMaybe<Scalars['String']>
  role_contains?: InputMaybe<Scalars['String']>
  role_contains_nocase?: InputMaybe<Scalars['String']>
  role_ends_with?: InputMaybe<Scalars['String']>
  role_ends_with_nocase?: InputMaybe<Scalars['String']>
  role_gt?: InputMaybe<Scalars['String']>
  role_gte?: InputMaybe<Scalars['String']>
  role_in?: InputMaybe<Array<Scalars['String']>>
  role_lt?: InputMaybe<Scalars['String']>
  role_lte?: InputMaybe<Scalars['String']>
  role_not?: InputMaybe<Scalars['String']>
  role_not_contains?: InputMaybe<Scalars['String']>
  role_not_contains_nocase?: InputMaybe<Scalars['String']>
  role_not_ends_with?: InputMaybe<Scalars['String']>
  role_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  role_not_in?: InputMaybe<Array<Scalars['String']>>
  role_not_starts_with?: InputMaybe<Scalars['String']>
  role_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  role_starts_with?: InputMaybe<Scalars['String']>
  role_starts_with_nocase?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  user_?: InputMaybe<User_Filter>
  user_contains?: InputMaybe<Scalars['String']>
  user_contains_nocase?: InputMaybe<Scalars['String']>
  user_ends_with?: InputMaybe<Scalars['String']>
  user_ends_with_nocase?: InputMaybe<Scalars['String']>
  user_gt?: InputMaybe<Scalars['String']>
  user_gte?: InputMaybe<Scalars['String']>
  user_in?: InputMaybe<Array<Scalars['String']>>
  user_lt?: InputMaybe<Scalars['String']>
  user_lte?: InputMaybe<Scalars['String']>
  user_not?: InputMaybe<Scalars['String']>
  user_not_contains?: InputMaybe<Scalars['String']>
  user_not_contains_nocase?: InputMaybe<Scalars['String']>
  user_not_ends_with?: InputMaybe<Scalars['String']>
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  user_not_in?: InputMaybe<Array<Scalars['String']>>
  user_not_starts_with?: InputMaybe<Scalars['String']>
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  user_starts_with?: InputMaybe<Scalars['String']>
  user_starts_with_nocase?: InputMaybe<Scalars['String']>
}

export enum Role_OrderBy {
  Contract = 'contract',
  Id = 'id',
  Role = 'role',
  User = 'user',
  UserBalance = 'user__balance',
  UserChainCreatedTimestamp = 'user__chainCreatedTimestamp',
  UserId = 'user__id',
  UserItemsCount = 'user__itemsCount',
}

export type SendFriendRequestOk = {
  __typename?: 'SendFriendRequestOk'
  result: Scalars['String']
}

export type SendFriendRequestResult = AuthError | ForbiddenError | NotFoundError | SendFriendRequestOk | ValidationError

export type SendMessageOk = {
  __typename?: 'SendMessageOk'
  result: Scalars['ID']
}

export type SendMessageResult = AuthError | NotFoundError | PermissionError | SendMessageOk

export type SetNicknameResult = AuthError | User | ValidationError

export type SetSettingsResult = AuthError | Settings | ValidationError

export type SetUserGameDataOk = {
  __typename?: 'SetUserGameDataOk'
  result: Scalars['ObjectScalar']
}

export type SetUserGameDataResult = AuthError | ForbiddenError | SetUserGameDataOk | ValidationError

export type Settings = {
  __typename?: 'Settings'
  id: Scalars['String']
  settings: Scalars['ObjectScalar']
  updated?: Maybe<Scalars['DateTime']>
  userId: Scalars['String']
}

export type SettingsResult = AuthError | NotFoundError | Settings | ValidationError

export type SignData = {
  __typename?: 'SignData'
  domain: Domain
  message: AuthMessage
  primaryType: Scalars['String']
  types: TypesData
}

export type SiweMessageInput = {
  address: Scalars['String']
  chainId: Scalars['Int']
  domain: Scalars['String']
  expirationTime?: InputMaybe<Scalars['String']>
  issuedAt: Scalars['String']
  nonce: Scalars['String']
  notBefore?: InputMaybe<Scalars['String']>
  requestId?: InputMaybe<Scalars['String']>
  resources?: InputMaybe<Array<Scalars['String']>>
  statement?: InputMaybe<Scalars['String']>
  uri: Scalars['String']
  version: Scalars['String']
}

export type Subscription = {
  __typename?: 'Subscription'
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>
  affiliation?: Maybe<Affiliation>
  affiliations: Array<Affiliation>
  chestOpenRequest?: Maybe<ChestOpenRequest>
  chestOpenRequests: Array<ChestOpenRequest>
  collection?: Maybe<Collection>
  collectionItemDropData?: Maybe<CollectionItemDropData>
  collectionItemDropDatas: Array<CollectionItemDropData>
  collectionRarities: Array<CollectionRarity>
  collectionRarity?: Maybe<CollectionRarity>
  collections: Array<Collection>
  dropInfo?: Maybe<DropInfo>
  dropInfos: Array<DropInfo>
  item?: Maybe<Item>
  itemTransfer?: Maybe<ItemTransfer>
  itemTransfers: Array<ItemTransfer>
  items: Array<Item>
  marketHistoryEntries: Array<MarketHistoryEntry>
  marketHistoryEntry?: Maybe<MarketHistoryEntry>
  marketLot?: Maybe<MarketLot>
  marketLots: Array<MarketLot>
  /** Get live messages from a chat you participate in. This do not include previous messages (you can get them with query). */
  messages: MessageChanged
  role?: Maybe<Role>
  roles: Array<Role>
  tokenTransfer?: Maybe<TokenTransfer>
  tokenTransfers: Array<TokenTransfer>
  trade?: Maybe<Trade>
  tradeItemEntries: Array<TradeItemEntry>
  tradeItemEntry?: Maybe<TradeItemEntry>
  trades: Array<Trade>
  user?: Maybe<User>
  userItemTransfer?: Maybe<UserItemTransfer>
  userItemTransfers: Array<UserItemTransfer>
  userTokenTransfer?: Maybe<UserTokenTransfer>
  userTokenTransfers: Array<UserTokenTransfer>
  userTrade?: Maybe<UserTrade>
  userTrades: Array<UserTrade>
  users: Array<User>
}

export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>
}

export type SubscriptionAffiliationArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionAffiliationsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Affiliation_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Affiliation_Filter>
}

export type SubscriptionChestOpenRequestArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionChestOpenRequestsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<ChestOpenRequest_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<ChestOpenRequest_Filter>
}

export type SubscriptionCollectionArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionCollectionItemDropDataArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionCollectionItemDropDatasArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<CollectionItemDropData_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<CollectionItemDropData_Filter>
}

export type SubscriptionCollectionRaritiesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<CollectionRarity_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<CollectionRarity_Filter>
}

export type SubscriptionCollectionRarityArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionCollectionsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Collection_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Collection_Filter>
}

export type SubscriptionDropInfoArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionDropInfosArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<DropInfo_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<DropInfo_Filter>
}

export type SubscriptionItemArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionItemTransferArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionItemTransfersArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<ItemTransfer_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<ItemTransfer_Filter>
}

export type SubscriptionItemsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Item_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Item_Filter>
}

export type SubscriptionMarketHistoryEntriesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<MarketHistoryEntry_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarketHistoryEntry_Filter>
}

export type SubscriptionMarketHistoryEntryArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionMarketLotArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionMarketLotsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<MarketLot_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarketLot_Filter>
}

export type SubscriptionMessagesArgs = {
  chatId: Scalars['ID']
}

export type SubscriptionRoleArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionRolesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Role_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Role_Filter>
}

export type SubscriptionTokenTransferArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionTokenTransfersArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<TokenTransfer_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<TokenTransfer_Filter>
}

export type SubscriptionTradeArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionTradeItemEntriesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<TradeItemEntry_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<TradeItemEntry_Filter>
}

export type SubscriptionTradeItemEntryArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionTradesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Trade_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Trade_Filter>
}

export type SubscriptionUserArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionUserItemTransferArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionUserItemTransfersArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<UserItemTransfer_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<UserItemTransfer_Filter>
}

export type SubscriptionUserTokenTransferArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionUserTokenTransfersArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<UserTokenTransfer_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<UserTokenTransfer_Filter>
}

export type SubscriptionUserTradeArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars['ID']
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionUserTradesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<UserTrade_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<UserTrade_Filter>
}

export type SubscriptionUsersArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<User_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<User_Filter>
}

export type Token = {
  __typename?: 'Token'
  accessToken: Scalars['String']
  tokenType: Scalars['String']
}

export type TokenTransfer = {
  __typename?: 'TokenTransfer'
  amount: Scalars['BigInt']
  chainCreatedTimestamp: Scalars['BigInt']
  from: User
  id: Scalars['ID']
  to: User
}

export type TokenTransfer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  amount?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  amount_not?: InputMaybe<Scalars['BigInt']>
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  and?: InputMaybe<Array<InputMaybe<TokenTransfer_Filter>>>
  chainCreatedTimestamp?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_gt?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_gte?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>
  chainCreatedTimestamp_lt?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_lte?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_not?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  from?: InputMaybe<Scalars['String']>
  from_?: InputMaybe<User_Filter>
  from_contains?: InputMaybe<Scalars['String']>
  from_contains_nocase?: InputMaybe<Scalars['String']>
  from_ends_with?: InputMaybe<Scalars['String']>
  from_ends_with_nocase?: InputMaybe<Scalars['String']>
  from_gt?: InputMaybe<Scalars['String']>
  from_gte?: InputMaybe<Scalars['String']>
  from_in?: InputMaybe<Array<Scalars['String']>>
  from_lt?: InputMaybe<Scalars['String']>
  from_lte?: InputMaybe<Scalars['String']>
  from_not?: InputMaybe<Scalars['String']>
  from_not_contains?: InputMaybe<Scalars['String']>
  from_not_contains_nocase?: InputMaybe<Scalars['String']>
  from_not_ends_with?: InputMaybe<Scalars['String']>
  from_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  from_not_in?: InputMaybe<Array<Scalars['String']>>
  from_not_starts_with?: InputMaybe<Scalars['String']>
  from_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  from_starts_with?: InputMaybe<Scalars['String']>
  from_starts_with_nocase?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_lt?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  or?: InputMaybe<Array<InputMaybe<TokenTransfer_Filter>>>
  to?: InputMaybe<Scalars['String']>
  to_?: InputMaybe<User_Filter>
  to_contains?: InputMaybe<Scalars['String']>
  to_contains_nocase?: InputMaybe<Scalars['String']>
  to_ends_with?: InputMaybe<Scalars['String']>
  to_ends_with_nocase?: InputMaybe<Scalars['String']>
  to_gt?: InputMaybe<Scalars['String']>
  to_gte?: InputMaybe<Scalars['String']>
  to_in?: InputMaybe<Array<Scalars['String']>>
  to_lt?: InputMaybe<Scalars['String']>
  to_lte?: InputMaybe<Scalars['String']>
  to_not?: InputMaybe<Scalars['String']>
  to_not_contains?: InputMaybe<Scalars['String']>
  to_not_contains_nocase?: InputMaybe<Scalars['String']>
  to_not_ends_with?: InputMaybe<Scalars['String']>
  to_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  to_not_in?: InputMaybe<Array<Scalars['String']>>
  to_not_starts_with?: InputMaybe<Scalars['String']>
  to_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  to_starts_with?: InputMaybe<Scalars['String']>
  to_starts_with_nocase?: InputMaybe<Scalars['String']>
}

export enum TokenTransfer_OrderBy {
  Amount = 'amount',
  ChainCreatedTimestamp = 'chainCreatedTimestamp',
  From = 'from',
  FromBalance = 'from__balance',
  FromChainCreatedTimestamp = 'from__chainCreatedTimestamp',
  FromId = 'from__id',
  FromItemsCount = 'from__itemsCount',
  Id = 'id',
  To = 'to',
  ToBalance = 'to__balance',
  ToChainCreatedTimestamp = 'to__chainCreatedTimestamp',
  ToId = 'to__id',
  ToItemsCount = 'to__itemsCount',
}

export type Trade = {
  __typename?: 'Trade'
  chainCreatedTimestamp: Scalars['BigInt']
  from: User
  fromItems: Array<TradeItemEntry>
  id: Scalars['ID']
  status: Scalars['String']
  to: User
  toItems: Array<TradeItemEntry>
  tradeID: Scalars['BigInt']
}

export type TradeFromItemsArgs = {
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<TradeItemEntry_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TradeItemEntry_Filter>
}

export type TradeToItemsArgs = {
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<TradeItemEntry_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TradeItemEntry_Filter>
}

export type TradeItemEntry = {
  __typename?: 'TradeItemEntry'
  amount: Scalars['BigInt']
  from?: Maybe<Trade>
  id: Scalars['ID']
  item: Item
  to?: Maybe<Trade>
}

export type TradeItemEntry_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  amount?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  amount_not?: InputMaybe<Scalars['BigInt']>
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  and?: InputMaybe<Array<InputMaybe<TradeItemEntry_Filter>>>
  from?: InputMaybe<Scalars['String']>
  from_?: InputMaybe<Trade_Filter>
  from_contains?: InputMaybe<Scalars['String']>
  from_contains_nocase?: InputMaybe<Scalars['String']>
  from_ends_with?: InputMaybe<Scalars['String']>
  from_ends_with_nocase?: InputMaybe<Scalars['String']>
  from_gt?: InputMaybe<Scalars['String']>
  from_gte?: InputMaybe<Scalars['String']>
  from_in?: InputMaybe<Array<Scalars['String']>>
  from_lt?: InputMaybe<Scalars['String']>
  from_lte?: InputMaybe<Scalars['String']>
  from_not?: InputMaybe<Scalars['String']>
  from_not_contains?: InputMaybe<Scalars['String']>
  from_not_contains_nocase?: InputMaybe<Scalars['String']>
  from_not_ends_with?: InputMaybe<Scalars['String']>
  from_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  from_not_in?: InputMaybe<Array<Scalars['String']>>
  from_not_starts_with?: InputMaybe<Scalars['String']>
  from_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  from_starts_with?: InputMaybe<Scalars['String']>
  from_starts_with_nocase?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_lt?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  item?: InputMaybe<Scalars['String']>
  item_?: InputMaybe<Item_Filter>
  item_contains?: InputMaybe<Scalars['String']>
  item_contains_nocase?: InputMaybe<Scalars['String']>
  item_ends_with?: InputMaybe<Scalars['String']>
  item_ends_with_nocase?: InputMaybe<Scalars['String']>
  item_gt?: InputMaybe<Scalars['String']>
  item_gte?: InputMaybe<Scalars['String']>
  item_in?: InputMaybe<Array<Scalars['String']>>
  item_lt?: InputMaybe<Scalars['String']>
  item_lte?: InputMaybe<Scalars['String']>
  item_not?: InputMaybe<Scalars['String']>
  item_not_contains?: InputMaybe<Scalars['String']>
  item_not_contains_nocase?: InputMaybe<Scalars['String']>
  item_not_ends_with?: InputMaybe<Scalars['String']>
  item_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  item_not_in?: InputMaybe<Array<Scalars['String']>>
  item_not_starts_with?: InputMaybe<Scalars['String']>
  item_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  item_starts_with?: InputMaybe<Scalars['String']>
  item_starts_with_nocase?: InputMaybe<Scalars['String']>
  or?: InputMaybe<Array<InputMaybe<TradeItemEntry_Filter>>>
  to?: InputMaybe<Scalars['String']>
  to_?: InputMaybe<Trade_Filter>
  to_contains?: InputMaybe<Scalars['String']>
  to_contains_nocase?: InputMaybe<Scalars['String']>
  to_ends_with?: InputMaybe<Scalars['String']>
  to_ends_with_nocase?: InputMaybe<Scalars['String']>
  to_gt?: InputMaybe<Scalars['String']>
  to_gte?: InputMaybe<Scalars['String']>
  to_in?: InputMaybe<Array<Scalars['String']>>
  to_lt?: InputMaybe<Scalars['String']>
  to_lte?: InputMaybe<Scalars['String']>
  to_not?: InputMaybe<Scalars['String']>
  to_not_contains?: InputMaybe<Scalars['String']>
  to_not_contains_nocase?: InputMaybe<Scalars['String']>
  to_not_ends_with?: InputMaybe<Scalars['String']>
  to_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  to_not_in?: InputMaybe<Array<Scalars['String']>>
  to_not_starts_with?: InputMaybe<Scalars['String']>
  to_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  to_starts_with?: InputMaybe<Scalars['String']>
  to_starts_with_nocase?: InputMaybe<Scalars['String']>
}

export enum TradeItemEntry_OrderBy {
  Amount = 'amount',
  From = 'from',
  FromChainCreatedTimestamp = 'from__chainCreatedTimestamp',
  FromId = 'from__id',
  FromStatus = 'from__status',
  FromTradeId = 'from__tradeID',
  Id = 'id',
  Item = 'item',
  ItemChainCreatedTimestamp = 'item__chainCreatedTimestamp',
  ItemGameId = 'item__gameID',
  ItemId = 'item__id',
  ItemLimited = 'item__limited',
  ItemOwnersCount = 'item__ownersCount',
  ItemPrice = 'item__price',
  ItemRarity = 'item__rarity',
  ItemTag = 'item__tag',
  To = 'to',
  ToChainCreatedTimestamp = 'to__chainCreatedTimestamp',
  ToId = 'to__id',
  ToStatus = 'to__status',
  ToTradeId = 'to__tradeID',
}

export type Trade_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<Trade_Filter>>>
  chainCreatedTimestamp?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_gt?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_gte?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>
  chainCreatedTimestamp_lt?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_lte?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_not?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  from?: InputMaybe<Scalars['String']>
  fromItems_?: InputMaybe<TradeItemEntry_Filter>
  from_?: InputMaybe<User_Filter>
  from_contains?: InputMaybe<Scalars['String']>
  from_contains_nocase?: InputMaybe<Scalars['String']>
  from_ends_with?: InputMaybe<Scalars['String']>
  from_ends_with_nocase?: InputMaybe<Scalars['String']>
  from_gt?: InputMaybe<Scalars['String']>
  from_gte?: InputMaybe<Scalars['String']>
  from_in?: InputMaybe<Array<Scalars['String']>>
  from_lt?: InputMaybe<Scalars['String']>
  from_lte?: InputMaybe<Scalars['String']>
  from_not?: InputMaybe<Scalars['String']>
  from_not_contains?: InputMaybe<Scalars['String']>
  from_not_contains_nocase?: InputMaybe<Scalars['String']>
  from_not_ends_with?: InputMaybe<Scalars['String']>
  from_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  from_not_in?: InputMaybe<Array<Scalars['String']>>
  from_not_starts_with?: InputMaybe<Scalars['String']>
  from_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  from_starts_with?: InputMaybe<Scalars['String']>
  from_starts_with_nocase?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_lt?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  or?: InputMaybe<Array<InputMaybe<Trade_Filter>>>
  status?: InputMaybe<Scalars['String']>
  status_contains?: InputMaybe<Scalars['String']>
  status_contains_nocase?: InputMaybe<Scalars['String']>
  status_ends_with?: InputMaybe<Scalars['String']>
  status_ends_with_nocase?: InputMaybe<Scalars['String']>
  status_gt?: InputMaybe<Scalars['String']>
  status_gte?: InputMaybe<Scalars['String']>
  status_in?: InputMaybe<Array<Scalars['String']>>
  status_lt?: InputMaybe<Scalars['String']>
  status_lte?: InputMaybe<Scalars['String']>
  status_not?: InputMaybe<Scalars['String']>
  status_not_contains?: InputMaybe<Scalars['String']>
  status_not_contains_nocase?: InputMaybe<Scalars['String']>
  status_not_ends_with?: InputMaybe<Scalars['String']>
  status_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  status_not_in?: InputMaybe<Array<Scalars['String']>>
  status_not_starts_with?: InputMaybe<Scalars['String']>
  status_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  status_starts_with?: InputMaybe<Scalars['String']>
  status_starts_with_nocase?: InputMaybe<Scalars['String']>
  to?: InputMaybe<Scalars['String']>
  toItems_?: InputMaybe<TradeItemEntry_Filter>
  to_?: InputMaybe<User_Filter>
  to_contains?: InputMaybe<Scalars['String']>
  to_contains_nocase?: InputMaybe<Scalars['String']>
  to_ends_with?: InputMaybe<Scalars['String']>
  to_ends_with_nocase?: InputMaybe<Scalars['String']>
  to_gt?: InputMaybe<Scalars['String']>
  to_gte?: InputMaybe<Scalars['String']>
  to_in?: InputMaybe<Array<Scalars['String']>>
  to_lt?: InputMaybe<Scalars['String']>
  to_lte?: InputMaybe<Scalars['String']>
  to_not?: InputMaybe<Scalars['String']>
  to_not_contains?: InputMaybe<Scalars['String']>
  to_not_contains_nocase?: InputMaybe<Scalars['String']>
  to_not_ends_with?: InputMaybe<Scalars['String']>
  to_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  to_not_in?: InputMaybe<Array<Scalars['String']>>
  to_not_starts_with?: InputMaybe<Scalars['String']>
  to_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  to_starts_with?: InputMaybe<Scalars['String']>
  to_starts_with_nocase?: InputMaybe<Scalars['String']>
  tradeID?: InputMaybe<Scalars['BigInt']>
  tradeID_gt?: InputMaybe<Scalars['BigInt']>
  tradeID_gte?: InputMaybe<Scalars['BigInt']>
  tradeID_in?: InputMaybe<Array<Scalars['BigInt']>>
  tradeID_lt?: InputMaybe<Scalars['BigInt']>
  tradeID_lte?: InputMaybe<Scalars['BigInt']>
  tradeID_not?: InputMaybe<Scalars['BigInt']>
  tradeID_not_in?: InputMaybe<Array<Scalars['BigInt']>>
}

export enum Trade_OrderBy {
  ChainCreatedTimestamp = 'chainCreatedTimestamp',
  From = 'from',
  FromItems = 'fromItems',
  FromBalance = 'from__balance',
  FromChainCreatedTimestamp = 'from__chainCreatedTimestamp',
  FromId = 'from__id',
  FromItemsCount = 'from__itemsCount',
  Id = 'id',
  Status = 'status',
  To = 'to',
  ToItems = 'toItems',
  ToBalance = 'to__balance',
  ToChainCreatedTimestamp = 'to__chainCreatedTimestamp',
  ToId = 'to__id',
  ToItemsCount = 'to__itemsCount',
  TradeId = 'tradeID',
}

export type TransactionCheckOk = {
  __typename?: 'TransactionCheckOk'
  result: Scalars['Boolean']
}

export type TransactionCheckResult = AuthError | TransactionCheckOk

export type TransactionCreateOk = {
  __typename?: 'TransactionCreateOk'
  result: Scalars['Boolean']
}

export type TransactionCreateResult = AuthError | ConflictError | TransactionCreateOk

export enum TransferType {
  From = 'FROM',
  To = 'TO',
}

export type TypeDescriptor = {
  __typename?: 'TypeDescriptor'
  name: Scalars['String']
  type: Scalars['String']
}

export type TypesData = {
  __typename?: 'TypesData'
  AuthMessage: Array<TypeDescriptor>
  EIP712Domain: Array<TypeDescriptor>
}

export type User = {
  __typename?: 'User'
  balance?: Maybe<Scalars['BigInt']>
  chainCreatedTimestamp?: Maybe<Scalars['BigInt']>
  created?: Maybe<Scalars['DateTime']>
  friends: Array<User>
  gameData?: Maybe<Scalars['ObjectScalar']>
  id: Scalars['ID']
  image?: Maybe<Scalars['String']>
  isFriend?: Maybe<Scalars['Boolean']>
  itemTransfers: Array<UserItemTransfer>
  items: Array<Affiliation>
  itemsCount?: Maybe<Scalars['Int']>
  lots: Array<MarketLot>
  nickname?: Maybe<Scalars['String']>
  roles: Array<Role>
  tokenTransfers: Array<UserTokenTransfer>
  trades: Array<UserTrade>
  updated?: Maybe<Scalars['DateTime']>
}

export type UserGameDataArgs = {
  gameId: Scalars['UUID']
}

export type UserItemTransfersArgs = {
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<UserItemTransfer_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<UserItemTransfer_Filter>
}

export type UserItemsArgs = {
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Affiliation_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<Affiliation_Filter>
}

export type UserLotsArgs = {
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<MarketLot_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MarketLot_Filter>
}

export type UserRolesArgs = {
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Role_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<Role_Filter>
}

export type UserTokenTransfersArgs = {
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<UserTokenTransfer_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<UserTokenTransfer_Filter>
}

export type UserTradesArgs = {
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<UserTrade_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<UserTrade_Filter>
}

export type UserFriendship = {
  __typename?: 'UserFriendship'
  created?: Maybe<Scalars['DateTime']>
  from: User
  to: User
  updated?: Maybe<Scalars['DateTime']>
}

export type UserItemTransfer = {
  __typename?: 'UserItemTransfer'
  id: Scalars['ID']
  transfer: ItemTransfer
  type: TransferType
  user: User
}

export type UserItemTransfer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<UserItemTransfer_Filter>>>
  id?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_lt?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  or?: InputMaybe<Array<InputMaybe<UserItemTransfer_Filter>>>
  transfer?: InputMaybe<Scalars['String']>
  transfer_?: InputMaybe<ItemTransfer_Filter>
  transfer_contains?: InputMaybe<Scalars['String']>
  transfer_contains_nocase?: InputMaybe<Scalars['String']>
  transfer_ends_with?: InputMaybe<Scalars['String']>
  transfer_ends_with_nocase?: InputMaybe<Scalars['String']>
  transfer_gt?: InputMaybe<Scalars['String']>
  transfer_gte?: InputMaybe<Scalars['String']>
  transfer_in?: InputMaybe<Array<Scalars['String']>>
  transfer_lt?: InputMaybe<Scalars['String']>
  transfer_lte?: InputMaybe<Scalars['String']>
  transfer_not?: InputMaybe<Scalars['String']>
  transfer_not_contains?: InputMaybe<Scalars['String']>
  transfer_not_contains_nocase?: InputMaybe<Scalars['String']>
  transfer_not_ends_with?: InputMaybe<Scalars['String']>
  transfer_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  transfer_not_in?: InputMaybe<Array<Scalars['String']>>
  transfer_not_starts_with?: InputMaybe<Scalars['String']>
  transfer_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  transfer_starts_with?: InputMaybe<Scalars['String']>
  transfer_starts_with_nocase?: InputMaybe<Scalars['String']>
  type?: InputMaybe<TransferType>
  type_in?: InputMaybe<Array<TransferType>>
  type_not?: InputMaybe<TransferType>
  type_not_in?: InputMaybe<Array<TransferType>>
  user?: InputMaybe<Scalars['String']>
  user_?: InputMaybe<User_Filter>
  user_contains?: InputMaybe<Scalars['String']>
  user_contains_nocase?: InputMaybe<Scalars['String']>
  user_ends_with?: InputMaybe<Scalars['String']>
  user_ends_with_nocase?: InputMaybe<Scalars['String']>
  user_gt?: InputMaybe<Scalars['String']>
  user_gte?: InputMaybe<Scalars['String']>
  user_in?: InputMaybe<Array<Scalars['String']>>
  user_lt?: InputMaybe<Scalars['String']>
  user_lte?: InputMaybe<Scalars['String']>
  user_not?: InputMaybe<Scalars['String']>
  user_not_contains?: InputMaybe<Scalars['String']>
  user_not_contains_nocase?: InputMaybe<Scalars['String']>
  user_not_ends_with?: InputMaybe<Scalars['String']>
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  user_not_in?: InputMaybe<Array<Scalars['String']>>
  user_not_starts_with?: InputMaybe<Scalars['String']>
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  user_starts_with?: InputMaybe<Scalars['String']>
  user_starts_with_nocase?: InputMaybe<Scalars['String']>
}

export enum UserItemTransfer_OrderBy {
  Id = 'id',
  Transfer = 'transfer',
  TransferAmount = 'transfer__amount',
  TransferChainCreatedTimestamp = 'transfer__chainCreatedTimestamp',
  TransferId = 'transfer__id',
  Type = 'type',
  User = 'user',
  UserBalance = 'user__balance',
  UserChainCreatedTimestamp = 'user__chainCreatedTimestamp',
  UserId = 'user__id',
  UserItemsCount = 'user__itemsCount',
}

export type UserTokenTransfer = {
  __typename?: 'UserTokenTransfer'
  id: Scalars['ID']
  transfer: TokenTransfer
  type: TransferType
  user: User
}

export type UserTokenTransfer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<UserTokenTransfer_Filter>>>
  id?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_lt?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  or?: InputMaybe<Array<InputMaybe<UserTokenTransfer_Filter>>>
  transfer?: InputMaybe<Scalars['String']>
  transfer_?: InputMaybe<TokenTransfer_Filter>
  transfer_contains?: InputMaybe<Scalars['String']>
  transfer_contains_nocase?: InputMaybe<Scalars['String']>
  transfer_ends_with?: InputMaybe<Scalars['String']>
  transfer_ends_with_nocase?: InputMaybe<Scalars['String']>
  transfer_gt?: InputMaybe<Scalars['String']>
  transfer_gte?: InputMaybe<Scalars['String']>
  transfer_in?: InputMaybe<Array<Scalars['String']>>
  transfer_lt?: InputMaybe<Scalars['String']>
  transfer_lte?: InputMaybe<Scalars['String']>
  transfer_not?: InputMaybe<Scalars['String']>
  transfer_not_contains?: InputMaybe<Scalars['String']>
  transfer_not_contains_nocase?: InputMaybe<Scalars['String']>
  transfer_not_ends_with?: InputMaybe<Scalars['String']>
  transfer_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  transfer_not_in?: InputMaybe<Array<Scalars['String']>>
  transfer_not_starts_with?: InputMaybe<Scalars['String']>
  transfer_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  transfer_starts_with?: InputMaybe<Scalars['String']>
  transfer_starts_with_nocase?: InputMaybe<Scalars['String']>
  type?: InputMaybe<TransferType>
  type_in?: InputMaybe<Array<TransferType>>
  type_not?: InputMaybe<TransferType>
  type_not_in?: InputMaybe<Array<TransferType>>
  user?: InputMaybe<Scalars['String']>
  user_?: InputMaybe<User_Filter>
  user_contains?: InputMaybe<Scalars['String']>
  user_contains_nocase?: InputMaybe<Scalars['String']>
  user_ends_with?: InputMaybe<Scalars['String']>
  user_ends_with_nocase?: InputMaybe<Scalars['String']>
  user_gt?: InputMaybe<Scalars['String']>
  user_gte?: InputMaybe<Scalars['String']>
  user_in?: InputMaybe<Array<Scalars['String']>>
  user_lt?: InputMaybe<Scalars['String']>
  user_lte?: InputMaybe<Scalars['String']>
  user_not?: InputMaybe<Scalars['String']>
  user_not_contains?: InputMaybe<Scalars['String']>
  user_not_contains_nocase?: InputMaybe<Scalars['String']>
  user_not_ends_with?: InputMaybe<Scalars['String']>
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  user_not_in?: InputMaybe<Array<Scalars['String']>>
  user_not_starts_with?: InputMaybe<Scalars['String']>
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  user_starts_with?: InputMaybe<Scalars['String']>
  user_starts_with_nocase?: InputMaybe<Scalars['String']>
}

export enum UserTokenTransfer_OrderBy {
  Id = 'id',
  Transfer = 'transfer',
  TransferAmount = 'transfer__amount',
  TransferChainCreatedTimestamp = 'transfer__chainCreatedTimestamp',
  TransferId = 'transfer__id',
  Type = 'type',
  User = 'user',
  UserBalance = 'user__balance',
  UserChainCreatedTimestamp = 'user__chainCreatedTimestamp',
  UserId = 'user__id',
  UserItemsCount = 'user__itemsCount',
}

export type UserTrade = {
  __typename?: 'UserTrade'
  id: Scalars['ID']
  trade: Trade
  type: TransferType
  user: User
}

export type UserTrade_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<UserTrade_Filter>>>
  id?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_lt?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  or?: InputMaybe<Array<InputMaybe<UserTrade_Filter>>>
  trade?: InputMaybe<Scalars['String']>
  trade_?: InputMaybe<Trade_Filter>
  trade_contains?: InputMaybe<Scalars['String']>
  trade_contains_nocase?: InputMaybe<Scalars['String']>
  trade_ends_with?: InputMaybe<Scalars['String']>
  trade_ends_with_nocase?: InputMaybe<Scalars['String']>
  trade_gt?: InputMaybe<Scalars['String']>
  trade_gte?: InputMaybe<Scalars['String']>
  trade_in?: InputMaybe<Array<Scalars['String']>>
  trade_lt?: InputMaybe<Scalars['String']>
  trade_lte?: InputMaybe<Scalars['String']>
  trade_not?: InputMaybe<Scalars['String']>
  trade_not_contains?: InputMaybe<Scalars['String']>
  trade_not_contains_nocase?: InputMaybe<Scalars['String']>
  trade_not_ends_with?: InputMaybe<Scalars['String']>
  trade_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  trade_not_in?: InputMaybe<Array<Scalars['String']>>
  trade_not_starts_with?: InputMaybe<Scalars['String']>
  trade_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  trade_starts_with?: InputMaybe<Scalars['String']>
  trade_starts_with_nocase?: InputMaybe<Scalars['String']>
  type?: InputMaybe<TransferType>
  type_in?: InputMaybe<Array<TransferType>>
  type_not?: InputMaybe<TransferType>
  type_not_in?: InputMaybe<Array<TransferType>>
  user?: InputMaybe<Scalars['String']>
  user_?: InputMaybe<User_Filter>
  user_contains?: InputMaybe<Scalars['String']>
  user_contains_nocase?: InputMaybe<Scalars['String']>
  user_ends_with?: InputMaybe<Scalars['String']>
  user_ends_with_nocase?: InputMaybe<Scalars['String']>
  user_gt?: InputMaybe<Scalars['String']>
  user_gte?: InputMaybe<Scalars['String']>
  user_in?: InputMaybe<Array<Scalars['String']>>
  user_lt?: InputMaybe<Scalars['String']>
  user_lte?: InputMaybe<Scalars['String']>
  user_not?: InputMaybe<Scalars['String']>
  user_not_contains?: InputMaybe<Scalars['String']>
  user_not_contains_nocase?: InputMaybe<Scalars['String']>
  user_not_ends_with?: InputMaybe<Scalars['String']>
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  user_not_in?: InputMaybe<Array<Scalars['String']>>
  user_not_starts_with?: InputMaybe<Scalars['String']>
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  user_starts_with?: InputMaybe<Scalars['String']>
  user_starts_with_nocase?: InputMaybe<Scalars['String']>
}

export enum UserTrade_OrderBy {
  Id = 'id',
  Trade = 'trade',
  TradeChainCreatedTimestamp = 'trade__chainCreatedTimestamp',
  TradeId = 'trade__id',
  TradeStatus = 'trade__status',
  TradeTradeId = 'trade__tradeID',
  Type = 'type',
  User = 'user',
  UserBalance = 'user__balance',
  UserChainCreatedTimestamp = 'user__chainCreatedTimestamp',
  UserId = 'user__id',
  UserItemsCount = 'user__itemsCount',
}

export type User_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<User_Filter>>>
  balance?: InputMaybe<Scalars['BigInt']>
  balance_gt?: InputMaybe<Scalars['BigInt']>
  balance_gte?: InputMaybe<Scalars['BigInt']>
  balance_in?: InputMaybe<Array<Scalars['BigInt']>>
  balance_lt?: InputMaybe<Scalars['BigInt']>
  balance_lte?: InputMaybe<Scalars['BigInt']>
  balance_not?: InputMaybe<Scalars['BigInt']>
  balance_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  chainCreatedTimestamp?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_gt?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_gte?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>
  chainCreatedTimestamp_lt?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_lte?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_not?: InputMaybe<Scalars['BigInt']>
  chainCreatedTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  id?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_lt?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  itemTransfers_?: InputMaybe<UserItemTransfer_Filter>
  itemsCount?: InputMaybe<Scalars['Int']>
  itemsCount_gt?: InputMaybe<Scalars['Int']>
  itemsCount_gte?: InputMaybe<Scalars['Int']>
  itemsCount_in?: InputMaybe<Array<Scalars['Int']>>
  itemsCount_lt?: InputMaybe<Scalars['Int']>
  itemsCount_lte?: InputMaybe<Scalars['Int']>
  itemsCount_not?: InputMaybe<Scalars['Int']>
  itemsCount_not_in?: InputMaybe<Array<Scalars['Int']>>
  items_?: InputMaybe<Affiliation_Filter>
  lots_?: InputMaybe<MarketLot_Filter>
  or?: InputMaybe<Array<InputMaybe<User_Filter>>>
  roles_?: InputMaybe<Role_Filter>
  tokenTransfers_?: InputMaybe<UserTokenTransfer_Filter>
  trades_?: InputMaybe<UserTrade_Filter>
}

export enum User_OrderBy {
  Balance = 'balance',
  ChainCreatedTimestamp = 'chainCreatedTimestamp',
  Id = 'id',
  ItemTransfers = 'itemTransfers',
  Items = 'items',
  ItemsCount = 'itemsCount',
  Lots = 'lots',
  Roles = 'roles',
  TokenTransfers = 'tokenTransfers',
  Trades = 'trades',
}

export type ValidationError = Error & {
  __typename?: 'ValidationError'
  field: Scalars['String']
  message: Scalars['String']
}

export type _Block_ = {
  __typename?: '_Block_'
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>
  /** The block number */
  number: Scalars['Int']
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>
}

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: '_Meta_'
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_
  /** The deployment ID */
  deployment: Scalars['String']
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean']
}

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny',
}

export type GridItemFragment = {
  __typename?: 'Item'
  id: string
  name?: string | null
  image?: string | null
  price?: any | null
  additional?: any | null
  tag?: string | null
  limited: boolean
  boundCollection?: { __typename?: 'Collection'; chest: { __typename?: 'Item'; name?: string | null } } | null
}

export type GetAccessTokenMutationVariables = Exact<{
  address: Scalars['String']
  salt?: InputMaybe<Scalars['String']>
  signature: Scalars['String']
  noonce: Scalars['Int']
}>

export type GetAccessTokenMutation = {
  __typename?: 'Mutation'
  getAccessToken:
    | { __typename?: 'AuthError'; message: string }
    | { __typename?: 'Token'; accessToken: string; tokenType: string }
}

export type LoginMutationVariables = Exact<{
  message: SiweMessageInput
  signature: Scalars['String']
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login:
    | { __typename: 'AuthError'; message: string }
    | { __typename: 'LoginOk'; result: string }
    | { __typename: 'ValidationError'; message: string }
}

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = {
  __typename?: 'Mutation'
  logout:
    | { __typename: 'AuthError'; message: string }
    | { __typename: 'LogoutOk'; result: string }
    | { __typename: 'ValidationError'; message: string }
}

export type GetDataToSignQueryVariables = Exact<{
  address: Scalars['String']
}>

export type GetDataToSignQuery = {
  __typename?: 'Query'
  signData: {
    __typename?: 'SignData'
    primaryType: string
    types: {
      __typename?: 'TypesData'
      AuthMessage: Array<{ __typename?: 'TypeDescriptor'; name: string; type: string }>
    }
    domain: {
      __typename?: 'Domain'
      name: string
      version: string
      chainId: string
      verifyingContract: string
      salt?: string | null
    }
    message: { __typename?: 'AuthMessage'; address: string; noonce: number }
  }
}

export type GetAuthNonceQueryVariables = Exact<{ [key: string]: never }>

export type GetAuthNonceQuery = {
  __typename?: 'Query'
  authNonce: { __typename: 'AuthError'; message: string } | { __typename: 'AuthNonceOk'; result: string }
}

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserQuery = {
  __typename?: 'Query'
  currentUser:
    | { __typename: 'AuthError'; message: string }
    | { __typename: 'User'; id: string; nickname?: string | null; image?: string | null }
}

export type CollectionQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type CollectionQuery = {
  __typename?: 'Query'
  collection?: {
    __typename?: 'Collection'
    id: string
    image?: string | null
    backgroundImage?: string | null
    description?: string | null
    chestsToGet?: any | null
    chestsTotal?: any | null
    lastBlockAvailable?: any | null
    firstBlockAvailable: any
    keyPrice?: any | null
    chainCreatedTimestamp: any
    chest: {
      __typename?: 'Item'
      id: string
      name?: string | null
      image?: string | null
      price?: any | null
      additional?: any | null
    }
    items: Array<{
      __typename?: 'Item'
      id: string
      name?: string | null
      image?: string | null
      price?: any | null
      additional?: any | null
      tag?: string | null
      limited: boolean
      boundCollection?: { __typename?: 'Collection'; chest: { __typename?: 'Item'; name?: string | null } } | null
    }>
  } | null
}

export type ItemQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ItemQuery = {
  __typename?: 'Query'
  item?: {
    __typename?: 'Item'
    id: string
    name?: string | null
    description?: string | null
    image?: string | null
    price?: any | null
    additional?: any | null
    tag?: string | null
    limited: boolean
    ownersCount: number
    owners: Array<{ __typename?: 'Affiliation'; owner: { __typename?: 'User'; id: string; nickname?: string | null } }>
    boundCollection?: {
      __typename?: 'Collection'
      chest: { __typename?: 'Item'; id: string; name?: string | null; price?: any | null; image?: string | null }
      rarities: Array<{
        __typename?: 'CollectionRarity'
        itemsData: Array<{
          __typename?: 'CollectionItemDropData'
          item: {
            __typename?: 'Item'
            id: string
            name?: string | null
            image?: string | null
            price?: any | null
            additional?: any | null
          }
        }>
      }>
    } | null
  } | null
}

export type ItemHistoryQueryVariables = Exact<{
  item: Scalars['String']
  first?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
}>

export type ItemHistoryQuery = {
  __typename?: 'Query'
  itemTransfers: Array<{
    __typename?: 'ItemTransfer'
    id: string
    amount: any
    chainCreatedTimestamp: any
    from: { __typename?: 'User'; id: string; nickname?: string | null }
    to: { __typename?: 'User'; id: string; nickname?: string | null }
  }>
}

export type ItemTransfersQueryVariables = Exact<{
  items?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type ItemTransfersQuery = {
  __typename?: 'Query'
  itemTransfers: Array<{
    __typename?: 'ItemTransfer'
    id: string
    amount: any
    chainCreatedTimestamp: any
    item: {
      __typename?: 'Item'
      id: string
      name?: string | null
      image?: string | null
      additional?: any | null
      boundCollection?: {
        __typename?: 'Collection'
        chest: { __typename?: 'Item'; id: string; name?: string | null; additional?: any | null }
      } | null
    }
    from: { __typename?: 'User'; id: string; nickname?: string | null; image?: string | null }
    to: { __typename?: 'User'; id: string; nickname?: string | null; image?: string | null }
  }>
}

export type ItemsQueryVariables = Exact<{
  first: Scalars['Int']
  skip: Scalars['Int']
  orderBy?: InputMaybe<Item_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<Item_Filter>
}>

export type ItemsQuery = {
  __typename?: 'Query'
  items: Array<{
    __typename?: 'Item'
    id: string
    name?: string | null
    image?: string | null
    price?: any | null
    additional?: any | null
    tag?: string | null
    limited: boolean
    boundCollection?: { __typename?: 'Collection'; chest: { __typename?: 'Item'; name?: string | null } } | null
  }>
}

export type ItemsOwnedQueryVariables = Exact<{
  owner: Scalars['String']
  first: Scalars['Int']
  skip: Scalars['Int']
  orderBy?: InputMaybe<Item_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<Item_Filter>
}>

export type ItemsOwnedQuery = {
  __typename?: 'Query'
  items: Array<{
    __typename?: 'Item'
    id: string
    name?: string | null
    image?: string | null
    price?: any | null
    additional?: any | null
    tag?: string | null
    limited: boolean
    owners: Array<{ __typename?: 'Affiliation'; amount: any }>
    boundCollection?: { __typename?: 'Collection'; chest: { __typename?: 'Item'; name?: string | null } } | null
  }>
}

export type MainPageQueryVariables = Exact<{
  first: Scalars['Int']
  skip: Scalars['Int']
  orderBy?: InputMaybe<Item_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
}>

export type MainPageQuery = {
  __typename?: 'Query'
  collections: Array<{
    __typename?: 'Collection'
    chest: {
      __typename?: 'Item'
      id: string
      name?: string | null
      image?: string | null
      price?: any | null
      additional?: any | null
    }
    rarities: Array<{
      __typename?: 'CollectionRarity'
      itemsData: Array<{
        __typename?: 'CollectionItemDropData'
        item: {
          __typename?: 'Item'
          id: string
          name?: string | null
          image?: string | null
          price?: any | null
          additional?: any | null
        }
      }>
    }>
  }>
  items: Array<{
    __typename?: 'Item'
    id: string
    name?: string | null
    image?: string | null
    price?: any | null
    additional?: any | null
    tag?: string | null
    limited: boolean
    boundCollection?: { __typename?: 'Collection'; chest: { __typename?: 'Item'; name?: string | null } } | null
  }>
}

export type ProfileQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ProfileQuery = {
  __typename?: 'Query'
  user?: {
    __typename?: 'User'
    id: string
    nickname?: string | null
    image?: string | null
    created?: any | null
    isFriend?: boolean | null
    itemsCount?: number | null
    items: Array<{
      __typename?: 'Affiliation'
      amount: any
      item: {
        __typename?: 'Item'
        id: string
        name?: string | null
        image?: string | null
        price?: any | null
        additional?: any | null
        tag?: string | null
        limited: boolean
        boundCollection?: { __typename?: 'Collection'; chest: { __typename?: 'Item'; name?: string | null } } | null
      }
    }>
  } | null
}

export type UserItemHistoryQueryVariables = Exact<{
  userId: Scalars['ID']
  first?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
}>

export type UserItemHistoryQuery = {
  __typename?: 'Query'
  user?: {
    __typename?: 'User'
    itemTransfers: Array<{
      __typename?: 'UserItemTransfer'
      transfer: {
        __typename?: 'ItemTransfer'
        id: string
        amount: any
        chainCreatedTimestamp: any
        item: {
          __typename?: 'Item'
          id: string
          name?: string | null
          image?: string | null
          additional?: any | null
          boundCollection?: {
            __typename?: 'Collection'
            chest: { __typename?: 'Item'; id: string; name?: string | null; additional?: any | null }
          } | null
        }
        from: { __typename?: 'User'; id: string; nickname?: string | null }
        to: { __typename?: 'User'; id: string; nickname?: string | null }
      }
    }>
  } | null
}

export const GridItemFragmentDoc = gql`
  fragment GridItem on Item {
    id
    name
    image
    price
    additional
    tag
    limited
    boundCollection {
      chest {
        name
      }
    }
  }
`
export const GetAccessTokenDocument = gql`
  mutation getAccessToken($address: String!, $salt: String, $signature: String!, $noonce: Int!) {
    getAccessToken(address: $address, salt: $salt, signature: $signature, noonce: $noonce) {
      ... on Token {
        accessToken
        tokenType
      }
      ... on AuthError {
        message
      }
    }
  }
`
export type GetAccessTokenMutationFn = Apollo.MutationFunction<GetAccessTokenMutation, GetAccessTokenMutationVariables>

/**
 * __useGetAccessTokenMutation__
 *
 * To run a mutation, you first call `useGetAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAccessTokenMutation, { data, loading, error }] = useGetAccessTokenMutation({
 *   variables: {
 *      address: // value for 'address'
 *      salt: // value for 'salt'
 *      signature: // value for 'signature'
 *      noonce: // value for 'noonce'
 *   },
 * });
 */
export function useGetAccessTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<GetAccessTokenMutation, GetAccessTokenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GetAccessTokenMutation, GetAccessTokenMutationVariables>(GetAccessTokenDocument, options)
}
export type GetAccessTokenMutationHookResult = ReturnType<typeof useGetAccessTokenMutation>
export type GetAccessTokenMutationResult = Apollo.MutationResult<GetAccessTokenMutation>
export type GetAccessTokenMutationOptions = Apollo.BaseMutationOptions<
  GetAccessTokenMutation,
  GetAccessTokenMutationVariables
>
export const LoginDocument = gql`
  mutation Login($message: SiweMessageInput!, $signature: String!) {
    login(message: $message, signature: $signature) {
      __typename
      ... on LoginOk {
        result
      }
      ... on Error {
        message
      }
    }
  }
`
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      message: // value for 'message'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options)
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>
export const LogoutDocument = gql`
  mutation Logout {
    logout {
      __typename
      ... on LogoutOk {
        result
      }
      ... on Error {
        message
      }
    }
  }
`
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options)
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>
export const GetDataToSignDocument = gql`
  query getDataToSign($address: String!) {
    signData(address: $address) {
      primaryType
      types {
        AuthMessage {
          name
          type
        }
      }
      domain {
        name
        version
        chainId
        verifyingContract
        salt
      }
      message {
        address
        noonce
      }
    }
  }
`

/**
 * __useGetDataToSignQuery__
 *
 * To run a query within a React component, call `useGetDataToSignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataToSignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataToSignQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useGetDataToSignQuery(
  baseOptions: Apollo.QueryHookOptions<GetDataToSignQuery, GetDataToSignQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDataToSignQuery, GetDataToSignQueryVariables>(GetDataToSignDocument, options)
}
export function useGetDataToSignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDataToSignQuery, GetDataToSignQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDataToSignQuery, GetDataToSignQueryVariables>(GetDataToSignDocument, options)
}
export type GetDataToSignQueryHookResult = ReturnType<typeof useGetDataToSignQuery>
export type GetDataToSignLazyQueryHookResult = ReturnType<typeof useGetDataToSignLazyQuery>
export type GetDataToSignQueryResult = Apollo.QueryResult<GetDataToSignQuery, GetDataToSignQueryVariables>
export const GetAuthNonceDocument = gql`
  query GetAuthNonce {
    authNonce {
      __typename
      ... on AuthNonceOk {
        result
      }
      ... on Error {
        message
      }
    }
  }
`

/**
 * __useGetAuthNonceQuery__
 *
 * To run a query within a React component, call `useGetAuthNonceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthNonceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthNonceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthNonceQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAuthNonceQuery, GetAuthNonceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAuthNonceQuery, GetAuthNonceQueryVariables>(GetAuthNonceDocument, options)
}
export function useGetAuthNonceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAuthNonceQuery, GetAuthNonceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAuthNonceQuery, GetAuthNonceQueryVariables>(GetAuthNonceDocument, options)
}
export type GetAuthNonceQueryHookResult = ReturnType<typeof useGetAuthNonceQuery>
export type GetAuthNonceLazyQueryHookResult = ReturnType<typeof useGetAuthNonceLazyQuery>
export type GetAuthNonceQueryResult = Apollo.QueryResult<GetAuthNonceQuery, GetAuthNonceQueryVariables>
export const CurrentUserDocument = gql`
  query CurrentUser {
    currentUser {
      __typename
      ... on User {
        id
        nickname
        image
      }
      ... on Error {
        message
      }
    }
  }
`

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options)
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options)
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>
export const CollectionDocument = gql`
  query Collection($id: ID!) {
    collection(id: $id) {
      id
      image
      backgroundImage
      description
      chest {
        id
        name
        image
        price
        additional
      }
      chestsToGet
      chestsTotal
      lastBlockAvailable
      firstBlockAvailable
      keyPrice
      items {
        ...GridItem
      }
      chainCreatedTimestamp
    }
  }
  ${GridItemFragmentDoc}
`

/**
 * __useCollectionQuery__
 *
 * To run a query within a React component, call `useCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCollectionQuery(baseOptions: Apollo.QueryHookOptions<CollectionQuery, CollectionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CollectionQuery, CollectionQueryVariables>(CollectionDocument, options)
}
export function useCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CollectionQuery, CollectionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CollectionQuery, CollectionQueryVariables>(CollectionDocument, options)
}
export type CollectionQueryHookResult = ReturnType<typeof useCollectionQuery>
export type CollectionLazyQueryHookResult = ReturnType<typeof useCollectionLazyQuery>
export type CollectionQueryResult = Apollo.QueryResult<CollectionQuery, CollectionQueryVariables>
export const ItemDocument = gql`
  query Item($id: ID!) {
    item(id: $id) {
      id
      name
      description
      image
      price
      additional
      tag
      limited
      ownersCount
      owners(first: 1) {
        owner {
          id
          nickname
        }
      }
      boundCollection {
        chest {
          id
          name
          price
          image
        }
        rarities {
          itemsData {
            item {
              id
              name
              image
              price
              additional
            }
          }
        }
      }
    }
  }
`

/**
 * __useItemQuery__
 *
 * To run a query within a React component, call `useItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useItemQuery(baseOptions: Apollo.QueryHookOptions<ItemQuery, ItemQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ItemQuery, ItemQueryVariables>(ItemDocument, options)
}
export function useItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemQuery, ItemQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ItemQuery, ItemQueryVariables>(ItemDocument, options)
}
export type ItemQueryHookResult = ReturnType<typeof useItemQuery>
export type ItemLazyQueryHookResult = ReturnType<typeof useItemLazyQuery>
export type ItemQueryResult = Apollo.QueryResult<ItemQuery, ItemQueryVariables>
export const ItemHistoryDocument = gql`
  query ItemHistory($item: String!, $first: Int, $skip: Int) {
    itemTransfers(first: $first, skip: $skip, where: { item: $item }) {
      id
      from {
        id
        nickname
      }
      to {
        id
        nickname
      }
      amount
      chainCreatedTimestamp
    }
  }
`

/**
 * __useItemHistoryQuery__
 *
 * To run a query within a React component, call `useItemHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemHistoryQuery({
 *   variables: {
 *      item: // value for 'item'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useItemHistoryQuery(baseOptions: Apollo.QueryHookOptions<ItemHistoryQuery, ItemHistoryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ItemHistoryQuery, ItemHistoryQueryVariables>(ItemHistoryDocument, options)
}
export function useItemHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ItemHistoryQuery, ItemHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ItemHistoryQuery, ItemHistoryQueryVariables>(ItemHistoryDocument, options)
}
export type ItemHistoryQueryHookResult = ReturnType<typeof useItemHistoryQuery>
export type ItemHistoryLazyQueryHookResult = ReturnType<typeof useItemHistoryLazyQuery>
export type ItemHistoryQueryResult = Apollo.QueryResult<ItemHistoryQuery, ItemHistoryQueryVariables>
export const ItemTransfersDocument = gql`
  query ItemTransfers($items: [String!]) {
    itemTransfers(where: { item_in: $items }) {
      id
      item {
        id
        name
        image
        additional
        boundCollection {
          chest {
            id
            name
            additional
          }
        }
      }
      amount
      from {
        id
        nickname
        image
      }
      to {
        id
        nickname
        image
      }
      chainCreatedTimestamp
    }
  }
`

/**
 * __useItemTransfersQuery__
 *
 * To run a query within a React component, call `useItemTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemTransfersQuery({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useItemTransfersQuery(
  baseOptions?: Apollo.QueryHookOptions<ItemTransfersQuery, ItemTransfersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ItemTransfersQuery, ItemTransfersQueryVariables>(ItemTransfersDocument, options)
}
export function useItemTransfersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ItemTransfersQuery, ItemTransfersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ItemTransfersQuery, ItemTransfersQueryVariables>(ItemTransfersDocument, options)
}
export type ItemTransfersQueryHookResult = ReturnType<typeof useItemTransfersQuery>
export type ItemTransfersLazyQueryHookResult = ReturnType<typeof useItemTransfersLazyQuery>
export type ItemTransfersQueryResult = Apollo.QueryResult<ItemTransfersQuery, ItemTransfersQueryVariables>
export const ItemsDocument = gql`
  query Items($first: Int!, $skip: Int!, $orderBy: Item_orderBy, $orderDirection: OrderDirection, $where: Item_filter) {
    items(first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection, where: $where) {
      ...GridItem
    }
  }
  ${GridItemFragmentDoc}
`

/**
 * __useItemsQuery__
 *
 * To run a query within a React component, call `useItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useItemsQuery(baseOptions: Apollo.QueryHookOptions<ItemsQuery, ItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ItemsQuery, ItemsQueryVariables>(ItemsDocument, options)
}
export function useItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemsQuery, ItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ItemsQuery, ItemsQueryVariables>(ItemsDocument, options)
}
export type ItemsQueryHookResult = ReturnType<typeof useItemsQuery>
export type ItemsLazyQueryHookResult = ReturnType<typeof useItemsLazyQuery>
export type ItemsQueryResult = Apollo.QueryResult<ItemsQuery, ItemsQueryVariables>
export const ItemsOwnedDocument = gql`
  query ItemsOwned(
    $owner: String!
    $first: Int!
    $skip: Int!
    $orderBy: Item_orderBy
    $orderDirection: OrderDirection
    $where: Item_filter
  ) {
    items(first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection, where: $where) {
      ...GridItem
      owners(first: 1, where: { owner: $owner }) {
        amount
      }
    }
  }
  ${GridItemFragmentDoc}
`

/**
 * __useItemsOwnedQuery__
 *
 * To run a query within a React component, call `useItemsOwnedQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsOwnedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsOwnedQuery({
 *   variables: {
 *      owner: // value for 'owner'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useItemsOwnedQuery(baseOptions: Apollo.QueryHookOptions<ItemsOwnedQuery, ItemsOwnedQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ItemsOwnedQuery, ItemsOwnedQueryVariables>(ItemsOwnedDocument, options)
}
export function useItemsOwnedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ItemsOwnedQuery, ItemsOwnedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ItemsOwnedQuery, ItemsOwnedQueryVariables>(ItemsOwnedDocument, options)
}
export type ItemsOwnedQueryHookResult = ReturnType<typeof useItemsOwnedQuery>
export type ItemsOwnedLazyQueryHookResult = ReturnType<typeof useItemsOwnedLazyQuery>
export type ItemsOwnedQueryResult = Apollo.QueryResult<ItemsOwnedQuery, ItemsOwnedQueryVariables>
export const MainPageDocument = gql`
  query MainPage($first: Int!, $skip: Int!, $orderBy: Item_orderBy, $orderDirection: OrderDirection) {
    collections {
      chest {
        id
        name
        image
        price
        additional
      }
      rarities {
        itemsData(first: 1) {
          item {
            id
            name
            image
            price
            additional
          }
        }
      }
    }
    items(first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection) {
      ...GridItem
    }
  }
  ${GridItemFragmentDoc}
`

/**
 * __useMainPageQuery__
 *
 * To run a query within a React component, call `useMainPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainPageQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useMainPageQuery(baseOptions: Apollo.QueryHookOptions<MainPageQuery, MainPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MainPageQuery, MainPageQueryVariables>(MainPageDocument, options)
}
export function useMainPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MainPageQuery, MainPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MainPageQuery, MainPageQueryVariables>(MainPageDocument, options)
}
export type MainPageQueryHookResult = ReturnType<typeof useMainPageQuery>
export type MainPageLazyQueryHookResult = ReturnType<typeof useMainPageLazyQuery>
export type MainPageQueryResult = Apollo.QueryResult<MainPageQuery, MainPageQueryVariables>
export const ProfileDocument = gql`
  query Profile($id: ID!) {
    user(id: $id) {
      id
      nickname
      image
      created
      isFriend
      itemsCount
      items(first: 60) {
        amount
        item {
          ...GridItem
        }
      }
    }
  }
  ${GridItemFragmentDoc}
`

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfileQuery(baseOptions: Apollo.QueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options)
}
export function useProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options)
}
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>
export type ProfileQueryResult = Apollo.QueryResult<ProfileQuery, ProfileQueryVariables>
export const UserItemHistoryDocument = gql`
  query UserItemHistory($userId: ID!, $first: Int, $skip: Int) {
    user(id: $userId) {
      itemTransfers(first: $first, skip: $skip) {
        transfer {
          id
          item {
            id
            name
            image
            additional
            boundCollection {
              chest {
                id
                name
                additional
              }
            }
          }
          from {
            id
            nickname
          }
          to {
            id
            nickname
          }
          amount
          chainCreatedTimestamp
        }
      }
    }
  }
`

/**
 * __useUserItemHistoryQuery__
 *
 * To run a query within a React component, call `useUserItemHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserItemHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserItemHistoryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useUserItemHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<UserItemHistoryQuery, UserItemHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserItemHistoryQuery, UserItemHistoryQueryVariables>(UserItemHistoryDocument, options)
}
export function useUserItemHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserItemHistoryQuery, UserItemHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserItemHistoryQuery, UserItemHistoryQueryVariables>(UserItemHistoryDocument, options)
}
export type UserItemHistoryQueryHookResult = ReturnType<typeof useUserItemHistoryQuery>
export type UserItemHistoryLazyQueryHookResult = ReturnType<typeof useUserItemHistoryLazyQuery>
export type UserItemHistoryQueryResult = Apollo.QueryResult<UserItemHistoryQuery, UserItemHistoryQueryVariables>
