import styled from 'styled-components/macro'
import { StyledGameCard } from '../../molecules/cards/GameCard'
import { Icon } from '../../atoms'
import { FlexBox } from '../../atoms'
import { shortenText } from '../../theme/tools'

type DropdownItem = {
  title: string
  href: string
  bgSrc: string
}

type GameDropdownProps = {
  items: DropdownItem[]
  open: boolean
  onClose: () => void
}

const StyledDropdownContainer = styled.div<{ open: boolean }>`
  width: calc(100% - 96px);
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  position: absolute;
  top: 97px;
  left: 0;
  right: 0;
  z-index: 100;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 30px 48px 20px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(22px);
  /* Note: backdrop-filter has minimal browser support */
  gap: 24px;
  border-radius: 0px 0px 60px 60px;
`

const GameCard = styled(StyledGameCard)`
  width: 100%;
  &:hover:not([disabled]) {
    border: 2px solid ${({ theme }) => theme.colors.royal_orange_primary};
  }
`

const DropdownItem = styled.a`
  width: 22.8%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
`

const GameTitle = styled.p`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 14px;
  color: ${({ theme }) => theme.colors.deep_koamaru_darkest};
`

const Background = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'relative' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
`

export const GameDropdown = ({ items, open, onClose }: GameDropdownProps) => {
  return (
    <>
      <Background open={open} onClick={onClose} />
      <StyledDropdownContainer open={open}>
        {items.map((item, idx) => (
          <DropdownItem key={item.title + idx} href={`${item.href}`}>
            <FlexBox align="baseline" gap={13}>
              <GameTitle>{shortenText(item.title, 26)}</GameTitle>
              <Icon
                defaultProps={{ fill: 'deep_koamaru_darkest', size: 13 }}
                icon="rightArrowPick"
              />
            </FlexBox>
            <GameCard bgSrc={`${item.bgSrc}`} />
          </DropdownItem>
        ))}
      </StyledDropdownContainer>
    </>
  )
}
