import Image from 'next/image'
import styled from 'styled-components/macro'
import { Icon, IconButton } from '../../atoms'

import BineLogo from '../../assets/icons/logo.svg'
import { IconProp } from '../../theme/types'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.device.desktop} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const StyledText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.neutral_white};
  margin-top: 30px;
  margin-bottom: 0;

  ${({ theme }) => theme.device.desktop} {
    display: none;
  }
`

const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
  flex-wrap: wrap;
  gap: 14px;

  ${({ theme }) => theme.device.desktop} {
    margin-top: 0;
  }
`

type Icons = {
  [key: string]: {
    url: string
    icon: IconProp
  }
}

const icons: Icons = {
  telegram: {
    url: 'https://t.me/binefinance',
    icon: 'telegram',
  },
  twitter: {
    url: 'https://twitter.com/binefinance',
    icon: 'twitter',
  },
  instagram: {
    url: 'https://www.instagram.com/binefinance/',
    icon: 'instagram',
  },
  discord: {
    url: 'https://discord.gg/8Y4Y4Y4',
    icon: 'discord',
  },
  medium: {
    url: 'https://medium.com/@binefinance',
    icon: 'medium',
  },
}

export const FooterCommunity = () => {
  return (
    <StyledContainer>
      <Icon icon="logo" />
      <StyledText>Join Bine Community</StyledText>
      <IconRow>
        {Object.entries(icons).map(([key, value]) => (
          <IconButton
            key={key}
            outline
            onClick={() => window.open(value.url, '_blank')}
            icon={{
              icon: value.icon,
              defaultProps: {
                size: 24,
                fill: 'deep_koamaru_medium',
              },
            }}
          />
        ))}
      </IconRow>
    </StyledContainer>
  )
}
