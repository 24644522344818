import React from 'react'
import styled from 'styled-components/macro'

const UserDataContainer = styled.div`
  width: 100%;
  margin: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.deep_koamaru_darkest};
  border-radius: 10px;
  padding: 14px 0;

  ${({ theme }) => theme.device.desktop} {
    border-radius: 12px;
    padding: 18px 0;
    width: fit-content;
  }
`

const DataBlock = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Manrope', sans-serif;
  justify-content: center;
  align-items: center;
  margin: 0 28px;

  ${({ theme }) => theme.device.desktop} {
    margin: 0 30px;
  }

  p:first-of-type {
    color: ${({ theme }) => theme.colors.deep_koamaru_medium};
    font-size: 14px;
    margin: 0;
    margin-bottom: 5px;

    ${({ theme }) => theme.device.desktop} {
      font-size: 18px;
    }
  }

  p:last-of-type {
    color: ${({ theme }) => theme.colors.neutral_white};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    font-size: 20px;
    margin: 0;

    ${({ theme }) => theme.device.desktop} {
      font-size: 36px;
    }
  }
`

const VerticalLine = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.deep_koamaru_dark};
  height: 28px;

  ${({ theme }) => theme.device.desktop} {
    height: 74px;
  }
`

type Data = {
  label: string
  value: string
}

type Props = {
  data: Data[]
}

export const UserData = ({ data }: Props) => {
  const renderBlock = (userData: Data, idx: number) => {
    return (
      <React.Fragment key={idx}>
        <DataBlock>
          <p>{userData.label}</p>
          <p>{userData.value}</p>
        </DataBlock>
        {idx !== data.length - 1 ? <VerticalLine /> : null}
      </React.Fragment>
    )
  }

  return (
    <UserDataContainer>
      {data.map((userData, idx) => renderBlock(userData, idx))}
    </UserDataContainer>
  )
}
