import { ApolloClient, InMemoryCache, NormalizedCacheObject, createHttpLink } from '@apollo/client'
import { environment } from '../environments/environment'

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

const createApolloClient = () => {
  const link = createHttpLink({
    uri: environment.gqlUrl,
    credentials: 'include',
  })

  return new ApolloClient({
    link,
    cache: new InMemoryCache({
      addTypename: true,
      // // TODO: Fix this
      // typePolicies: {
      //   Query: {
      //     fields: {
      //       items: {
      //         read(existing, { args: { skip = 0, first = existing?.length } }) {
      //           // A read function should always return undefined if existing is
      //           // undefined. Returning undefined signals that the field is
      //           // missing from the cache, which instructs Apollo Client to
      //           // fetch its value from your GraphQL server.
      //           // if (!existing) return undefined
      //           // if (existing.length < first + skip) return undefined

      //           // return existing && existing.slice(skip, skip + first)
      //           return undefined
      //         },

      //         keyArgs: args => {
      //           const { orderBy, orderDirection, where = {} } = args
      //           return JSON.stringify({ orderBy, orderDirection, where })
      //         },
      //         merge(existing, incoming, { args: { skip = 0 } }) {
      //           // This runs when the Apollo Client cache is merging existing
      //           // data with incoming data. It's up to the developer to teach the
      //           // cache how to merge the two. In this case, we want to preserve
      //           // existing items and concat the incoming items after them.
      //           const merged = existing ? existing.slice(0) : []
      //           for (let i = skip; i < skip + incoming.length; ++i) {
      //             merged[i] = incoming[i - skip]
      //           }
      //           return merged
      //         },
      //       },
      //     },
      //   },
      // },
    }),
  })
}

export function initializeApollo(initialState: NormalizedCacheObject | null = null) {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    _apolloClient.cache.restore({ ...existingCache, ...initialState })
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}
