import styled from 'styled-components/macro'
import { StyledGameCard } from '../../molecules/cards/GameCard'
import { Icon, PrimaryButton } from '../../atoms'
import { FlexBox } from '../../atoms'
import { shortenText } from '../../theme/tools'

const Container = styled(FlexBox)<{ open: boolean }>`
  position: fixed;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  top: max(50%, 280px);
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 284px;
  z-index: 1197;
`

const StyledH1 = styled.h1`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: 36px;
  line-height: 49px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
  margin-top: 12px;
  margin-bottom: 12px;

  color: ${({ theme }) => theme.colors.deep_koamaru_lightest};
`

const StyledH2 = styled.h2`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;

  color: ${({ theme }) => theme.colors.deep_koamaru_lightest};
`

const StyledH3 = styled.h2`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
  margin-bottom: 30px;

  color: ${({ theme }) => theme.colors.deep_koamaru_lightest};
`

const Background = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'relative' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1196;
  background-color: ${({ theme }) => theme.colors.deep_koamaru_primary};
  margin-top: 65px;

  ${({ theme }) => theme.device.desktop} {
    margin-top: 98px;
  }
`

type NoLightModeScreenProps = {
  open: boolean
  onClose?: () => void
}

export const NoLightModeScreen = ({
  open,
  onClose,
}: NoLightModeScreenProps) => {
  return (
    <>
      <Background open={open} />
      <Container open={open}>
        <Icon
          icon={{ name: 'cloudedSun', size: 80 }}
          defaultProps={{ fill: 'deep_koamaru_primary' }}
        />
        <StyledH1>Oops!</StyledH1>
        <StyledH2>There is no light mode yet</StyledH2>
        <StyledH3>We are working on that</StyledH3>
        <PrimaryButton title="Back to the dark side" onClick={onClose} />
      </Container>
    </>
  )
}
