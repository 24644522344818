import styled from 'styled-components'
import { Icon } from '../../atoms'
import { defaultTheme } from '../../theme'

type Props = {
  tag: 'NFT' | 'FT'
  limited: boolean
  className?: string
}

const StyledIcon = styled(Icon)<{ fill: string }>`
  & path {
    fill: ${({ fill }) => fill};
  }
`

const StyledBadge = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  gap: 8px;
  border-radius: 21px;
  color: ${({ color }) => color};
  border: 1.6px solid ${({ color }) => color};
`

const mapPropsToData = ({ tag, limited }: Props) => {
  if (tag === 'NFT') {
    return {
      text: 'NFT',
      color: defaultTheme.colors.special_out_of_office_bright,
    }
  } else {
    if (limited) {
      return {
        text: 'LTD FT',
        color: defaultTheme.colors.special_do_not_disturb_bright,
      }
    } else {
      return {
        text: 'UNLTD FT',
        color: defaultTheme.colors.special_online_bright,
      }
    }
  }
}

export const ItemTag = ({ className, tag, limited }: Props) => {
  const { text, color } = mapPropsToData({ tag, limited })

  return (
    <StyledBadge className={className} color={color}>
      <StyledIcon icon={{ name: 'ethereum' }} fill={color} />
      {text}
    </StyledBadge>
  )
}
