import styled from 'styled-components/macro'
import { PrimaryButton } from '../../atoms'
import { useTranslation } from 'next-i18next'

const PriceBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  p {
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.deep_koamaru_medium};
    margin: 0;
  }

  span {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 36px;
    line-height: 49px;
    color: ${({ theme }) => theme.colors.royal_orange_primary};
  }

  sub {
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.deep_koamaru_medium};
    vertical-align: unset;
  }
`

type ItemPriceProps = {
  price?: number | string
  priceUsd?: number | string
}

export const ItemPrice: React.FC<ItemPriceProps> = ({ price, priceUsd }) => {
  const { t } = useTranslation(['common', 'itempage'])

  if (!price || !priceUsd)
    return (
      <PriceBlock>
        <p>{t('price')}:</p>
        <span>{t('not_on_sale')}</span>
      </PriceBlock>
    )

  return (
    <>
      <PriceBlock>
        <p>{t('price')}:</p>
        <span>
          {price === t('loading') ? price : price + ' BN'}{' '}
          <sub>${priceUsd === t('loading') ? priceUsd : priceUsd + ` ${t('in_usd')}`}</sub>
        </span>
      </PriceBlock>
      <PrimaryButton title={t('itempage:buy_now')} />
    </>
  )
}
