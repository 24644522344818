import { forwardRef } from 'react'
import Lottie from 'lottie-react'
import * as Animations from './assets'
import type { BasicAnimationProps, AnimationName } from '../../theme/types'

export const capitalizeFirstLetter = <T extends string = string>(
  string: string,
): T => {
  return (string.charAt(0).toUpperCase() + string.slice(1)) as T
}

const Animation = forwardRef<HTMLDivElement, BasicAnimationProps>(
  ({ name, autoPlay = true, loop = true, ...props }, ref) => {
    const animation =
      Animations[capitalizeFirstLetter<Capitalize<AnimationName>>(name)]

    return (
      <Lottie
        ref={ref}
        autoPlay={autoPlay}
        loop={loop}
        animationData={animation}
        {...props}
      />
    )
  },
)

export default Animation
