import styled from 'styled-components/macro'
import Image from 'next/image'

import React from 'react'
import { ItemCard, ItemCardData, UserData } from '../../molecules'
import { CollectionInfoHead, CollectionProgress } from '../../organisms'
import { HorizontalLine, PrimaryButton } from '../../atoms'
import { useDisplayType } from '../../hooks'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { useTranslation } from 'next-i18next'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 416px;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 33px;
  padding-top: 72px;

  ${({ theme }) => theme.device.desktop} {
    margin-bottom: 130px;
  }
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 416px;
  align-items: center;
  z-index: 0;
`

const StyledBackgroundImage = styled.div<{ bgSrc: string }>`
  background: linear-gradient(180deg, rgba(45, 45, 107, 0) 0%, ${({ theme }) => theme.colors.deep_koamaru_primary} 100%),
    url(${({ bgSrc }) => bgSrc});
  width: 100%;
  height: 440px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
  pointer-events: none;

  ${({ theme }) => theme.device.desktop} {
    height: 733px;
  }
`

const JazziconContainer = styled.div`
  position: relative;
  margin-top: 162px;
  width: 120px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  border: 6px solid ${({ theme }) => theme.colors.deep_koamaru_primary};
  border-radius: 50%;
  overflow: hidden;

  ${({ theme }) => theme.device.desktop} {
    width: 130px;
    height: 130px;
    margin-top: 121px;
  }
`

const StyledImage = styled(Image)<{ bgColor?: string }>`
  position: relative;
  margin-top: 162px;
  width: 120px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  border: 6px solid ${({ theme }) => theme.colors.deep_koamaru_primary};
  background: ${({ bgColor, theme }) =>
    `linear-gradient(70deg, ${bgColor ?? theme.colors.deep_koamaru_medium}40, ${
      bgColor ?? theme.colors.deep_koamaru_medium
    }20), ${theme.colors.deep_koamaru_primary}`};
  border-radius: 50%;

  ${({ theme }) => theme.device.desktop} {
    width: 130px;
    height: 130px;
    margin-top: 121px;
  }
`

const StyledItemTitle = styled.h1`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 24px;
  line-height: 33px;
  margin: 0;
  z-index: 1;
  margin-top: 8px;

  ${({ theme }) => theme.device.desktop} {
    font-size: 36px;
    line-height: 49px;
    margin-top: 10px;
  }
`

const StyledItemGameTitle = styled.h2`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  z-index: 1;
  margin-top: 6px;
  margin-bottom: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  text-align: center;

  color: ${({ theme }) => theme.colors.royal_orange_primary};

  ${({ theme }) => theme.device.desktop} {
    font-size: 20px;
    line-height: 27px;
    margin-top: 8px;
    margin-bottom: 22px;
  }
`

const StyledDescription = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: 12px;
  line-height: 15px;
  gap: 6px;

  margin: 0 auto;
  margin-top: 18px;
  margin-bottom: 20px;

  width: auto;

  display: flex;
  align-items: center;

  color: white;

  span {
    color: ${({ theme }) => theme.colors.royal_orange_primary};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  ${({ theme }) => theme.device.desktop} {
    font-size: 18px;
    line-height: 22px;
    margin-top: 26px;
    margin-bottom: 33px;
  }
`

export type UserInfoData = {
  id: string
  nickname?: string
  image: string
  created?: Date
  isFriend?: boolean
  friendsCount: number
  itemsCount: number
  totalSpent: number
}

type ProfileInfoProps = {
  user: UserInfoData
  onAddToFriends?: () => void
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ user }) => {
  const { t } = useTranslation(['common', 'profilepage'])

  return (
    <StyledContainer>
      <StyledBackgroundImage bgSrc="https://via.placeholder.com/150/#6e55ab" />
      <InnerContainer>
        {user.image ? (
          <StyledImage
            src={user.image ?? 'https://via.placeholder.com/150/#6e55ab'}
            width={120}
            height={120}
            alt={'Avatar'}
            bgColor={undefined}
          />
        ) : (
          <JazziconContainer>
            <Jazzicon diameter={130} seed={jsNumberForAddress(user.id)} />
          </JazziconContainer>
        )}
        <StyledItemTitle>{user.nickname}</StyledItemTitle>
        <StyledItemGameTitle>{user.id}</StyledItemGameTitle>
        <UserData
          data={[
            { label: t('profilepage:friends'), value: user.friendsCount.toString() },
            { label: t('profilepage:items'), value: user.itemsCount.toString() },
            { label: t('profilepage:in_game'), value: `${user.totalSpent}h` },
          ]}
        />
        <StyledDescription>
          {t('profilepage:registered_since')} <span>{user.created?.toLocaleString()}</span>
        </StyledDescription>
        <PrimaryButton
          title={t('profilepage:add_to_friends')}
          icon={{ icon: { name: 'telegram' } }}
          iconPosition="right"
          style={{ width: 'auto', height: '52px', padding: '0 53px' }}
          // TODO: change when there will be a handler
          onClick={() => null}
        />
      </InnerContainer>
    </StyledContainer>
  )
}
