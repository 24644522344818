import styled from 'styled-components/macro'

const ProgressContainer = styled.div`
  width: 100%;
  height: 6px;
  position: relative;
  overflow: hidden;

  ${({ theme }) => theme.device.desktop} {
    height: 16px;
  }

  progress {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    left: -777px;
  }
`

const ProgressBackground = styled.div`
  background: ${({ theme }) => theme.colors.deep_koamaru_darkest};
  position: relative;
  height: 6px;
  border: 1px solid ${({ theme }) => theme.colors.deep_koamaru_medium_dark};
  border-radius: 9px;
  overflow: hidden;

  ${({ theme }) => theme.device.desktop} {
    height: 16px;
  }
`

const StyledProgressBar = styled.div<{ valuePercents: string }>`
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: ${props => props.valuePercents}%;
  height: 100%;
  background: ${({ theme }) => theme.gradients.theme_gradient};
  box-shadow: 12px 0px 20px rgba(255, 157, 67, 0.6);
  border-radius: 120px;
`

export const ProgressBar = ({ valuePercents }: { valuePercents: string }) => {
  return (
    <ProgressContainer>
      <progress max="100" value={valuePercents} />
      <ProgressBackground>
        <StyledProgressBar valuePercents={valuePercents} />
      </ProgressBackground>
    </ProgressContainer>
  )
}
