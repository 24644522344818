import styled from 'styled-components'
import { Icon, FlexBox } from '../../../atoms'
import { IconedInfo } from '../../../molecules'
import { HeaderDropdown } from '../../HeaderDropdown'
import React from 'react'
import { BineConnectButton } from '../../../molecules/ConnectButton'

const Container = styled.div<{ bell: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px 8px 8px;
  gap: 10px;

  text-decoration: none;

  ${({ bell }) => (bell ? 'margin-left: 15px;' : '')}

  min-width: 252px;
  width: 100%;
  height: 46px;

  border: 1px solid ${({ theme }) => theme.colors.deep_koamaru_dark};
  border-radius: 50px;
`

const TransformerContainer = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;

  width: auto;
  height: auto;

  transform: ${({ open }) => (open ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: transform 0.2s ease-in-out;

  ${({ theme }) => theme.device.desktop} {
    transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledDropdownContent = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'contents' : 'none')};
  position: absolute;
  z-index: 1;
  flex-direction: column;

  & > div {
    transform: translate(50%, 6%);
    width: 0;
    z-index: 10000;

    ${({ theme }) => theme.device.desktop} {
      transform: translate(42%, 13.5%);
      margin-left: 50px;
    }
  }
`

export type HeaderProfileProps = {
  onConnectWallet: () => void
  onLogout: () => void
  isLoading: boolean
  bell?: boolean
  account?: {
    id: string
    name?: string
    image?: string
    balance?: string
  }
}

export const HeaderProfile = ({ onConnectWallet, onLogout, account, isLoading, bell = true }: HeaderProfileProps) => {
  const [openDropdown, setOpenDropdown] = React.useState(false)

  const onDropdownClick = () => setOpenDropdown(!openDropdown)

  return !account ? (
    <BineConnectButton />
  ) : (
    <Wrapper>
      <FlexBox direction="row" justify="space-around" align="center" style={{ width: '100%' }}>
        {bell && <Icon icon={{ name: 'bell', size: 40 }} />}
        <Container onClick={onDropdownClick} bell={bell}>
          <IconedInfo
            image={account.image ? { src: account.image, size: 'small' } : { address: account.id, size: 'small' }}
            label={{
              text: account.name ?? account.id,
              color: 'white',
              lineHeight: 25,
              fontSize: 18,
            }}
            sublabel={{
              text: account.balance ? `${account.balance} BN` : 'Loading...',
              color: 'orange',
              lineHeight: 19,
              fontSize: 16,
            }}
          />
          <TransformerContainer open={openDropdown}>
            <Icon icon={{ name: 'arrowDownRound', size: 30 }} />
          </TransformerContainer>
        </Container>
      </FlexBox>
      <StyledDropdownContent open={openDropdown}>
        <HeaderDropdown
          open={openDropdown}
          onClose={() => setOpenDropdown(!openDropdown)}
          items={{
            Profile: {
              isActive: true,
              leftIcon: 'profile',
              rightIcon: {
                name: 'rightArrowPick',
                size: 18,
                fill: 'deep_koamaru_medium',
              },
              onClick: `/profile/${account.id}`,
            },
            Settings: {
              isActive: true,
              leftIcon: 'settings',
              rightIcon: {
                name: 'rightArrowPick',
                size: 18,
                fill: 'deep_koamaru_medium',
              },
              onClick: `/settings`,
            },
            'Sign Out': {
              isActive: true,
              leftIcon: 'signOut',
              rightIcon: {
                name: 'rightArrowPick',
                size: 18,
                fill: 'deep_koamaru_medium',
              },
              onClick: onLogout,
            },
          }}
        />
      </StyledDropdownContent>
    </Wrapper>
  )
}
