import styled from 'styled-components/macro'

export const HorizontalLine = styled.hr<{
  margin?: string
  width?: string
  thickness?: string
  color?: 'dark' | 'medium' | 'medium_dark' | 'light'
}>`
  border: none;
  border-top: ${({ thickness }) => thickness ?? '1px'} solid
    ${({ theme, color }) => {
      switch (color ?? 'dark') {
        case 'dark':
          return theme.colors.deep_koamaru_dark
        case 'medium':
          return theme.colors.deep_koamaru_medium
        case 'medium_dark':
          return theme.colors.deep_koamaru_medium_dark
        case 'light':
          return theme.colors.deep_koamaru_light
      }
    }};
  width: ${({ width }) => width ?? '100%'};
  margin: ${({ margin }) => margin || '0'};
`
