import styled from 'styled-components/macro'

export const StyledProgressText = styled.p`
  display: flex;
  align-items: flex-end;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  gap: 4px;

  text-align: center;

  color: ${({ theme }) => theme.colors.deep_koamaru_medium};

  span {
    color: ${({ theme }) => theme.colors.royal_orange_primary};
    margin: 0;
  }

  sub {
    font-size: 12px;
    line-height: 19px;
    margin: 0;
    /* vertical-align: baseline; */
  }

  ${({ theme }) => theme.device.desktop} {
    height: 27px;
    font-size: 24px;
    line-height: 33px;

    sub {
      font-size: 16px;
      line-height: 27px;
    }
  }
`

type ProgressTextProps = {
  current: bigint | number
  total?: bigint | number | null
  title: string
}

export const ProgressText = ({ current, total, title }: ProgressTextProps) => {
  return (
    <StyledProgressText>
      <span>{current.toString()}</span>
      {total != null && ` / ${total}`} <sub>{title}</sub>
    </StyledProgressText>
  )
}
