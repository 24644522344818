import styled from 'styled-components/macro'
import { FlexBox } from '../../atoms'
import { defaultTheme } from '../../theme'

type OnlineStatusProps = {
  status: 'online' | 'offline' | 'away' | 'invisible'
}

const StatusesToColor = {
  online: defaultTheme.colors.special_online_dark,
  offline: defaultTheme.colors.special_out_of_office_dark,
  away: defaultTheme.colors.special_away_dark,
  invisible: defaultTheme.colors.special_invisible_dark,
}

const StatusesToText = {
  online: 'Active',
  offline: 'Offline',
  away: 'Away',
  invisible: 'Invisible',
}

const StatusCircle = styled.div<{ color: string }>`
  width: 14px;
  height: 14px;
  background: ${({ color }) => color};
  border-radius: 30px;
  flex: none;
  order: 0;
  flex-grow: 0;
`

const StatusText = styled.p<{ color: string }>`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 24px;
  line-height: 33px;
  color: ${({ color }) => color};
`

export const OnlineStatus = ({ status }: OnlineStatusProps) => {
  return (
    <FlexBox align="center" gap={14}>
      <StatusCircle color={StatusesToColor[status]} />
      <StatusText color={StatusesToColor[status]}>
        {StatusesToText[status]}
      </StatusText>
    </FlexBox>
  )
}
