import styled from 'styled-components/macro'
import Image from 'next/image'
import { HorizontalLine } from '../../atoms'
import { ItemDescription, ItemProperties } from '../../organisms'
import { ItemInfoAddresses, ItemPrice } from '../../molecules'
import { QuestionMark } from '../../atoms/Icon/assets'
import { useTranslation } from 'next-i18next'

type ItemData = {
  id: string
  name: string
  description: string
  imageHref?: string
  bgColor?: string
  price?: number
  priceUsd?: number
  tag?: 'NFT' | 'FT'
  limited: boolean
  ownersCount: number
  owner?: {
    id: string
    nickname: string
    href?: string
  }
  additional?: { [key: string]: string | number | boolean | any }
  collection?: {
    id: string
    name: string
    imageHref?: string
  }
}

type ItemInfoProps = {
  item: ItemData
  loading?: boolean
}

const StyledItemContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  padding-top: 72px;

  ${({ theme }) => theme.device.desktop} {
    flex-direction: row;
    max-width: 1516px;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    align-items: start;
    margin-bottom: 30px;
    gap: 36px;
  }
`

const StyledItemInfoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;

  ${({ theme }) => theme.device.desktop} {
    width: 438px;
    max-width: none;
  }
`

const StyledImageContainer = styled.div<{ bgColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #9696b2;
  border-radius: 20px;
  width: 100%;
  aspect-ratio: 1 / 1;
  max-width: 500px;

  background: ${({ theme, bgColor }) =>
    `linear-gradient(30deg,${bgColor ?? theme.colors.deep_koamaru_medium}30, ${
      bgColor ?? theme.colors.deep_koamaru_medium
    }10)`};

  img,
  svg {
    width: 90%;
    height: 90%;
    object-fit: cover;
  }

  ${({ theme }) => theme.device.desktop} {
    width: 40vw;
    aspect-ratio: 1 / 1;
    min-width: 300px;
    max-width: 700px;
  }
`

const Heading = styled.h1`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 24px;
  line-height: 33px;
  margin: 0;
  margin-bottom: 6px;
  padding: 0;

  ${({ theme }) => theme.device.desktop} {
    font-size: 36px;
    line-height: 49px;
  }
`

export const ItemInfo: React.FC<ItemInfoProps> = ({ item, loading = false }) => {
  const { t } = useTranslation(['itempage', 'common'])

  const ownerLabel =
    item.ownersCount === 0
      ? t('itempage:no_owners')
      : item.ownersCount > 1
      ? `${item.ownersCount} ${t('itempage:owners')}`
      : item.owner?.nickname ?? t('itempage:no_owners')

  return (
    <StyledItemContainer>
      <StyledImageContainer bgColor={item.bgColor}>
        {item.imageHref ? <Image width={320} height={320} src={item.imageHref} alt={item.name} /> : <QuestionMark />}
      </StyledImageContainer>
      <StyledItemInfoContainer>
        <Heading>{item.name}</Heading>
        <HorizontalLine margin="18px 0" />
        <ItemInfoAddresses ownedBy={ownerLabel} ownedByHref={item.owner?.href} tokenId={item.id} />
        <HorizontalLine margin="18px 0" />
        <ItemPrice price={loading ? t('loading') : item.price} priceUsd={loading ? t('loading') : item.priceUsd} />
        <HorizontalLine margin="18px 0" />
        <ItemDescription
          description={item.description}
          game={{ name: t('game'), imageSrc: item.imageHref }}
          collection={
            item.collection && {
              name: item.collection.name,
              imageSrc: item.collection.imageHref,
            }
          }
        />
        {item.additional ? <ItemProperties properties={item.additional} /> : ''}
      </StyledItemInfoContainer>
    </StyledItemContainer>
  )
}
