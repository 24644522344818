import styled from 'styled-components/macro'
import { useTranslation } from 'next-i18next'
import { FlexBox, HorizontalLine, IconButton, PrimaryButton, Select } from '../../atoms'
import { ExploreItemsFilters } from '../../organisms'
import { ItemCard, ItemCardData } from '../../molecules'
import type { FilterChangeData } from '../../organisms/ExploreItemsFilters'
import { Sidebar, SidebarHeader } from '../../molecules/Sidebar'

import { useId, useState } from 'react'

const DesktopHorizontalLine = styled(HorizontalLine)`
  display: none;
  color: ${({ theme }) => theme.colors.deep_koamaru_medium};

  ${({ theme }) => theme.device.desktop} {
    display: block;
  }
`

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  h1 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 16px;
    line-height: 22px;
    margin: 0 auto 6px 0;
    padding: 0;

    display: flex;
    align-items: center;
    gap: 4px;

    ${({ theme }) => theme.device.desktop} {
      font-size: 24px;
      line-height: 33px;
    }

    sub {
      font-weight: ${({ theme }) => theme.fontWeight.regular};
      font-size: inherit;
      text-align: center;
      color: ${({ theme }) => theme.colors.deep_koamaru_medium};
    }
  }
`

const ItemGrid = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 16px;
  justify-content: center;
  align-items: center;

  margin-top: 17.5px;

  ${({ theme }) => theme.device.desktop} {
    gap: 24px;
    margin-top: 40px;
  }

  @media only screen and (min-width: 300px) and (max-width: 520px) {
    grid-template-columns: repeat(2, minmax(120px, 1fr));
  }

  @media only screen and (min-width: 520px) and (max-width: 720px) {
    grid-template-columns: repeat(3, minmax(120px, 1fr));
  }

  @media only screen and (min-width: 720px) and (max-width: 1024px) {
    grid-template-columns: repeat(4, minmax(120px, 1fr));
  }

  @media only screen and (min-width: 1024px) and (max-width: 1240px) {
    grid-template-columns: repeat(3, minmax(120px, 1fr));
  }

  @media only screen and (min-width: 1240px) {
    grid-template-columns: repeat(4, minmax(120px, 1fr));
  }
`

export type SortType = 'item_id' | 'recently_added' | 'price'

export type ExploreItemsQueryParams = {
  sort: SortType
  filter: FilterChangeData
}

type ExploreItemsProps = {
  items: ItemCardData[]
  titleContent?: React.ReactNode | string
  defaultSorting?: SortType
  isLoading: boolean
  isRefetching: boolean
  onLoadMore?: () => void
  onChangeQuery?: (params: ExploreItemsQueryParams) => void
  full?: boolean
  styles?: React.CSSProperties
}

const SortByLabel = styled.p`
  width: auto;
  display: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: 20px;
  line-height: 24px;
  text-align: right;

  ${({ theme }) => theme.device.desktop} {
    display: block;
  }
`

const StyledIconButton = styled(IconButton)`
  display: flex;

  ${({ theme }) => theme.device.desktop} {
    display: none;
  }
`

const MobileFilterScroll = styled.div`
  overflow-y: scroll;
  height: 100%;
  padding: 0 20px;
  padding-top: 48px;
`

const DesktopExploreItemsFilters = styled.div`
  display: none;
  min-width: 324px;

  @media screen and (max-width: 1440px) {
    min-width: 250px;
  }

  ${({ theme }) => theme.device.desktop} {
    display: block;
  }
`

export const ExploreItems = ({
  items,
  titleContent = <h1>Explore</h1>,
  isLoading,
  defaultSorting = 'recently_added',
  onLoadMore = () => null,
  onChangeQuery = () => null,
  full = false,
  styles,
}: ExploreItemsProps) => {
  const { t } = useTranslation('common')
  const id = useId()
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false)
  const [sorting, setSorting] = useState<SortType>(defaultSorting)
  const [settings, setSettings] = useState<FilterChangeData>({
    filters: {
      games: {
        title: t('games'),
        options: ['All', 'Axie Infinity', 'CryptoPunks', 'CryptoKitties'],
        value: 'All',
      },
      availability: {
        title: t('availability'),
        options: ['For Sale', 'Not For Sale'],
        value: ['For Sale', 'Not For Sale'],
      },
      type: {
        title: t('type'),
        options: ['FT', 'NFT'],
        value: ['FT', 'NFT'],
      },
      amount: {
        title: t('amount'),
        options: ['Limited', 'Unlimited'],
        value: ['Limited', 'Unlimited'],
      },
      rarity: {
        title: t('rarity'),
        options: ['Common', 'Uncommon', 'Rare', 'Epic', 'Legendary', 'Mythical'],
        value: ['Common', 'Uncommon', 'Rare', 'Epic', 'Legendary', 'Mythical'],
      },
    },
    submit: true,
  })

  const sortingMap: Record<string, SortType> = {
    'Item ID': 'item_id',
    'Recently added': 'recently_added',
    Price: 'price',
  }

  const stringToSortType = (str: string): SortType => {
    return sortingMap[str]
  }

  const sortTypeToString = (sortType: SortType): string => {
    return Object.keys(sortingMap).find(key => sortingMap[key] === sortType) || ''
  }

  const onSortSelectChanged = (value: string) => {
    const newSorting = stringToSortType(value)
    setSorting(newSorting)
    onChangeQuery({ sort: newSorting, filter: settings })
  }

  const onFilterChange = (newSettings: FilterChangeData) => {
    setSettings(newSettings)
    if (newSettings.submit) {
      setMobileFilterOpen(false)
      onChangeQuery({ sort: sorting, filter: newSettings })
    }
  }

  return (
    <section style={{ paddingBottom: '30px', ...styles }}>
      <HeaderContent>
        {titleContent}
        <SortByLabel>{t('sort_by')}: </SortByLabel>
        <StyledIconButton icon={{ icon: 'filter' }} filled={true} onClick={() => setMobileFilterOpen(true)} />
        <Select
          options={Object.values(sortingMap)}
          onChanged={onSortSelectChanged}
          defaultValue={sortTypeToString(sorting)}
        />
      </HeaderContent>
      <DesktopHorizontalLine margin="24px 0 0 0" />
      <FlexBox align="flex-start" gap={24} direction="row">
        <Sidebar isOpen={mobileFilterOpen} top={0}>
          <SidebarHeader title={t('filters')} onClose={() => setMobileFilterOpen(false)} />
          <MobileFilterScroll>
            <ExploreItemsFilters settings={settings} onFilterChange={onFilterChange} buttonsRequired />
          </MobileFilterScroll>
        </Sidebar>
        <DesktopExploreItemsFilters>
          <ExploreItemsFilters settings={settings} onFilterChange={onFilterChange} />
        </DesktopExploreItemsFilters>
        <div style={{ flexGrow: 1 }}>
          <ItemGrid>
            {items.map(item => (
              <ItemCard key={`${id}-${item.id}`} item={item} refreshing={isLoading} />
            ))}
          </ItemGrid>
          {full ? null : (
            <PrimaryButton
              outline
              title={t('explore_more')}
              onClick={onLoadMore}
              disabled={isLoading}
              loading={isLoading}
              style={{ marginTop: '36px' }}
            />
          )}
        </div>
      </FlexBox>
    </section>
  )
}
