import styled from 'styled-components/macro'
import * as theme from '../../theme'

const colorMap = {
  online: theme.defaultTheme.colors.special_online_dark,
  away: theme.defaultTheme.colors.special_away_dark,
  offline: theme.defaultTheme.colors.special_out_of_office_dark,
  'do-not-disturb': theme.defaultTheme.colors.special_do_not_disturb_dark,
}

export type BadgeProps = {
  color?: string
  status?: keyof typeof colorMap
}

export const Badge = styled.label<BadgeProps>`
  border-radius: 6px;
  border: 0.5px solid
    ${({ status, color }) => (status ? colorMap[status] : color)};
  padding: 2px 4px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  width: fit-content;
  height: fit-content;
  font-size: 6px;
  line-height: 7px;
  text-transform: uppercase;
  text-align: center;
  color: ${({ status, color }) => (status ? colorMap[status] : color)};

  ${theme.defaultTheme.device.desktop} {
    font-size: 10px;
    line-height: 12px;
    padding: 4px 8px;
  }
`
