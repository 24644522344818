import type { Size } from './types'
import { deviceSizes } from './visual'

export const getSize = (size: Size | undefined) =>
  typeof size === 'number'
    ? { width: size, height: size }
    : { width: size?.width, height: size?.height }

export function detectDevice(windowWidth: number) {
  if (windowWidth < deviceSizes.phone.max) {
    return 'mobile'
  } else if (windowWidth < deviceSizes.tablet.max) {
    return 'tablet'
  } else {
    return 'desktop'
  }
}

export const shortenText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text
  }
  return text.substring(0, maxLength) + '...'
}

export const capitalizeFirstLetter = <T extends string = string>(
  string: string,
): T => {
  return (string.charAt(0).toUpperCase() + string.slice(1)) as T
}

export const paddingY = (pixels: number) =>
  `padding-left: ${pixels}px;padding-right: ${pixels}px;`

export const paddingX = (pixels: number) =>
  `padding-top: ${pixels}px;padding-bottom: ${pixels}px;`

export const marginY = (pixels: number) =>
  `margin-left: ${pixels}px;margin-right: ${pixels}px;`

export const marginX = (pixels: number) =>
  `margin-top: ${pixels}px;margin-bottom: ${pixels}px;`
