import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'

type RangeSliderProps = {
  min: number
  max: number
  value: { from: number; to: number }
  step: number
  onChange: (value: { from: number; to: number }) => void
}

const trackStyles = css`
  appearance: none;
  background: transparent;
  border: transparent;
`

const thumbStyles = css`
  appearance: none;
  pointer-events: all;
  width: 20px;
  height: 20px;
  border-radius: 0px;
  border: 0 none;
  cursor: grab;
  background-color: red;

  ${({ theme }) => theme.device.desktop} {
    width: 24px;
    height: 24px;
  }

  &:active {
    cursor: grabbing;
  }
`

const Root = styled.div`
  max-width: 500px;
  padding: 12px;
  margin: auto;
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 40px 10px;
  padding-top: 1.6rem;
  height: calc(20px + 1.6rem);
`

const InputWrapper = styled.div`
  width: calc(100% + 20px);
  margin: 0 -10px;
  position: absolute;
  height: 20px;

  ${({ theme }) => theme.device.desktop} {
    width: calc(100% + 24px);
    margin: 0 -12px;
    height: 24px;
  }
`

const ControlWrapper = styled.div`
  width: 100%;
  position: absolute;
  height: 20px;

  ${({ theme }) => theme.device.desktop} {
    height: 24px;
  }
`

const Input = styled.input`
  position: absolute;
  width: 100%;
  pointer-events: none;
  appearance: none;
  height: 100%;
  opacity: 0;
  z-index: 3;
  padding: 0;

  &::-ms-track {
    ${trackStyles}
  }

  &::-moz-range-track {
    ${trackStyles}
  }

  &:focus::-webkit-slider-runnable-track {
    ${trackStyles}
  }

  &::-ms-thumb {
    ${thumbStyles}
  }

  &::-moz-range-thumb {
    ${thumbStyles}
  }

  &::-webkit-slider-thumb {
    ${thumbStyles}
  }
`

const Rail = styled.div`
  width: 100%;
  height: 5px;
  position: absolute;
  top: 50%;
  background-color: ${({ theme }) => theme.colors.deep_koamaru_dark};
  transform: translateY(-50%);
`

const InnerRail = styled.div`
  position: absolute;
  height: 100%;
  background: ${({ theme }) => theme.colors.royal_orange_primary};
`

const Control = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.neutral_white};
  border: 3px solid ${({ theme }) => theme.colors.royal_orange_primary};
  top: 50%;
  margin-left: -10px;
  transform: translate3d(0, -50%, 0);
  z-index: 2;

  ${({ theme }) => theme.device.desktop} {
    width: 24px;
    height: 24px;
  }
`

export const RangeSlider: React.FC<RangeSliderProps> = ({
  min,
  max,
  value,
  step,
  onChange,
}) => {
  const [minValue, setMinValue] = useState(value ? value.from : min)
  const [maxValue, setMaxValue] = useState(value ? value.to : max)

  useEffect(() => {
    if (value) {
      setMinValue(value.from)
      setMaxValue(value.to)
    }
  }, [value])

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const newMinVal = Math.min(+e.target.value, maxValue - step)
    if (!value) setMinValue(newMinVal)
    onChange({ from: newMinVal, to: maxValue })
  }

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const newMaxVal = Math.max(+e.target.value, minValue + step)
    if (!value) setMaxValue(newMaxVal)
    onChange({ from: minValue, to: newMaxVal })
  }

  const minPos = ((minValue - min) / (max - min)) * 100
  const maxPos = ((maxValue - min) / (max - min)) * 100

  return (
    <Wrapper>
      <InputWrapper>
        <Input
          type="range"
          value={minValue}
          min={min}
          max={max}
          step={step}
          onChange={handleMinChange}
        />
        <Input
          type="range"
          value={maxValue}
          min={min}
          max={max}
          step={step}
          onChange={handleMaxChange}
        />
      </InputWrapper>
      <ControlWrapper>
        <Control style={{ left: `${minPos}%` }} />
        <Rail>
          <InnerRail
            style={{ left: `${minPos}%`, right: `${100 - maxPos}%` }}
          />
        </Rail>
        <Control style={{ left: `${maxPos}%` }} />
      </ControlWrapper>
    </Wrapper>
  )
}
