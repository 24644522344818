import styled from 'styled-components/macro'
import Image from 'next/image'

const StyledBackgroundImage = styled.div<{ image?: string; bgColor?: string }>`
  width: 100%;
  height: 224px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
  pointer-events: none;
  background: ${({ image, bgColor, theme }) =>
    image
      ? `url(${image})`
      : `linear-gradient(70deg, ${bgColor ?? theme.colors.deep_koamaru_medium}40, ${
          bgColor ?? theme.colors.deep_koamaru_medium
        }10)`};

  ${({ theme }) => theme.device.desktop} {
    height: 435px;
  }
`

const StyledImage = styled(({ bgColor, ...props }) => <Image {...props} />)<{ bgColor?: string }>`
  position: relative;
  margin-top: 162px;
  width: 120px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  border: 4px solid ${({ theme }) => theme.colors.deep_koamaru_primary};
  background: ${({ bgColor, theme }) =>
    `linear-gradient(70deg, ${bgColor ?? theme.colors.deep_koamaru_medium}40, ${
      bgColor ?? theme.colors.deep_koamaru_medium
    }20), ${theme.colors.deep_koamaru_primary}`};
  border-radius: 50%;

  ${({ theme }) => theme.device.desktop} {
    width: 130px;
    height: 130px;
    margin-top: 366px;
  }
`

const StyledItemTitle = styled.h1`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 24px;
  line-height: 33px;
  margin: 0;

  ${({ theme }) => theme.device.desktop} {
    font-size: 36px;
    line-height: 49px;
  }
`

const StyledItemGameTitle = styled.h2`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 16px;
  line-height: 22px;
  margin: 0;

  text-align: center;

  color: ${({ theme }) => theme.colors.royal_orange_primary};

  ${({ theme }) => theme.device.desktop} {
    font-size: 20px;
    line-height: 27px;
  }
`

const StyledItemDescription = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: 16px;
  line-height: 19px;
  max-width: 329px;

  margin: 6px 0 0 0;

  text-align: center;

  color: white;

  ${({ theme }) => theme.device.desktop} {
    font-size: 20px;
    line-height: 24px;
    max-width: 592px;
  }
`

type CollectionInfoHeadData = {
  image?: string
  title: string
  gameName: string
  backgroundImage: string
  description?: string
  chest: {
    additional?: any
  }
}

type CollectionInfoHeadProps = {
  collection: CollectionInfoHeadData
}

export const CollectionInfoHead = ({ collection }: CollectionInfoHeadProps) => {
  return (
    <>
      <StyledBackgroundImage image={collection.backgroundImage} bgColor={collection.chest.additional?.rarity_color} />
      <StyledImage
        src={collection.image ?? 'https://via.placeholder.com/150/#6e55ab'}
        width={120}
        height={120}
        alt={"Collection's image"}
        bgColor={collection.chest.additional?.rarity_color}
      />
      <StyledItemTitle>{collection.title}</StyledItemTitle>
      <StyledItemGameTitle>{collection.gameName}</StyledItemGameTitle>
      <StyledItemDescription>{collection.description}</StyledItemDescription>
    </>
  )
}
