export const formatDuration = (durationAgo: number) => {
  if (durationAgo < 1) return 'just now'
  if (durationAgo < 60) return `${Math.round(durationAgo)} seconds`
  if (durationAgo < 3600) {
    const minutes = Math.round(durationAgo / 60)
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`
  }
  if (durationAgo < 86400) {
    const hours = Math.round(durationAgo / 3600)
    return `${hours} ${hours === 1 ? 'hour' : 'hours'}`
  }

  const days = Math.round(durationAgo / 86400)
  return `${days} ${days === 1 ? 'day' : 'days'}`
}
