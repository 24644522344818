import styled from 'styled-components'
import { DropdownItem, DropdownItemProps } from '../../molecules'

const Background = styled.a<{ isOpen: boolean }>`
  display: ${({ isOpen: open }) => (open ? 'fixed' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9900;

  cursor: pointer;
`
const SubContainer = styled.div`
  display: relative;
  position: absolute;
  z-index: 10000;
  width: 0;
`

const Container = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: 'relative';
  top: 0px;
  left: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
  width: 244px;
  height: auto;

  transform: translate(-50%, -110%);

  background: ${({ theme }) => theme.colors.deep_koamaru_darkest};

  border: 1px solid ${({ theme }) => theme.colors.deep_koamaru_medium_dark};
  box-shadow: 0px 10px 24px rgba(18, 18, 43, 0.6);
  border-radius: 10px;

  ${({ theme }) => theme.device.desktop} {
    transform: translate(-50%, +21%);
  }
`

export type DropdownProps = {
  items: Record<string, Omit<DropdownItemProps & { translation?: string }, 'text'>>
  onClose: () => void
  open: boolean
  style?: React.CSSProperties
}

export const HeaderDropdown = ({ items, open, onClose, style }: DropdownProps) => {
  return (
    <>
      <Background isOpen={open} onClick={onClose} />
      <SubContainer style={style}>
        <Container isOpen={open}>
          {Object.entries(items).map(([name, value]) => (
            <DropdownItem key={name} text={value.translation ?? name} {...value} />
          ))}
        </Container>
      </SubContainer>
    </>
  )
}
