import styled from 'styled-components/macro'
import { Table, IconedInfo } from '../../../molecules'
import { ReactComponent as LinkIcon } from '../../../assets/icons/link.svg'

import { createColumnHelper } from '@tanstack/react-table'
import { PrimaryButton } from '../../../atoms'
import { useTranslation } from 'next-i18next'

export type Activity = {
  item: {
    id: string
    name: string
    collectionId?: string
    collectionName?: string
    image?: string
  }
  event: {
    type: 'listed' | 'transfered' | 'burnt' | 'minted' | 'unlisted' | 'opened'
    amount: bigint
  }
  price: { native?: number; usd?: number }
  from: {
    id: string
    name: string
    image?: string
  }
  to: {
    id: string
    name: string
    image?: string
  }
  date: Date
  href?: string
}

const StyledDate = styled.p`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 24px;
  line-height: 33px;

  color: ${({ theme }) => theme.colors.deep_koamaru_medium};

  span {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    color: white;
  }
`

type ActivityTableProps = {
  data: Activity[]
  isLoading?: boolean
  onLoadMore?: () => void
  full?: boolean
}

export const ActivityTable = ({ data, isLoading, onLoadMore, full }: ActivityTableProps) => {
  const { t } = useTranslation('common')

  const columnHelper = createColumnHelper<Activity>()

  const columns = [
    columnHelper.accessor('item', {
      header: () => t('item'),
      cell: info => {
        const props = info.getValue()
        return (
          <IconedInfo
            image={props.image !== undefined ? { src: props.image, size: 'normal' } : undefined}
            label={{
              text: props.name,
              color: 'white',
              href: `/item/${props.id}`,
            }}
            sublabel={
              props.collectionName !== undefined
                ? {
                    text: props.collectionName,
                    color: 'orange',
                    href: `/collection/${props.collectionId}`,
                  }
                : undefined
            }
          />
        )
      },
    }),
    columnHelper.accessor('event', {
      header: () => t('event'),
      cell: info => {
        const { type, amount } = info.getValue()
        return (
          <IconedInfo image={{ icon: type, size: 'big' }} label={{ text: `${t(type)} (x${amount})`, color: 'white' }} />
        )
      },
    }),
    columnHelper.accessor('price', {
      header: () => t('price'),
      cell: info => {
        const { native, usd } = info.getValue()
        return (
          <IconedInfo label={{ text: `${native} BN`, color: 'orange' }} sublabel={{ text: `$${usd}`, color: 'gray' }} />
        )
      },
    }),
    columnHelper.accessor('from', {
      header: () => t('from'),
      cell: info => {
        const { id, name, image } = info.getValue()
        return (
          <IconedInfo
            image={image ? { src: image, size: 'small' } : { address: id, size: 'small' }}
            label={{ text: name ?? id, color: 'orange', href: `/profile/${id}` }}
          />
        )
      },
    }),
    columnHelper.accessor('to', {
      header: () => t('to'),
      cell: info => {
        const { id, name, image } = info.getValue()
        return (
          <IconedInfo
            image={image ? { src: image, size: 'small' } : { address: id, size: 'small' }}
            label={{ text: name ?? id, color: 'orange', href: `/profile/${id}` }}
          />
        )
      },
    }),
    columnHelper.accessor('date', {
      header: () => t('date'),
      cell: info => {
        const date = info.getValue()
        return (
          <StyledDate>
            <span>{date.toLocaleDateString()}</span> , {date.toLocaleTimeString()}
          </StyledDate>
        )
      },
    }),
    columnHelper.accessor('href', {
      header: () => '',
      cell: info => {
        const href = info.getValue()
        return (
          href && (
            <a href={href}>
              <LinkIcon />
            </a>
          )
        )
      },
    }),
  ]

  return (
    <>
      <Table
        data={data}
        columns={columns}
        columnVisibility={{
          item: 'mobile',
          event: 600,
          price: 'mobile',
          from: 1614,
          to: 1614,
          date: 900,
          href: 1150,
        }}
      />
      {full ? null : (
        <PrimaryButton
          outline
          title={t('explore_more')}
          onClick={onLoadMore}
          disabled={isLoading}
          loading={isLoading}
          style={{ marginBottom: '30px' }}
        />
      )}
    </>
  )
}
