import styled from 'styled-components/macro'
import { Badge, BadgeProps } from '../../Badge'

type Props = {
  bgSrc: string
  title: string
  subtitle: string
  avatarSrc: string
  genreStatus?: BadgeProps['status']
  genreColor?: string
  genreText?: string
}

type GameAvatarProps = {
  bgSrc: string
}

type GameCardProps = GameAvatarProps

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 156px;

  ${({ theme }) => theme.device.desktop} {
    width: 438px;
  }
`

const StyledGameInfoContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledGameAvatar = styled.div<GameAvatarProps>`
  width: 39px;
  height: 39px;
  border-radius: 30px;
  margin-right: 6px;
  background: ${({ bgSrc }) => `url(${bgSrc})`};
  border: ${({ theme }) => `1px solid ${theme.colors.deep_koamaru_medium}`};

  ${({ theme }) => theme.device.desktop} {
    width: 58px;
    height: 58px;
    margin-right: 16px;
  }
`

const StyledGameInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledGameTitle = styled.p`
  margin: 0;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 12px;
  margin: 2px 0;
  line-height: 16px;
  color: #ffffff;

  ${({ theme }) => theme.device.desktop} {
    margin: 6px 0;
    font-size: 24px;
    line-height: 32px;
  }
`

const StyledGameSubtitle = styled.p`
  margin: 0;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.deep_koamaru_lightest};

  ${({ theme }) => theme.device.desktop} {
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.deep_koamaru_medium};
  }
`
// @TODO: make a separate file for this
export const StyledGameCard = styled.div<GameCardProps>`
  width: 156px;
  height: 96px;
  box-sizing: border-box;
  margin-bottom: 6px;
  border: 1px solid ${({ theme }) => theme.colors.deep_koamaru_medium};
  border-radius: 16px;
  background: ${props => `center/cover url(${props.bgSrc})`};
  cursor: pointer;

  &:hover:not([disabled]) {
    border: 1px solid ${({ theme }) => theme.colors.royal_orange_primary};
    background: ${props =>
      `linear-gradient(0deg, rgba(18, 18, 43, 0.4), rgba(18, 18, 43, 0.4)), center/cover url(${props.bgSrc})`};
  }

  ${({ theme }) => theme.device.desktop} {
    width: 438px;
    height: 241px;
    border-radius: 30px;
    margin-bottom: 16px;

    &:hover:not([disabled]) {
      border: 2px solid ${({ theme }) => theme.colors.deep_koamaru_medium};
    }
  }
`

export const GameCard = (props: Props) => {
  return (
    <StyledContainer>
      <StyledGameCard bgSrc={props.bgSrc} />
      <StyledGameInfoContainer>
        <StyledGameAvatar bgSrc={props.avatarSrc} />
        <StyledGameInfo>
          <Badge status={props.genreStatus} color={props.genreColor}>
            {props.genreText}
          </Badge>
          <StyledGameTitle>{props.title}</StyledGameTitle>
          <StyledGameSubtitle>{props.subtitle}</StyledGameSubtitle>
        </StyledGameInfo>
      </StyledGameInfoContainer>
    </StyledContainer>
  )
}
