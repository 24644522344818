import styled from 'styled-components/macro'

export const PageContainer = styled.div`
  padding: 64px 16px 0 16px;
  width: auto;
  max-width: 1920px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.deep_koamaru_primary};
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.device.desktop} {
    padding: 98px 46px 0 46px;
  }
`
