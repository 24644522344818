import styled from 'styled-components/macro'
import { QuestionMark } from '../../../atoms/Icon/assets'
import Image from 'next/image'
import { ItemTag } from '../../ItemTag'

import Link from 'next/link'
import { useTranslation } from 'next-i18next'

export type ItemCardData = {
  id: string
  bgSrc: string
  bgColor?: string
  title: string
  gameTitle: string
  price?: number
  priceUsd?: number
  href: string
  amount?: number
  limited: boolean
  tag: 'NFT' | 'FT'
}

type ItemCardProps = {
  item: ItemCardData
  refreshing?: boolean
}

const StyledItemContainer = styled.div<{ refreshing: boolean }>`
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;

  ${({ refreshing }) =>
    refreshing && 'filter: opacity(50%); transition: filter 0.3s ease-in-out; pointer-events: none;'}
`

const StyledItemCard = styled(Link)<ItemCardProps>`
  position: relative;
  width: auto;
  height: auto;
  aspect-ratio: 156 / 122;
  display: flex;
  position: relative;
  margin-bottom: 6px;
  border: 1px solid ${({ theme }) => theme.colors.deep_koamaru_medium};
  border-radius: 10px;
  background: ${props =>
    `linear-gradient(30deg, ${props.item.bgColor ?? props.theme.colors.deep_koamaru_medium}70, ${
      props.item.bgColor ?? props.theme.colors.deep_koamaru_medium
    }10)`};
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;

  img {
    width: 85%;
    height: 85%;
    margin: auto;
    object-fit: contain;
  }

  // Missing icon
  .missing-item {
    width: 50%;
    height: 50%;
    aspect-ratio: 1 / 1;
    margin: auto;
    object-fit: contain;
  }

  &:hover:not([disabled]) {
    box-shadow: 0px 30px 50px 10px rgba(25, 18, 43, 0.3);
    z-index: 10;
  }

  ${({ theme }) => theme.device.desktop} {
    border-radius: 20px;
    margin-bottom: 14px;
    aspect-ratio: 361 / 330;

    &:hover:not([disabled]) {
      box-shadow: 0px 30px 90px 30px rgba(18, 18, 43, 0.3);
    }
  }
`

const StyledItemData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledItemTitle = styled.p<{ gray: boolean }>`
  margin: 0;
  font-style: normal;
  font-family: 'Manrope', sans-serif;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme, gray }) => (gray ? theme.colors.deep_koamaru_medium_light : theme.colors.neutral_white)};
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme.device.desktop} {
    font-size: 20px;
    line-height: 27px;
  }
`

const StyledGameTitle = styled.p`
  margin: 0;
  font-style: normal;
  font-family: 'Manrope', sans-serif;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 10px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.deep_koamaru_medium};
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme.device.desktop} {
    font-size: 16px;
    line-height: 22px;
  }
`

const StyledItemPrice = styled.p`
  margin: 0;
  font-style: normal;
  font-family: 'Manrope', sans-serif;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.royal_orange_primary};
  white-space: nowrap;

  ${({ theme }) => theme.device.desktop} {
    font-size: 20px;
    line-height: 27px;
  }
`

const StyledItemPriceUsd = styled.p`
  margin: 0;
  font-style: normal;
  font-family: 'Manrope', sans-serif;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: 8px;
  line-height: 7px;
  color: ${({ theme }) => theme.colors.deep_koamaru_medium};

  ${({ theme }) => theme.device.desktop} {
    font-size: 12px;
    line-height: 16px;
  }
`

const StyledAmountLabel = styled.p`
  position: absolute;
  right: 8px;
  bottom: 8px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  gap: 10px;

  min-width: 16px;
  height: 12px;

  background: rgba(45, 45, 107, 0.4);
  backdrop-filter: blur(7px);

  border-radius: 8px;

  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 12px;
  line-height: 16px;
  color: white;

  pointer-events: none;
  margin: 0;

  ${({ theme }) => theme.device.desktop} {
    right: 20px;
    bottom: 20px;

    min-width: 51px;
    height: 27px;
    font-size: 20px;
    line-height: 27px;
  }
`

const StyledItemTag = styled(ItemTag)`
  position: absolute;
  top: 8px;
  left: 8px;
`

export const ItemCard = ({ item, refreshing = false }: ItemCardProps) => {
  const { t } = useTranslation('common')

  return (
    <StyledItemContainer refreshing={refreshing}>
      <StyledItemCard item={item} href={item.href}>
        <StyledItemTag tag={item.tag} limited={item.limited} />
        {item.bgSrc ? (
          <Image src={item.bgSrc} alt={item.title} width={500} height={500} />
        ) : (
          <QuestionMark className="missing-item" />
        )}
        {item.amount && <StyledAmountLabel>x{item.amount}</StyledAmountLabel>}
      </StyledItemCard>
      <StyledItemData>
        <StyledItemTitle gray={!item.title}>{item.title ?? t('unknown_name')}</StyledItemTitle>
        {item.price !== undefined && <StyledItemPrice>{item.price} BN</StyledItemPrice>}
      </StyledItemData>
      <StyledItemData>
        <StyledGameTitle>{item.gameTitle}</StyledGameTitle>
        {item.priceUsd !== undefined && <StyledItemPriceUsd>${item.priceUsd}</StyledItemPriceUsd>}
      </StyledItemData>
    </StyledItemContainer>
  )
}
