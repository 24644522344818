import styled, { css } from 'styled-components/macro'
import Skeleton from 'react-loading-skeleton'
import NextImage from 'next/image'

export const Container = styled.div<{
  width?: number | string
  height?: number | string
}>`
  display: flex;
  ${({ width, height }) => css`
    width: ${width ?? 0}px;
    height: ${height ?? 0}px;
  `}
`

export const ImageContent = styled(NextImage)<{
  width?: number | string
  height?: number | string
}>`
  ${({ width, height }) => css`
    width: ${width ?? 0}px;
    height: ${height ?? 0}px;
  `}
`

export const SkeletonLoader = styled(Skeleton)<{
  width?: number | string
  height?: number | string
}>`
  overflow: hidden;
  top: 0;
  position: absolute;
  ${({ width, height }) => css`
    width: ${width ?? 0}px;
    height: ${height ?? 0}px;
  `}
`
