import { useRef } from 'react'
import styled from 'styled-components/macro'

type RadioProps = {
  id?: string
  checked?: boolean
  marginRight?: string
  name: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const RadioContainer = styled.div<{ marginRight?: string }>`
  position: relative;
  display: inline-block;
  margin-right: ${({ marginRight }) => marginRight || '0'};
`

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`

const RadioIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.deep_koamaru_medium};
  background-color: transparent;

  ${({ theme }) => theme.device.desktop} {
    width: 20px;
    height: 20px;
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.royal_orange_primary};
  }

  &:after {
    content: '';
    display: none;
  }

  ${RadioInput}:checked + & {
    outline-offset: -3px;
    outline: 2px solid ${({ theme }) => theme.colors.deep_koamaru_primary};
    background-color: ${({ theme }) => theme.colors.royal_orange_primary};
    border: 0;
    &:after {
      display: block;
    }

    ${({ theme }) => theme.device.desktop} {
      outline-offset: -4px;
      outline: 3px solid ${({ theme }) => theme.colors.deep_koamaru_primary};
    }
  }
`

export const Radio = ({ marginRight, ...props }: RadioProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <RadioContainer marginRight={marginRight}>
      <RadioInput ref={inputRef} type="radio" {...props} />
      <RadioIcon />
    </RadioContainer>
  )
}
