import { Chain } from '@rainbow-me/rainbowkit'

export const bineTestnet: Chain = {
  id: 1337,
  name: 'Bine Testnet',
  network: 'bine',
  iconUrl: 'https://app.bine.games/favicon.png',
  iconBackground: 'rgb(45, 45, 107)',
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://testnet.bine.games'] },
    default: { http: ['https://testnet.bine.games'] },
  },
  // contracts: {
  //   multicall3: {
  //     address: '0xca11bde05977b3631167028862be2a173976ca11',
  //     blockCreated: 11_907_934,
  //   },
  // },
  testnet: true,
}
