import styled from 'styled-components'
import { FlexBox, Icon } from '../../atoms'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;

  width: 100%;
  height: 64px;
`

export const StyledInput = styled.input`
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: 18px;
  line-height: 22px;

  color: ${({ theme }) => theme.colors.deep_koamaru_medium};
  background-color: transparent;
  border: none;
`

export const MobileSidebarSearch = () => {
  return (
    <StyledContainer>
      <FlexBox style={{ width: '58px' }} justify="center">
        <Icon icon={{ name: 'search', size: 24 }} />
      </FlexBox>
      <StyledInput placeholder="Items, Collections, and More" />
    </StyledContainer>
  )
}
