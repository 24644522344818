import React from 'react'
import { ItemHistoryTransfer, ItemHistoryTransferProps } from '../../molecules'

import { Accordion } from '..'
import { HorizontalLine, PrimaryButton } from '../../atoms'
import styled from 'styled-components/macro'

type ItemHistoryProps = {
  transfers: ItemHistoryTransferProps[]
  full?: boolean
  loading?: boolean
  onLoadMore?: () => void
}

const StyledAccordionContainer = styled.div`
  margin-top: 18px;
  margin-bottom: 18px;
  max-width: 1516px;
  margin-left: auto;
  margin-right: auto;
`

export const ItemHistory = ({ transfers, full = false, loading = false, onLoadMore }: ItemHistoryProps) => {
  return transfers.length === 0 ? null : (
    <StyledAccordionContainer>
      <Accordion title="Trading History" open>
        <HorizontalLine />
        {transfers.map((transfer, index) => (
          <React.Fragment key={index}>
            <ItemHistoryTransfer key={index} {...transfer} />
            <HorizontalLine />
          </React.Fragment>
        ))}
        {full ? null : <PrimaryButton outline title={'Load More'} onClick={onLoadMore} loading={loading} />}
      </Accordion>
    </StyledAccordionContainer>
  )
}
