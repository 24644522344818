import styled from 'styled-components/macro'

type FlexBoxProps = {
  direction?: 'row' | 'column'
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  align?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline'
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
  content?:
    | 'stretch'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'initial'
    | 'inherit'
  flex?: string
  order?: string
  grow?: string
  shrink?: string
  self?: string
  gap?: number
}

export const FlexBox = styled.div<FlexBoxProps>`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justify || 'flex-start'};
  align-items: ${props => props.align || 'stretch'};
  flex-wrap: ${props => props.wrap || 'nowrap'};
  align-content: ${props => props.content || 'stretch'};
  flex: ${props => props.flex || '0 1 auto'};
  order: ${props => props.order || '0'};
  flex-grow: ${props => props.grow || '0'};
  flex-shrink: ${props => props.shrink || '1'};
  align-self: ${props => props.self || 'auto'};
  gap: ${props => props.gap || 0}px;
`
