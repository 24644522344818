// NOTE: exports are generated by `yarn generate-icons`, do not edit it
export { ReactComponent as ArrowDownRound } from '../../assets/icons/arrow-down-round.svg'
export { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg'
export { ReactComponent as Article } from '../../assets/icons/article.svg'
export { ReactComponent as Bell } from '../../assets/icons/bell.svg'
export { ReactComponent as BellNotification } from '../../assets/icons/bellNotification.svg'
export { ReactComponent as BurgerMenu } from '../../assets/icons/burger-menu.svg'
export { ReactComponent as BurntIcon } from '../../assets/icons/burnt-icon.svg'
export { ReactComponent as Check } from '../../assets/icons/check.svg'
export { ReactComponent as China } from '../../assets/icons/china.svg'
export { ReactComponent as Clock } from '../../assets/icons/clock.svg'
export { ReactComponent as Close } from '../../assets/icons/close.svg'
export { ReactComponent as CloudedSun } from '../../assets/icons/cloudedSun.svg'
export { ReactComponent as Community } from '../../assets/icons/community.svg'
export { ReactComponent as Discord } from '../../assets/icons/discord.svg'
export { ReactComponent as Earth } from '../../assets/icons/earth.svg'
export { ReactComponent as Filter } from '../../assets/icons/filter.svg'
export { ReactComponent as Gamepad } from '../../assets/icons/gamepad.svg'
export { ReactComponent as HelpCenter } from '../../assets/icons/help-center.svg'
export { ReactComponent as Instagram } from '../../assets/icons/instagram.svg'
export { ReactComponent as Language } from '../../assets/icons/language.svg'
export { ReactComponent as LeftArrow } from '../../assets/icons/left-arrow.svg'
export { ReactComponent as Link } from '../../assets/icons/link.svg'
export { ReactComponent as ListedIcon } from '../../assets/icons/listed-icon.svg'
export { ReactComponent as Loading } from '../../assets/icons/loading.svg'
export { ReactComponent as Logo } from '../../assets/icons/logo.svg'
export { ReactComponent as Medium } from '../../assets/icons/medium.svg'
export { ReactComponent as MintedIcon } from '../../assets/icons/minted-icon.svg'
export { ReactComponent as Moon } from '../../assets/icons/moon.svg'
export { ReactComponent as Profile } from '../../assets/icons/profile.svg'
export { ReactComponent as QuestionMark } from '../../assets/icons/question-mark.svg'
export { ReactComponent as Refresh } from '../../assets/icons/refresh.svg'
export { ReactComponent as RightArrowPick } from '../../assets/icons/right-arrow-pick.svg'
export { ReactComponent as Search } from '../../assets/icons/search.svg'
export { ReactComponent as Settings } from '../../assets/icons/settings.svg'
export { ReactComponent as Share } from '../../assets/icons/share.svg'
export { ReactComponent as SignOut } from '../../assets/icons/sign-out.svg'
export { ReactComponent as SnackbarCross } from '../../assets/icons/snackbar-cross.svg'
export { ReactComponent as SnackbarError } from '../../assets/icons/snackbar-error.svg'
export { ReactComponent as SnackbarInfo } from '../../assets/icons/snackbar-info.svg'
export { ReactComponent as SnackbarSuccess } from '../../assets/icons/snackbar-success.svg'
export { ReactComponent as SnackbarWarning } from '../../assets/icons/snackbar-warning.svg'
export { ReactComponent as Spain } from '../../assets/icons/spain.svg'
export { ReactComponent as Sun } from '../../assets/icons/sun.svg'
export { ReactComponent as Telegram } from '../../assets/icons/telegram.svg'
export { ReactComponent as TransferIcon } from '../../assets/icons/transfer-icon.svg'
export { ReactComponent as Twitter } from '../../assets/icons/twitter.svg'
export { ReactComponent as UnlistedIcon } from '../../assets/icons/unlisted-icon.svg'
export { ReactComponent as Vietnam } from '../../assets/icons/vietnam.svg'
export { ReactComponent as Wallet } from '../../assets/icons/wallet.svg'
export { ReactComponent as Whitepaper } from '../../assets/icons/whitepaper.svg'
export { ReactComponent as Ukraine } from '../../assets/icons/ukraine.svg'
export { ReactComponent as Ethereum } from '../../assets/icons/ethereum.svg'
