import { RadioInput } from '../../../molecules'
import { Accordion } from '../../../organisms'
import styled from 'styled-components/macro'
import { useId } from 'react'
import { useTranslation } from 'next-i18next'

const AccordionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 16px;
`

type RadioFilterSelectorProps<T extends string = string> = {
  title: string
  items: T[]
  selectedItem: T
  onChange?: (item: T) => void
}

export const RadioFilterSelector = <T extends string = string>({
  title,
  items,
  selectedItem,
  onChange = () => null,
}: RadioFilterSelectorProps<T>) => {
  const radioName = useId()
  const { t } = useTranslation('common')

  return (
    <Accordion title={title} open>
      <AccordionContent>
        {items.map(item => {
          console.log(item)
          return (
            <RadioInput
              key={item}
              name={radioName}
              label={t(item.toLowerCase().replace(/ /g, '_'))}
              value={item}
              checked={selectedItem === item}
              onSelected={() => onChange(item)}
            />
          )
        })}
      </AccordionContent>
    </Accordion>
  )
}
