import { useState } from 'react'
import styled from 'styled-components/macro'
import { PrimaryButton } from '../../atoms/buttons/PrimaryButton'
import { Input } from '../../atoms/Input'
import { useTranslation } from 'next-i18next'

const StyledForm = styled.form`
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.device.desktop} {
    max-width: 492px;
  }
`

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 18px;

  ${({ theme }) => theme.device.desktop} {
    margin-top: 28px;
    margin-bottom: 26px;
    input {
      width: 464px;
    }
  }
`

const StyledText = styled.p`
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.neutral_white};
  line-height: 22px;
  font-family: 'Inter', sans-serif;
  text-align: center;
  margin: 0;

  ${({ theme }) => theme.device.desktop} {
    font-size: 28px;
    line-height: 38px;
  }
`

const Button = styled(PrimaryButton)`
  width: 100%;

  ${({ theme }) => theme.device.desktop} {
    width: 492px;
  }
`

type Props = {
  onSubmit: (email: string) => void
}

export const EmailSubscriptionForm = ({ onSubmit }: Props) => {
  const { t } = useTranslation('footer')
  const [email, setEmail] = useState('')

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit(email)
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledText>{t('stay_connected')}</StyledText>
      <StyledText>{t('get_news_and_collections')}</StyledText>
      <StyledText>{t('items_and_more')}</StyledText>
      <StyledContainer>
        <Input value={email} setValue={setEmail} placeholder={t('your_email_placeholder')} />
        <Button title={t('subscribe')} />
      </StyledContainer>
    </StyledForm>
  )
}
