import type { FC } from 'react'

type Props = {
  providers: unknown[]
  children: React.ReactNode
}

export const ProvidersWrapper: FC<Props> = ({ providers, children }) => {
  return (
    <>
      {providers.reverse().reduce((acc, curr) => {
        const [Provider, props] = Array.isArray(curr)
          ? [curr[0], curr[1]]
          : [curr, {}]
        return <Provider {...props}>{acc}</Provider>
      }, children)}
    </>
  )
}
