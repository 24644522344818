import { Icon } from '../..'
import type { IconProps } from '../../Icon'
import { Button, Title, Loader } from './styles'

export interface PrimaryButtonProps
  extends Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    'title' | 'size'
  > {
  title?: string | JSX.Element
  icon?: IconProps
  iconPosition?: 'left' | 'right'
  outline?: boolean
  secondary?: boolean
  loading?: boolean
}

export const PrimaryButton = ({
  title,
  loading,
  icon,
  iconPosition,
  outline = false,
  ...props
}: PrimaryButtonProps) => {
  return (
    <Button {...props} outline={outline} disabled={props.disabled || loading}>
      {loading ? (
        <Loader name={outline ? 'loaderOrange' : 'loaderBlack'} />
      ) : (
        <>
          {icon && iconPosition === 'left' ? <Icon {...icon} /> : null}
          <Title outline={outline}>{title}</Title>
          {icon && iconPosition === 'right' ? <Icon {...icon} /> : null}
        </>
      )}
    </Button>
  )
}
