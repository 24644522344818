import styled from 'styled-components'
import { Icon } from '../../atoms'
import { useTranslation } from 'next-i18next'

const Container = styled.a`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;

  width: 100%;
  max-height: 48px;

  border: 1px solid ${({ theme }) => theme.colors.deep_koamaru_dark};
  border-radius: 24px;
  background: ${({ theme }) => theme.colors.deep_koamaru_primary};
`

const TransformerContainer = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;

  width: auto;
  height: auto;

  margin: 0 0 0 auto;

  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.2s ease-in-out;
`

const CurrentLanguage = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: white;
`

const Wrapper = styled.div<{ small: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: ${({ small }) => (small ? '70px' : '100%')};
  align-items: center;
`

const StyledDropdownContent = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  position: absolute;
  z-index: 1;
  flex-direction: column;
`

export type LanguageSelectorProps = {
  open: boolean
  small: boolean
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  children?: React.ReactNode
}

const languageMap = {
  en: 'earth',
  es: 'spain',
  ua: 'ukraine',
  zh: 'china',
  vi: 'vietnam',
} as const

export const LanguageSelector = ({ open, small, onClick, children }: LanguageSelectorProps) => {
  const { i18n } = useTranslation()
  const languageIconName = languageMap[i18n.language as keyof typeof languageMap]

  return (
    <Wrapper small={small}>
      <Container onClick={onClick}>
        <Icon icon={{ name: languageIconName, size: 35 }} defaultProps={{ fill: 'deep_koamaru_primary' }} />
        {!small && <CurrentLanguage>English</CurrentLanguage>}
        <TransformerContainer open={open}>
          <Icon icon={{ name: 'arrowDownRound', size: 25 }} />
        </TransformerContainer>
      </Container>
      <StyledDropdownContent open={open}>{children}</StyledDropdownContent>
    </Wrapper>
  )
}
