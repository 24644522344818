import { Button, Title } from './styles'

export interface PrimaryButtonProps
  extends Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    'title' | 'size'
  > {
  title?: string | JSX.Element
}

const TextButton = ({ title, ...props }: PrimaryButtonProps) => {
  return (
    <Button {...props}>
      <Title>{title}</Title>
    </Button>
  )
}
export default TextButton
