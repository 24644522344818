import { useRef } from 'react'
import styled from 'styled-components/macro'

import CheckSvg from '../../assets/icons/check.svg'

type CheckboxProps = {
  id?: string
  checked?: boolean
  marginRight?: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CheckboxContainer = styled.div<{ marginRight?: string }>`
  position: relative;
  display: flex;
  margin-right: ${({ marginRight }) => marginRight || '0'};
`

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`

const CheckboxIcon = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.deep_koamaru_medium};
  border-radius: 4px;
  background-color: transparent;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.royal_orange_primary};
  }

  ${({ theme }) => theme.device.desktop} {
    width: 20px;
    height: 20px;
    border-radius: 6px;
  }

  &:after {
    content: '';
    display: none;
    border-bottom: 2.5px solid
      ${({ theme }) => theme.colors.deep_koamaru_primary};
    border-right: 2.5px solid
      ${({ theme }) => theme.colors.deep_koamaru_primary};
    transform: translateY(-1px) rotate(-45deg) rotateY(180deg);
    width: 7px;
    height: 4px;

    ${({ theme }) => theme.device.desktop} {
      width: 11px;
      height: 6px;
    }
  }

  ${CheckboxInput}:checked + & {
    background-color: ${({ theme }) => theme.colors.royal_orange_primary};
    border: 0;
    &:after {
      display: block;
    }
  }
`

export const Checkbox = ({ marginRight, ...props }: CheckboxProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <CheckboxContainer marginRight={marginRight}>
      <CheckboxInput type="checkbox" ref={inputRef} {...props} />
      <CheckboxIcon />
    </CheckboxContainer>
  )
}
