import React, { useState } from 'react'
import { type IconProps } from '../../atoms/Icon'
import Icon from '../../atoms/Icon'
import styled from 'styled-components/macro'

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-down.svg'

type Props = {
  title: string
  children: React.ReactNode
  open?: boolean
  icon?: IconProps
}

const StyledIcon = styled(ArrowIcon)<{ open: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  margin-left: auto;
  margin-right: 15px;
`

const AccordionContainer = styled.div`
  /* padding: 21px 22px 16px 12px; */
  width: 100%;

  /* ${({ theme }) => theme.device.desktop} {
    padding: 24px 22px 16px 12px;
  } */
`

const AccordionTitle = styled.h3`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.neutral_white};
  cursor: pointer;

  ${({ theme }) => theme.device.desktop} {
    font-size: 24px;
    line-height: 29px;
  }
`

const AccordionContent = styled.div`
  /* styles for the accordion content */
`

export const Accordion = ({ title, children, open, icon }: Props) => {
  const [isOpen, setIsOpen] = useState(open ?? false)

  return (
    <AccordionContainer>
      <AccordionTitle onClick={() => setIsOpen(!isOpen)}>
        {icon && <Icon {...icon} />} {title}{' '}
        <StyledIcon open={isOpen} width={20} height={20} />
      </AccordionTitle>
      {isOpen && <AccordionContent>{children}</AccordionContent>}
    </AccordionContainer>
  )
}
