import React from 'react'
import styled from 'styled-components/macro'

const StyledInput = styled.input`
  width: 100%;
  padding: 15px 12px 15px 16px;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.deep_koamaru_medium_dark};
  border-radius: 6px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.deep_koamaru_medium};
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.deep_koamaru_medium};
  }

  ${({ theme }) => theme.device.desktop} {
    width: 492px;
    padding: 18px 12px 18px 16px;
    font-size: 18px;
  }
`

type Props = {
  value: string
  setValue: (value: string) => void
  placeholder?: string
}

export const Input = ({ value, setValue, placeholder }: Props) => {
  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  return (
    <StyledInput
      value={value}
      onChange={onValueChange}
      placeholder={placeholder}
    />
  )
}
