import { ReactElement } from 'react'
import styled from 'styled-components/macro'

const StyledDropdown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;

  width: 284px;
  height: 290px;

  background: ${({ theme }) => theme.colors.deep_koamaru_darkest};

  border: 1px solid ${({ theme }) => theme.colors.deep_koamaru_medium_dark};
  box-shadow: 0px 10px 24px rgba(18, 18, 43, 0.6);
  border-radius: 10px;
`

export const Dropdown = ({ children }: { children: ReactElement[] }) => {
  return <StyledDropdown>{children}</StyledDropdown>
}
