import { Shadow, DeviceName } from './types'

export const colors = {
  deep_koamaru_primary: '#2D2D6B',
  deep_koamaru_darkest: '#12122B',
  deep_koamaru_dark: '#414376',
  deep_koamaru_medium_dark: '#6C6D94',
  deep_koamaru_medium: '#9696B2',
  deep_koamaru_medium_light: '#C0C0D1',
  deep_koamaru_light: '#D5D5E0',
  deep_koamaru_lightest: '#EAEAEF',

  pixie_powder_primary: '#3A0CA3',
  pixie_powder_darkest: '#110431',
  pixie_powder_dark: '#1D0652',
  pixie_powder_medium_dark: '#2E0A82',
  pixie_powder_medium: '#3F37C9',
  pixie_powder_medium_light: '#4361EE',
  pixie_powder_light: '#4895EF',
  pixie_powder_lightest: '#4CC9F0',

  electric_pink_primary: '#F72585',
  electric_pink_darkest: '#A02C5C',
  electric_pink_dark: '#E74F90',
  electric_pink_medium_dark: '#E970A8',
  electric_pink_medium: '#EB84B4',
  electric_pink_medium_light: '#F4C1D9',
  electric_pink_light: '#F7D5E6',
  electric_pink_lightest: '#FAEAF2',

  royal_orange_primary: '#FF9D43',
  royal_orange_darkest: '#995E28',
  royal_orange_dark: '#F4AA65',
  royal_orange_medium_dark: '#F4B375',
  royal_orange_medium: '#F6BC84',
  royal_orange_medium_light: '#F8CFA7',
  royal_orange_light: '#FBE3CA',
  royal_orange_lightest: '#FDF5ED',

  neutral_black: '#000000',
  neutral_dark: '#201F1E',
  neutral_sinister: '#323130',
  neutral_desert_shadow: '#3B3A39',
  neutral_pantone: '#605E5C',
  neutral_paloma: '#A19F9D',
  neutral_ice_grey: '#C8C6C4',
  neutral_elusion: '#D2D0CE',
  neutral_spatial_white: '#E1DFDD',
  neutral_heavenly_white: '#EDEBE9',
  neutral_white_chalk: '#FAF9F8',
  neutral_white: '#FFFFFF',

  special_online_bright: '#86BE40',
  special_online_dark: '#A4DC5E',

  special_do_not_disturb_bright: '#CD4847',
  special_do_not_disturb_dark: '#F5706F',

  special_away_bright: '#E1B545',
  special_away_dark: '#F5C959',

  special_out_of_office_bright: '#A565D7',
  special_out_of_office_dark: '#CD8DFF',

  special_invisible_bright: '#C6C4C2',
  special_invisible_dark: '#8A8886',
}

export const borderRadius = {
  xsmall: '3px',
  small: '4px',
  xmedium: '10px',
  xlmedium: '12px',
  medium: '14px',
  topModal: '20px',
  large: '36px',
  xlarge: '50px',
} as const

export const gradients = {
  theme_gradient: `linear-gradient(180deg, ${colors.special_away_dark} 0%, ${colors.royal_orange_primary} 100%)`,
  dark_yellow_gradient: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, ${colors.special_away_dark} 0%, ${colors.royal_orange_primary} 100%)`,
}

export const shadowsNames = ['basic'] as const

export const shadow: Record<Shadow, string> = {
  basic: `
  box-shadow: 0px 9px 14px 0px #12122b66;
`,
} as const

export const deviceNames = ['phone', 'tablet', 'desktop'] as const

export const deviceSizes = {
  phone: {
    min: 320,
    max: 811,
  },
  tablet: {
    min: 812,
    max: 1023,
  },
  desktop: {
    min: 1024,
    max: 9999,
  },
} as const

export const device: Record<DeviceName, string> = {
  phone: `@media only screen  
  and (max-width: 812px)`,
  tablet: `@media only screen 
  and (min-width: 768px) 
  and (max-width: 1024px)`,
  desktop: `@media only screen
  and (min-width: 1024px)`,
}
