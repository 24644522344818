import styled from 'styled-components/macro'
import Image from 'next/image'

import { Accordion } from '..'
import { IconedInfo } from '../../molecules'
import { useTranslation } from 'next-i18next'

const StyledAccordion = styled(Accordion)`
  h3 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
  }
`

const DescriptionText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 18px;
`

const DescriptionItems = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  ${({ theme }) => theme.device.desktop} {
    gap: 36px;
  }

  @media screen and (max-width: 350px) {
    flex-direction: column;
  }
`

type ItemDescriptionProps = {
  description: string
  game: {
    name: string
    imageSrc?: string
  }
  collection?: {
    name: string
    imageSrc?: string
  }
}

export const ItemDescription = (props: ItemDescriptionProps) => {
  const { t } = useTranslation('common')

  return (
    <StyledAccordion title={t('description')} open>
      <DescriptionText>{props.description}</DescriptionText>
      <DescriptionItems>
        <IconedInfo
          image={{ src: props.game.imageSrc || null, size: 'normal' }}
          title={t('game')}
          label={{ text: props.game.name }}
          overflowLayout
        />
        {props.collection && (
          <IconedInfo
            image={{ src: props.collection.imageSrc || null, size: 'normal' }}
            title={t('collection')}
            label={{ text: props.collection.name }}
            overflowLayout
          />
        )}
      </DescriptionItems>
    </StyledAccordion>
  )
}
