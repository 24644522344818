import { useReactTable, ColumnDef, getCoreRowModel, flexRender } from '@tanstack/react-table'
import styled from 'styled-components'
import { PrimaryButton } from '../../atoms'
import { useWindowSize } from '../../../lib/hooks'
import { detectDevice } from '../../theme/tools'
import { useTranslation } from 'next-i18next'

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
`

// Define your styled components
const StyledTable = styled.table`
  width: 100%;
  margin: 40px 0 0 0;

  thead {
    height: 33px;

    tr {
      height: 33px;
    }
  }
`

const TableRow = styled.tr`
  height: 110px;
  border-top: 1px solid ${({ theme }) => theme.colors.deep_koamaru_dark};
`

const TableCell = styled.td`
  padding: 0 10px;
`

const TableHeader = styled.th`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 24px;
  line-height: 33px;

  color: ${({ theme }) => theme.colors.deep_koamaru_medium};

  text-align: left;
  padding: 0 10px;
  padding-bottom: 24px;
`

type Display = 'mobile' | 'tablet' | 'desktop'

type TableProps<TData, TValue = any> = {
  data: TData[]
  columns: ColumnDef<TData, TValue>[]
  columnVisibility?: Record<string, Display | number>
  onLoadMore?: () => void
  isLoading?: boolean
  full?: boolean
}

const CalculateVisibility = (currentWidth: number, available: Display | number) => {
  if (typeof available === 'number') {
    return currentWidth >= available
  }

  switch (detectDevice(currentWidth)) {
    case 'mobile':
      return available === 'mobile'
    case 'tablet':
      return available === 'mobile' || available === 'tablet'
    case 'desktop':
      return true
  }
}

// Define your generic table component
export function Table<TData>({
  data,
  columns,
  columnVisibility = {},
  onLoadMore = () => null,
  isLoading = false,
  full = true,
}: TableProps<TData>) {
  const { t } = useTranslation('common')
  const screenWidth = useWindowSize()[0]

  const table = useReactTable<TData>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: Object.fromEntries(
        Object.keys(columnVisibility).map(key => [key, CalculateVisibility(screenWidth, columnVisibility[key])]),
      ),
    },
  })

  return (
    <TableContainer>
      <StyledTable>
        <thead>
          {table?.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <TableHeader key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </thead>
        <tbody>
          {table?.getRowModel().rows.map(row => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map(cell => (
                <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
              ))}
            </TableRow>
          ))}
        </tbody>
      </StyledTable>
      {full ? null : (
        <PrimaryButton
          outline
          title={t('explore_more')}
          onClick={onLoadMore}
          disabled={isLoading}
          loading={isLoading}
        />
      )}
    </TableContainer>
  )
}
