import { useState } from 'react'
import styled from 'styled-components/macro'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper'

type Props = {
  tabs: string[]
  onChange: (tab: string) => void
}

const StyledTabs = styled.div`
  display: block;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.deep_koamaru_dark};
  overflow: hidden;
  overflow-x: scroll;
  scrollbar-width: none;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
`
const Nav = styled(Swiper)`
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;

  .swiper-slide {
    width: auto;
  }
`

const MoreContent = styled.div`
  display: flex;
  width: 46px;
  height: 100%;
  position: absolute;
  right: 0;
  z-index: 1;
  top: 0;
  background: linear-gradient(
    270deg,
    ${({ theme }) => theme.colors.deep_koamaru_primary} 0%,
    rgba(45, 45, 107, 0) 100%
  );
`

const Tab = styled.li`
  color: ${({ theme }) => theme.colors.deep_koamaru_medium};
  padding: 0 10px 24px;
  margin-bottom: -1px;
  font-family: 'Manrope', sans-serif;
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  cursor: pointer;
  transition: color 0.3s ease-in;

  &:hover {
    color: ${({ theme }) => theme.colors.neutral_white};
  }

  &.active {
    color: ${({ theme }) => theme.colors.neutral_white};
    border-bottom: 2px solid ${({ theme }) => theme.colors.royal_orange_primary};
  }

  ${({ theme }) => theme.device.desktop} {
    font-size: 28px;
    padding: 0 27px 24px;
  }
`

export function Tabs({ tabs, onChange }: Props) {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <StyledTabs>
      <Nav
        allowTouchMove={true}
        freeMode
        spaceBetween={24}
        slidesPerView="auto"
        modules={[FreeMode]}
      >
        {tabs.map((tab, index) => (
          <SwiperSlide key={index}>
            <Tab
              className={activeTab === index ? 'active' : ''}
              onClick={() => {
                setActiveTab(index)
                onChange(tab)
              }}
            >
              {tab}
            </Tab>
          </SwiperSlide>
        ))}
      </Nav>
      <MoreContent />
    </StyledTabs>
  )
}
