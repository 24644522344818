import type { Environment } from './environment.type'

export const environment: Environment = {
  production: false,
  authUrl: 'https://api.bine.games/auth',
  gqlUrl: 'https://api.bine.games',
  chainUrl: 'https://testnet.bine.games',
  chainId: 1337,
  BINE_ABI_URL: 'https://static.bine.games/contracts/production/',
  BINE_RESOURCE_URL: 'https://static.bine.games/resources/production/',
  WALLET_CONNECT_ID: 'f5500624df358fad4b7b767ef3d7e49a',
}
