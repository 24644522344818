import { useState, useId } from 'react'
import styled from 'styled-components/macro'
import Image from 'next/image'

import { Checkbox } from '../../atoms/Checkbox'
import { detectDevice } from '../../theme/tools'

import { useEffect } from 'react'

type Props = {
  imageSrc?: string
  imageAlt?: string
  price?: string
  label: string
  value: string
  checked?: boolean
  onChanged?: (value: boolean) => void
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledCheckbox = styled(Checkbox)`
  padding-right: 12px;
`

const StyledImage = styled(Image)`
  margin-right: 8px;
  border-radius: 50%;
`

const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-family: 'Manrope';
  color: ${({ theme }) => theme.colors.neutral_white};

  ${({ theme }) => theme.device.desktop} {
    font-size: 20px;
  }
`

const Price = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-left: 2px;
  color: ${({ theme }) => theme.colors.deep_koamaru_medium};

  ${({ theme }) => theme.device.desktop} {
    margin-left: 4px;
  }
`

export const CheckboxInput = ({
  imageSrc,
  imageAlt,
  label,
  value,
  price,
  checked = false,
  onChanged = () => null,
}: Props) => {
  const id = 'checkbox-' + useId()
  const [imgSize, setImgSize] = useState(28)

  useEffect(() => {
    setImgSize(detectDevice(window?.innerWidth) === 'mobile' ? 28 : 38)
  }, [])

  return (
    <Wrapper>
      <Label htmlFor={id}>
        <StyledCheckbox
          checked={checked}
          onChange={e => onChanged(e.target.checked)}
          marginRight="12px"
          value={value}
          id={id}
        />
        {imageSrc && (
          <StyledImage
            width={imgSize}
            height={imgSize}
            src={imageSrc}
            alt={imageAlt || ''}
          />
        )}
        {label}
        {price && <Price>({price})</Price>}
      </Label>
    </Wrapper>
  )
}
