import styled from 'styled-components/macro'
import { useTranslation } from 'next-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import { CollectionSlider } from '../../molecules'
import { Collection } from '../../molecules/cards/CollectionCard'
import { useDisplayType } from '../../hooks'

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    color: ${({ theme }) => theme.colors.neutral_white};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 20px;
    font-family: 'Manrope', sans-serif;

    ${({ theme }) => theme.device.desktop} {
      font-size: 28px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: ${({ theme }) => theme.colors.neutral_white};
    left: initial;
    top: initial;
    right: initial;
    position: unset;
    margin-top: 5px;

    &::after {
      font-size: 19.5px;
    }
  }

  .swiper-button-prev {
    margin-right: 10px;
  }

  ${({ theme }) => theme.device.desktop} {
    padding: 13px 0;
  }
`

const SliderContainer = styled.div`
  height: 310px;
  overflow-x: hidden;

  ${({ theme }) => theme.device.desktop} {
    height: 550px;
  }
`

const Slider = styled(Swiper)`
  position: absolute;
  width: calc(100% - 32px);
  left: 0;
  padding: 0 16px;

  .swiper-slide {
    width: fit-content;
  }

  ${({ theme }) => theme.device.desktop} {
    padding: 0 46px;
    width: calc(100% - 92px);
  }

  @media screen and (min-width: 2020px) {
    padding: 0 calc((100% - 1920px) / 2);
    width: 1920px;
  }
`

const SwiperButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`

export type FeaturingCollectionsProps = {
  collections: Collection[]
}

export const FeaturingCollections: React.FC<FeaturingCollectionsProps> = ({ collections }) => {
  const { t } = useTranslation('homepage')
  const display = useDisplayType()
  const spaceBetween = display === 'desktop' ? 32 : 24

  return (
    <section>
      <Row>
        <h2>{t('featuring_collections')}</h2>
        <SwiperButtonsContainer>
          <div className="swiper-button-prev featuring-collections-nav"></div>
          <div className="swiper-button-next featuring-collections-nav"></div>
        </SwiperButtonsContainer>
      </Row>

      <SliderContainer>
        <Slider
          navigation={{
            nextEl: '.swiper-button-next.featuring-collections-nav',
            prevEl: '.swiper-button-prev.featuring-collections-nav',
          }}
          modules={[Navigation]}
          allowTouchMove={true}
          centeredSlides={display !== 'desktop'}
          pagination={{
            clickable: true,
          }}
          spaceBetween={spaceBetween}
          slidesPerView={'auto'}
        >
          {collections.map((collection, idx) => (
            <SwiperSlide key={collection.name + idx}>
              <CollectionSlider collection={collection} />
            </SwiperSlide>
          ))}
        </Slider>
      </SliderContainer>
    </section>
  )
}
