import styled from 'styled-components/macro'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper'
import { QuestionMark } from '../../../atoms/Icon/assets'
import Image from 'next/image'

import Link from 'next/link'
import { useId } from 'react'

type CollectionCardProps = {
  bgColor?: string
}

export type CollectionItem = {
  id: string
  name: string
  bgColor?: string
  bgSrc?: string
  price?: number
  priceUsd?: number
  href: string
}

export type Collection = {
  name: string
  items: CollectionItem[]
}

export type CollectionSliderProps = {
  collection: Collection
}

const Slider = styled(Swiper)`
  width: 328px;
  height: 353px;

  ${({ theme }) => theme.device.desktop} {
    width: 746px;
    height: 550px;
  }

  .swiper-pagination {
    left: 10px !important;
    bottom: 112px !important;
    display: flex;
    align-items: center;

    ${({ theme }) => theme.device.desktop} {
      left: 30px !important;
      bottom: 100px !important;
    }
  }

  .swiper-button-prev {
    &::after {
      font-size: 19.5px;
    }
    color: ${({ theme }) => theme.colors.neutral_white};

    top: 36% !important;

    ${({ theme }) => theme.device.desktop} {
      left: 43px;
      top: 42% !important;
    }
  }

  .swiper-button-next {
    &::after {
      font-size: 19.5px;
    }
    color: ${({ theme }) => theme.colors.neutral_white};

    top: 36% !important;

    ${({ theme }) => theme.device.desktop} {
      right: 43px;
      top: 42% !important;
    }
  }

  .swiper-button-disabled {
    color: ${({ theme }) => theme.colors.deep_koamaru_medium_dark};
  }

  .swiper-pagination-bullet {
    background: ${({ theme }) => theme.colors.royal_orange_darkest};
    width: 8px;
    height: 8px;
    margin: 0 3px !important;

    ${({ theme }) => theme.device.desktop} {
      width: 10px;
      height: 10px;
    }
  }

  .swiper-pagination-bullet-active {
    background: ${({ theme }) => theme.colors.royal_orange_primary};
    width: 10px;
    height: 10px;

    ${({ theme }) => theme.device.desktop} {
      width: 12px;
      height: 12px;
    }
  }
`
const CollectionCard = styled(({ bgColor, ...props }) => <Link {...props} />)<CollectionCardProps>`
  width: 328px;
  height: 253px;
  display: flex;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0px 9px 14px rgba(18, 18, 43, 0.4);

  * {
    width: 90%;
    height: 90%;
    margin: auto;
    object-fit: contain;
  }

  ${props =>
    `background: 
        linear-gradient(
          30deg, 
          ${props.bgColor ?? props.theme.colors.deep_koamaru_medium}70, 
          ${props.bgColor ?? props.theme.colors.deep_koamaru_medium}20
        );
    `}

  &:hover:not([disabled]) {
    box-shadow: 0px 9px 14px rgba(18, 18, 43, 0.4);
    filter: none;
  }

  ${({ theme }) => theme.device.desktop} {
    width: 746px;
    height: 474px;
    border-radius: 20px;
    box-shadow: 0px 9px 14px rgba(18, 18, 43, 0.4);

    ${props =>
      `background: 
        linear-gradient(
          30deg, 
          ${props.bgColor ?? props.theme.colors.deep_koamaru_medium}70, 
          ${props.bgColor ?? props.theme.colors.deep_koamaru_medium}20
        );
    `}
  }
`

const StyledItemData = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  &:last-of-type {
    margin-top: 6px;
  }
`

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledItemTitle = styled.p`
  margin: 0;
  font-style: normal;
  font-family: 'Manrope', sans-serif;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 20px;
  line-height: 27px;
  color: ${({ theme }) => theme.colors.neutral_white};
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;

  ${({ theme }) => theme.device.desktop} {
    font-size: 24px;
    line-height: 33px;
  }
`

const StyledGameTitle = styled.p`
  margin: 0;
  font-style: normal;
  font-family: 'Manrope', sans-serif;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.deep_koamaru_medium};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;

  ${({ theme }) => theme.device.desktop} {
    font-size: 20px;
    line-height: 27px;
  }
`

const StyledItemPrice = styled.p`
  margin: 0;
  width: 90px;
  font-style: normal;
  font-family: 'Manrope', sans-serif;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 20px;
  line-height: 27px;
  text-align: right;
  color: ${({ theme }) => theme.colors.royal_orange_primary};
  white-space: nowrap;

  ${({ theme }) => theme.device.desktop} {
    font-size: 24px;
    line-height: 33px;
  }
`

const StyledItemPriceUsd = styled.p`
  margin: 0;
  width: 90px;
  font-style: normal;
  font-family: 'Manrope', sans-serif;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: ${({ theme }) => theme.colors.deep_koamaru_medium};

  ${({ theme }) => theme.device.desktop} {
    font-size: 12px;
    line-height: 16px;
  }
`

export const CollectionSlider: React.FC<CollectionSliderProps> = ({ collection }) => {
  const id = useId()

  const randomizeAutoplayDelay = () => {
    return Math.random() * (5000 - 2000) + 2000 // Change min (2000) and max (5000) delay as you see fit
  }

  return (
    <Slider
      navigation={true}
      modules={[Autoplay, Navigation, Pagination]}
      allowTouchMove={false}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: randomizeAutoplayDelay(),
        disableOnInteraction: false,
      }}
      // effect={'creative'}
      spaceBetween={0}
      slidesPerView={1}
    >
      {collection.items.map(item => (
        <SwiperSlide key={`${id}-${item.id}`}>
          <>
            <CollectionCard bgColor={item.bgColor} href={item.href}>
              {item.bgSrc ? <Image src={item.bgSrc} alt={item.name} width={500} height={500} /> : <QuestionMark />}
            </CollectionCard>
            <StyledItemData>
              <StyledCol>
                <StyledItemTitle>{item.name}</StyledItemTitle>
                <StyledGameTitle>{collection.name}</StyledGameTitle>
              </StyledCol>
              <StyledCol>
                {item.price !== undefined && <StyledItemPrice>{item.price} BN</StyledItemPrice>}
                {item.priceUsd !== undefined && <StyledItemPriceUsd>${item.priceUsd}</StyledItemPriceUsd>}
              </StyledCol>
            </StyledItemData>
          </>
        </SwiperSlide>
      ))}
    </Slider>
  )
}
