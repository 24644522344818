import { TextType, FontWeight } from './types'

export const textTypes = [
  'heading1',
  'heading2',
  'heading3',
  'description1',
  'description2',
  'caption1',
  'caption2',
] as const

export const fontWeights = [
  'light',
  'regular',
  'medium',
  'semibold',
  'bold',
] as const

export const fontWeight: Record<FontWeight, string> = {
  light: '200',
  regular: '300',
  medium: '400',
  semibold: '500',
  bold: '600',
} as const

export const textAligns = [
  'left',
  'right',
  'center',
  'auto',
  'justify',
] as const

export const typeScale: Record<TextType, string> = {
  heading1: '64px',
  heading2: '36px',
  heading3: '24px',
  description1: '18px',
  description2: '16px',
  caption1: '14px',
  caption2: '10px',
} as const

export const lineHeight: Record<TextType, string> = {
  heading1: '78px',
  heading2: '44px',
  heading3: '30px',
  description1: '22px',
  description2: '20px',
  caption1: '18px',
  caption2: '12px',
} as const
