import styled from 'styled-components/macro'
import { ProgressBar, ProgressText } from '../../atoms'
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg'
import { formatDuration } from '../../utils/formatDuration'

type CollectionProgressData = {
  chestsToGet?: number
  chestsTotal?: number
  lastBlockAvailable?: number
  firstBlockAvailable: number
  currentBlock?: number
  blockDuration?: number
}

type CollectionProgressProps = {
  collection: CollectionProgressData
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  max-width: 328px;
  margin: 0 auto;
  margin-bottom: 50px;

  ${({ theme }) => theme.device.desktop} {
    max-width: 592px;
    gap: 22px;
  }
`

const StyledProgressText = styled.div`
  display: flex;
  align-items: flex-start;
  width: auto;

  height: 22px;
  flex-direction: row;
  justify-content: space-between;
`

const StyledDescription = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: 12px;
  line-height: 15px;
  gap: 6px;

  margin: 0 auto;
  margin-top: 10px;
  width: auto;

  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.deep_koamaru_medium};

  span {
    color: ${({ theme }) => theme.colors.royal_orange_primary};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  ${({ theme }) => theme.device.desktop} {
    font-size: 18px;
    line-height: 22px;
  }
`

export const CollectionProgress = ({ collection }: CollectionProgressProps) => {
  let progressBarValue = null

  if (collection.lastBlockAvailable && collection.currentBlock) {
    const totalBlocks = collection.lastBlockAvailable - collection.firstBlockAvailable
    const currentBlock = collection.currentBlock - collection.firstBlockAvailable
    progressBarValue = (currentBlock / totalBlocks) * 100
  }

  if (collection.chestsTotal && collection.chestsToGet) {
    const chestPercentage = (collection.chestsTotal / collection.chestsToGet) * 100
    if (progressBarValue == null || chestPercentage > progressBarValue) {
      progressBarValue = chestPercentage
    }
  }

  let finishedLabel = null

  if (
    collection.blockDuration &&
    collection.currentBlock &&
    collection.lastBlockAvailable &&
    collection.currentBlock > collection.lastBlockAvailable
  ) {
    const blocksAgo = collection.currentBlock - collection.lastBlockAvailable
    const durationAgo = blocksAgo * collection.blockDuration

    finishedLabel = `Collection finished ${blocksAgo} blocks ago, (${formatDuration(durationAgo)})`
  }

  return (
    <StyledContainer>
      {progressBarValue != null && (
        <>
          <StyledProgressText>
            <ProgressText
              title="blocks"
              current={collection.firstBlockAvailable}
              total={collection.lastBlockAvailable}
            />
            {collection.chestsTotal != null && (
              <ProgressText title="chests" current={collection.chestsTotal} total={collection.chestsToGet} />
            )}
          </StyledProgressText>
          <ProgressBar valuePercents={progressBarValue.toFixed(2)} />
        </>
      )}
      {finishedLabel != null && (
        <StyledDescription>
          <ClockIcon />
          {finishedLabel}
        </StyledDescription>
      )}
    </StyledContainer>
  )
}
