import { useTranslation } from 'next-i18next'
import { HeaderDropdown } from '../../HeaderDropdown'

type HeaderDropdownProps = {
  onClose: () => void
}

export const CommunityDropdown = ({ onClose }: HeaderDropdownProps) => {
  const { t } = useTranslation('header')

  return (
    <HeaderDropdown
      onClose={onClose}
      open={true}
      items={{
        Landing: {
          translation: t('landing'),
          isActive: true,
          leftIcon: 'article',
          rightIcon: {
            name: 'rightArrowPick',
            size: 18,
            fill: 'deep_koamaru_medium',
          },
          onClick: () => {
            window.open('https://bine.games/', '_blank')
          },
        },
        Bonuses: {
          translation: t('bonuses'),
          isActive: true,
          leftIcon: 'community',
          rightIcon: {
            name: 'rightArrowPick',
            size: 18,
            fill: 'deep_koamaru_medium',
          },
          onClick: () => {
            window.open('https://bonuses.bine.games/', '_blank')
          },
        },
        'Help Center': {
          translation: t('help_center'),
          isActive: false,
          leftIcon: 'helpCenter',
          rightIcon: {
            name: 'rightArrowPick',
            size: 18,
            fill: 'deep_koamaru_medium',
          },
        },
        Whitepaper: {
          translation: t('whitepaper'),
          isActive: true,
          leftIcon: 'whitepaper',
          rightIcon: {
            name: 'rightArrowPick',
            size: 18,
            fill: 'deep_koamaru_medium',
          },
          onClick: () => {
            window.open('https://whitepaper.bine.games/', '_blank')
          },
        },
      }}
    />
  )
}
